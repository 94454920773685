import React, { ReactElement, useEffect, useState } from 'react'

import { CheckIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, useColorModeValue } from '@chakra-ui/react'

interface IStepButtonComponent {
    activeStep: string | number
    step: string | number
    totalSteps: number
}
function StepButtonComponent({
    activeStep,
    step,
    totalSteps,
}: IStepButtonComponent): ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const [active, setActive] = useState(false)
    const [previous, setPrevious] = useState(false)
    const [stepPRops, setStepProps] = useState({})
    const [dividerProps, seDividerProps] = useState({})

    const activeColor = useColorModeValue('primary.500', 'secondary.500')
    const inactiveColor = useColorModeValue('primary.700', 'secondary.750')

    useEffect(() => {
        setActive(activeStep === step)
        setPrevious(activeStep > step)
        if (activeStep === step) {
            setStepProps({ bg: activeColor })
            seDividerProps({ bg: activeColor })
        } else {
            setStepProps({})
            seDividerProps({ bg: inactiveColor })
        }
    }, [activeStep, step])

    return (
        <>
            {previous ? (
                <Flex flexDirection={'column'} alignItems="center">
                    <Button w={'40px'} h={'40px'} borderRadius={'50%'}>
                        <CheckIcon />
                    </Button>
                </Flex>
            ) : (
                <Flex flexDirection={'column'} alignItems="center">
                    <Button
                        w={'40px'}
                        h={'40px'}
                        borderWidth={'1px'}
                        borderRadius={'50%'}
                        {...stepPRops}
                    >
                        {step}
                    </Button>
                </Flex>
            )}
            {(step as number) < totalSteps && (
                <Box
                    {...dividerProps}
                    position={'relative'}
                    bottom={'4px'}
                    ml={2}
                    width={'100%'}
                    h={1}
                ></Box>
            )}
        </>
    )
}

export default StepButtonComponent
