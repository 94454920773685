import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Flex, useBoolean, useToast } from '@chakra-ui/react'

import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../services/API/general.api'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../utils/functions.utils'
import { AssetTypeBO, ProductDTO } from '../../../utils/types/types'
import AddProductComponent from './AddProduct.component'
import { columnsConfig } from './ProductsTable.config'

interface IProductsTable {
    fetchProducts: Function
    isLoading?: boolean
    products: ProductDTO[]
}

export default function ProductsTable({
    products,
    fetchProducts,
}: IProductsTable): ReactElement {
    const navigate = useNavigate()
    const translate = useTranslation().t
    const toast = useToast()
    const [isAddProductOpen, setAddProduct] = useBoolean()
    const [assetTypes, setAssetTypes] = useState<AssetTypeBO[]>([])

    const handleSaveProduct = async (product: ProductDTO): Promise<void> => {
        const response = await generalPostAPI(API_ENDPOINTS.product, {
            ...product,
            productType: Number(product.productType),
            recurrence: Number(product.recurrence),
        })
        if (response?.isOk) {
            fetchProducts()
            toast(baseSuccessToastOptions(translate('productCreated')))
        } else {
            toast(baseErrorToastOptions(response?.message))
        }
        setAddProduct.off()
    }

    const fetchAssetTypes = async () => {
        const result = await generalGetAPI(API_ENDPOINTS.assetTypes)
        if (result?.isOk) {
            setAssetTypes(result.data)
        }
    }

    useEffect(() => {
        fetchAssetTypes()
    }, [])

    return (
        <Flex flexDirection={'column'} gap={4}>
            <AddProductComponent
                modalTitle={translate('addProduct')}
                isModalOpen={isAddProductOpen}
                onClose={() => setAddProduct.off()}
                handleSaveProduct={(data: ProductDTO) =>
                    handleSaveProduct(data)
                }
                assetTypes={assetTypes}
                maxWidth="1000px"
            />
            <DynamicGrid
                tableId="productsTable"
                columns={columnsConfig(translate, navigate)}
                rowData={products}
                pagination={true}
                rowMultiSelectWithClick={false}
                headers={
                    <Button
                        onClick={(): void => {
                            setAddProduct.on()
                        }}
                    >
                        {translate('addRow')}
                    </Button>
                }
            />
        </Flex>
    )
}
