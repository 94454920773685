import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Heading, Text, useColorModeValue } from '@chakra-ui/react'

import NumberFormatterPlainText from '../../features/numberFormatterPlainText/NumberFormatterPlainText.feature'
import { formatDate } from '../../utils/localization/culture.utils'

export interface HighlightInterface {
    title?: string | null
    info?: string | number | null
    type?: 'number' | 'text' | 'date' | 'link'
    currency?: string | number | null
    hasBorder?: boolean
    navigateTo?: string
}

function HighlightComponent({
    title,
    info,
    currency,
    navigateTo,
    type = 'text',
    hasBorder = false,
}: HighlightInterface): ReactElement {
    const borderColor = useColorModeValue('primary.500', 'secondary.500')
    const textColor = useColorModeValue('primary.700', 'secondary.200')
    const valuesColor = useColorModeValue('primary.500', 'secondary.300')
    const navigate = useNavigate()

    return (
        <Box
            borderLeft={hasBorder ? `1px solid` : 'none'}
            borderColor={borderColor}
            pr={1.5}
            pl={2.5}
            minWidth={'fit-content'}
        >
            <Box>
                <Heading as={'h4'} fontSize={'md'} color={textColor}>
                    {title}
                </Heading>
                {type === 'number' && (
                    <NumberFormatterPlainText
                        value={info}
                        color={valuesColor}
                        fontSize={{ base: '1.125em' }}
                        fontWeight={'bold'}
                    />
                )}
                {type === 'text' && (
                    <Text
                        fontSize={{ base: '1.125em' }}
                        fontWeight={'bold'}
                        color={valuesColor}
                    >
                        {info} {currency}
                    </Text>
                )}
                {type === 'link' && (
                    <Text
                        fontSize={{ base: '1.125em' }}
                        fontWeight={'bold'}
                        color={valuesColor}
                        _hover={{ color: valuesColor, cursor: 'pointer' }}
                        onClick={() => {
                            if (navigateTo) {
                                navigate(navigateTo)
                                window.location.reload()
                            }
                        }}
                    >
                        {info}
                    </Text>
                )}
                {type === 'date' && (
                    <Text
                        fontSize={{ base: '1.125em' }}
                        fontWeight={'bold'}
                        color={valuesColor}
                    >
                        {formatDate(info as string)}
                    </Text>
                )}
            </Box>
        </Box>
    )
}

export default HighlightComponent
