import camelCase from 'lodash/camelCase'

import { ViewIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'
import { ColumnActionConfiguration } from '../../../utils/functions.utils'
import { formatDate } from '../../../utils/localization/culture.utils'
import { ProductType, Recurrence } from '../../../utils/types/types'

export const isProductValid = (
    validFromDateTime: string,
    validToDateTime: string | null
): boolean => {
    const currentTime = new Date().getTime()
    const validFromTime = new Date(validFromDateTime).getTime()

    if (validToDateTime === null) {
        return validFromTime <= currentTime
    }

    const validToTime = new Date(validToDateTime).getTime()
    return validFromTime <= currentTime && validToTime >= currentTime
}

export const columnsConfig = (translate: Function, navigate: Function) => [
    {
        field: 'name',
        filter: 'agTextColumnFilter',
        headerName: translate('name'),
    },
    {
        field: 'description',
        filter: 'agTextColumnFilter',
        headerName: translate('description'),
    },
    {
        field: 'type',
        filter: 'agTextColumnFilter',
        headerName: translate('type'),
        valueGetter: (params: any) =>
            translate(camelCase(ProductType[params?.data?.productType])),
    },
    {
        field: 'recurrence',
        filter: 'agTextColumnFilter',
        headerName: translate('recurrence'),
        valueGetter: (params: any) =>
            translate(camelCase(Recurrence[params?.data?.recurrence])),
    },
    {
        field: 'status',
        filter: 'agTextColumnFilter',
        headerName: translate('status'),
        valueGetter: (params: any) =>
            translate(
                isProductValid(
                    params?.data.validFromDateTime,
                    params?.data?.validToDateTime || null
                )
                    ? 'valid'
                    : 'notValid'
            ),
    },
    {
        field: 'validFromDateTime',
        filter: 'agDateColumnFilter',
        headerName: translate('validFromDateTime'),
        valueGetter: (params: any) =>
            formatDate(params?.data?.validFromDateTime),
    },
    {
        field: 'validToDateTime',
        filter: 'agDateColumnFilter',
        headerName: translate('validToDateTime'),
        valueGetter: (params: any) => formatDate(params?.data?.validToDateTime),
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <Tooltip label={translate('View')} placement="top" hasArrow>
                    <IconButton
                        aria-label="View"
                        mr={2}
                        size="sm"
                        onClick={() =>
                            navigate(`/setup/templates/${params?.data?.id}`, {
                                state: params?.data,
                            })
                        }
                        icon={<ViewIcon />}
                    />
                </Tooltip>
            ),
            100
        ),
    },
]
