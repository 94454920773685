/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface VariableInterestDTO {
    /** @format int64 */
    id: number
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
    /** @format decimal */
    value: number
}

export interface CreateVariableInterestCommand {
    /** @format date-time */
    startDate: string
    /** @format decimal */
    value: number
}

export interface VariableInterestLimitDTO {
    /** @format int64 */
    id: number
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
    /** @format decimal */
    limit: number
}

export interface CreateVariableInterestLimitCommand {
    /** @format date-time */
    startDate: string
    /** @format decimal */
    limit: number
}

export interface PagedModelOfGetUserResult {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: GetUserResult[]
}

export interface GetUserResult {
    userNumber: string
    name?: string
    email?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    culture: CountryCode
    /**
     * 0 = DisabledUser
     * 5 = RestrictedUser
     * 10 = RegularUser
     * 30 = SuperUser
     * 40 = AdminUser
     * 50 = GlobalAdminUser
     * 100 = SystemUser
     */
    userGroup: UserGroup
    /** @format int64 */
    activePartnerId: number
    branchName?: string
    /**
     * 0 = Dark
     * 1 = Light
     * 2 = Purple
     */
    theme: UserTheme
    allowedPartnersList: PartnerInfoResult[]
}

/**
 * 0 = AX
 * 1 = AF
 * 2 = AL
 * 3 = DZ
 * 4 = AS
 * 5 = AD
 * 6 = AO
 * 7 = AI
 * 8 = AQ
 * 9 = AG
 * 10 = AR
 * 11 = AM
 * 12 = AW
 * 13 = AU
 * 14 = AT
 * 15 = AZ
 * 16 = BS
 * 17 = BH
 * 18 = BD
 * 19 = BB
 * 20 = BY
 * 21 = BE
 * 22 = BZ
 * 23 = BJ
 * 24 = BM
 * 25 = BT
 * 26 = BO
 * 27 = BA
 * 28 = BW
 * 29 = BV
 * 30 = BR
 * 31 = IO
 * 32 = BN
 * 33 = BG
 * 34 = BF
 * 35 = BI
 * 36 = KH
 * 37 = CM
 * 38 = CA
 * 39 = CV
 * 40 = KY
 * 41 = CF
 * 42 = TD
 * 43 = CL
 * 44 = CN
 * 45 = CX
 * 46 = CC
 * 47 = CO
 * 48 = KM
 * 49 = CD
 * 50 = CG
 * 51 = CK
 * 52 = CR
 * 53 = CI
 * 54 = HR
 * 55 = CU
 * 56 = CY
 * 57 = CZ
 * 58 = DK
 * 59 = DJ
 * 60 = DM
 * 61 = DO
 * 62 = EC
 * 63 = EG
 * 64 = SV
 * 65 = GQ
 * 66 = ER
 * 67 = EE
 * 68 = ET
 * 69 = FK
 * 70 = FO
 * 71 = FJ
 * 72 = FI
 * 73 = FR
 * 74 = GF
 * 75 = PF
 * 76 = TF
 * 77 = GA
 * 78 = GM
 * 79 = GE
 * 80 = DE
 * 81 = GH
 * 82 = GI
 * 83 = GR
 * 84 = GL
 * 85 = GD
 * 86 = GP
 * 87 = GU
 * 88 = GT
 * 89 = GN
 * 90 = GW
 * 91 = GY
 * 92 = HT
 * 93 = HM
 * 94 = HN
 * 95 = HK
 * 96 = HU
 * 97 = IS
 * 98 = IN
 * 99 = ID
 * 100 = IR
 * 101 = IQ
 * 102 = IE
 * 103 = IL
 * 104 = IT
 * 105 = JM
 * 106 = JP
 * 107 = JO
 * 108 = KZ
 * 109 = KE
 * 110 = KI
 * 111 = KP
 * 112 = KR
 * 113 = KW
 * 114 = KG
 * 115 = LA
 * 116 = LV
 * 117 = LB
 * 118 = LS
 * 119 = LR
 * 120 = LY
 * 121 = LI
 * 122 = LT
 * 123 = LU
 * 124 = MO
 * 125 = MK
 * 126 = MG
 * 127 = MW
 * 128 = MY
 * 129 = MV
 * 130 = ML
 * 131 = MT
 * 132 = MH
 * 133 = MQ
 * 134 = MR
 * 135 = MU
 * 136 = YT
 * 137 = MX
 * 138 = FM
 * 139 = MD
 * 140 = MC
 * 141 = MN
 * 142 = MS
 * 143 = MA
 * 144 = MZ
 * 145 = MM
 * 146 = NA
 * 147 = NR
 * 148 = NP
 * 149 = NL
 * 150 = AN
 * 151 = NC
 * 152 = NZ
 * 153 = NI
 * 154 = NE
 * 155 = NG
 * 156 = NU
 * 157 = NF
 * 158 = MP
 * 159 = NO
 * 160 = OM
 * 161 = PK
 * 162 = PW
 * 163 = PS
 * 164 = PA
 * 165 = PG
 * 166 = PY
 * 167 = PE
 * 168 = PH
 * 169 = PN
 * 170 = PL
 * 171 = PT
 * 172 = PR
 * 173 = QA
 * 174 = RE
 * 175 = RO
 * 176 = RU
 * 177 = RW
 * 178 = SH
 * 179 = KN
 * 180 = LC
 * 181 = PM
 * 182 = VC
 * 183 = WS
 * 184 = SM
 * 185 = ST
 * 186 = SA
 * 187 = SN
 * 188 = CS
 * 189 = SC
 * 190 = SL
 * 191 = SG
 * 192 = SK
 * 193 = SI
 * 194 = SB
 * 195 = SO
 * 196 = ZA
 * 197 = GS
 * 198 = ES
 * 199 = LK
 * 200 = SD
 * 201 = SR
 * 202 = SJ
 * 203 = SZ
 * 204 = SE
 * 205 = CH
 * 206 = SY
 * 207 = TW
 * 208 = TJ
 * 209 = TZ
 * 210 = TH
 * 211 = TL
 * 212 = TG
 * 213 = TK
 * 214 = TO
 * 215 = TT
 * 216 = TN
 * 217 = TR
 * 218 = TM
 * 219 = TC
 * 220 = TV
 * 221 = UG
 * 222 = UA
 * 223 = AE
 * 224 = GB
 * 225 = US
 * 226 = UM
 * 227 = UY
 * 228 = UZ
 * 229 = VU
 * 230 = VA
 * 231 = VE
 * 232 = VN
 * 233 = VG
 * 234 = VI
 * 235 = WF
 * 236 = EH
 * 237 = YE
 * 238 = ZM
 * 239 = ZW
 */
export enum CountryCode {
    AX = 0,
    AF = 1,
    AL = 2,
    DZ = 3,
    AS = 4,
    AD = 5,
    AO = 6,
    AI = 7,
    AQ = 8,
    AG = 9,
    AR = 10,
    AM = 11,
    AW = 12,
    AU = 13,
    AT = 14,
    AZ = 15,
    BS = 16,
    BH = 17,
    BD = 18,
    BB = 19,
    BY = 20,
    BE = 21,
    BZ = 22,
    BJ = 23,
    BM = 24,
    BT = 25,
    BO = 26,
    BA = 27,
    BW = 28,
    BV = 29,
    BR = 30,
    IO = 31,
    BN = 32,
    BG = 33,
    BF = 34,
    BI = 35,
    KH = 36,
    CM = 37,
    CA = 38,
    CV = 39,
    KY = 40,
    CF = 41,
    TD = 42,
    CL = 43,
    CN = 44,
    CX = 45,
    CC = 46,
    CO = 47,
    KM = 48,
    CD = 49,
    CG = 50,
    CK = 51,
    CR = 52,
    CI = 53,
    HR = 54,
    CU = 55,
    CY = 56,
    CZ = 57,
    DK = 58,
    DJ = 59,
    DM = 60,
    DO = 61,
    EC = 62,
    EG = 63,
    SV = 64,
    GQ = 65,
    ER = 66,
    EE = 67,
    ET = 68,
    FK = 69,
    FO = 70,
    FJ = 71,
    FI = 72,
    FR = 73,
    GF = 74,
    PF = 75,
    TF = 76,
    GA = 77,
    GM = 78,
    GE = 79,
    DE = 80,
    GH = 81,
    GI = 82,
    GR = 83,
    GL = 84,
    GD = 85,
    GP = 86,
    GU = 87,
    GT = 88,
    GN = 89,
    GW = 90,
    GY = 91,
    HT = 92,
    HM = 93,
    HN = 94,
    HK = 95,
    HU = 96,
    IS = 97,
    IN = 98,
    ID = 99,
    IR = 100,
    IQ = 101,
    IE = 102,
    IL = 103,
    IT = 104,
    JM = 105,
    JP = 106,
    JO = 107,
    KZ = 108,
    KE = 109,
    KI = 110,
    KP = 111,
    KR = 112,
    KW = 113,
    KG = 114,
    LA = 115,
    LV = 116,
    LB = 117,
    LS = 118,
    LR = 119,
    LY = 120,
    LI = 121,
    LT = 122,
    LU = 123,
    MO = 124,
    MK = 125,
    MG = 126,
    MW = 127,
    MY = 128,
    MV = 129,
    ML = 130,
    MT = 131,
    MH = 132,
    MQ = 133,
    MR = 134,
    MU = 135,
    YT = 136,
    MX = 137,
    FM = 138,
    MD = 139,
    MC = 140,
    MN = 141,
    MS = 142,
    MA = 143,
    MZ = 144,
    MM = 145,
    NA = 146,
    NR = 147,
    NP = 148,
    NL = 149,
    AN = 150,
    NC = 151,
    NZ = 152,
    NI = 153,
    NE = 154,
    NG = 155,
    NU = 156,
    NF = 157,
    MP = 158,
    NO = 159,
    OM = 160,
    PK = 161,
    PW = 162,
    PS = 163,
    PA = 164,
    PG = 165,
    PY = 166,
    PE = 167,
    PH = 168,
    PN = 169,
    PL = 170,
    PT = 171,
    PR = 172,
    QA = 173,
    RE = 174,
    RO = 175,
    RU = 176,
    RW = 177,
    SH = 178,
    KN = 179,
    LC = 180,
    PM = 181,
    VC = 182,
    WS = 183,
    SM = 184,
    ST = 185,
    SA = 186,
    SN = 187,
    CS = 188,
    SC = 189,
    SL = 190,
    SG = 191,
    SK = 192,
    SI = 193,
    SB = 194,
    SO = 195,
    ZA = 196,
    GS = 197,
    ES = 198,
    LK = 199,
    SD = 200,
    SR = 201,
    SJ = 202,
    SZ = 203,
    SE = 204,
    CH = 205,
    SY = 206,
    TW = 207,
    TJ = 208,
    TZ = 209,
    TH = 210,
    TL = 211,
    TG = 212,
    TK = 213,
    TO = 214,
    TT = 215,
    TN = 216,
    TR = 217,
    TM = 218,
    TC = 219,
    TV = 220,
    UG = 221,
    UA = 222,
    AE = 223,
    GB = 224,
    US = 225,
    UM = 226,
    UY = 227,
    UZ = 228,
    VU = 229,
    VA = 230,
    VE = 231,
    VN = 232,
    VG = 233,
    VI = 234,
    WF = 235,
    EH = 236,
    YE = 237,
    ZM = 238,
    ZW = 239,
}

/**
 * 0 = DisabledUser
 * 5 = RestrictedUser
 * 10 = RegularUser
 * 30 = SuperUser
 * 40 = AdminUser
 * 50 = GlobalAdminUser
 * 100 = SystemUser
 */
export enum UserGroup {
    DisabledUser = 0,
    RestrictedUser = 5,
    RegularUser = 10,
    SuperUser = 30,
    AdminUser = 40,
    GlobalAdminUser = 50,
    SystemUser = 100,
}

/**
 * 0 = Dark
 * 1 = Light
 * 2 = Purple
 */
export enum UserTheme {
    Dark = 0,
    Light = 1,
    Purple = 2,
}

export interface PartnerInfoResult {
    shortCode?: string
    name?: string
    /** @format int64 */
    id: number
}

export interface ProblemDetails {
    type?: string
    title?: string
    /** @format int32 */
    status?: number
    detail?: string
    instance?: string
    [key: string]: any
}

export interface CreateUserCommand {
    name?: string
    email?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    culture: CountryCode
    /**
     * 0 = DisabledUser
     * 5 = RestrictedUser
     * 10 = RegularUser
     * 30 = SuperUser
     * 40 = AdminUser
     * 50 = GlobalAdminUser
     * 100 = SystemUser
     */
    userGroup: UserGroup
    /** @format int64 */
    activePartnerId: number
    changePasswordOnNextLogin: boolean
    branchName?: string
    allowedPartners?: number[]
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    formatting: CountryCode
    /**
     * 0 = Dark
     * 1 = Light
     * 2 = Purple
     */
    theme: UserTheme
}

export interface UpdateUserCommand {
    userNumber: string
    name?: string
    email?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    culture: CountryCode
    /**
     * 0 = DisabledUser
     * 5 = RestrictedUser
     * 10 = RegularUser
     * 30 = SuperUser
     * 40 = AdminUser
     * 50 = GlobalAdminUser
     * 100 = SystemUser
     */
    userGroup: UserGroup
    /** @format int64 */
    activePartnerId: number
    changePasswordOnNextLogin: boolean
    branchName?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    formatting: CountryCode
    /**
     * 0 = Dark
     * 1 = Light
     * 2 = Purple
     */
    theme: UserTheme
}

export interface UsageDTO {
    /** @format int64 */
    id: number
    /**
     * 0 = Hourly
     * 1 = Daily
     * 2 = Count
     * 3 = Distance
     */
    usageType: UsageType
    /** @format decimal */
    value?: number
    /** @format int32 */
    period: number
    /** @format date-time */
    lastUpdateTime?: string
    /** @format date-time */
    periodStart?: string
    /** @format date-time */
    periodEnd?: string
    /** @format decimal */
    usageLimit: number
    /** @format decimal */
    pricePerUnitOverUsageLimit: number
    /** @format decimal */
    pricePerUnitUnderUsageLimit: number
    /** @format decimal */
    usageAmount: number
    /** @format decimal */
    overUsageAmount: number
    /**
     * 0 = Ongoing
     * 1 = Locked
     * 2 = PeriodicInvoiced
     * 3 = OverUsageInvoiced
     */
    status: UsageStatus
    assetNumber?: string
    message?: string
}

/**
 * 0 = Hourly
 * 1 = Daily
 * 2 = Count
 * 3 = Distance
 */
export enum UsageType {
    Hourly = 0,
    Daily = 1,
    Count = 2,
    Distance = 3,
}

/**
 * 0 = Ongoing
 * 1 = Locked
 * 2 = PeriodicInvoiced
 * 3 = OverUsageInvoiced
 */
export enum UsageStatus {
    Ongoing = 0,
    Locked = 1,
    PeriodicInvoiced = 2,
    OverUsageInvoiced = 3,
}

export interface UpdateUsageCommand {
    assetNumber: string
    /** @format decimal */
    value: number
    /** @format date-time */
    lastUpdateTime: string
    isMultipleEntry: boolean
}

export type UploadedUsageDTO = UploadUsageDTO & {
    status?: boolean
    message: string
}

export interface UploadUsageDTO {
    assetNumber?: string
    /** @format decimal */
    usageAmount: number
}

export type SignerDto = SignerBaseDto & {
    /** @format int64 */
    id: number
    /** @format int64 */
    contractId?: number
    /** @format int64 */
    customerId?: number
    enableTouchSignature: boolean
}

export interface SignerBaseDto {
    name?: string
    email?: string
    /**
     * 0 = Signer
     * 1 = Copy
     * 2 = Approver
     */
    receiver: SignatureReceiver
    /**
     * 0 = Digital
     * 1 = Touch
     */
    digitalType?: SignatureDigitalType
    /**
     * 0 = MitID
     * 1 = SwedishBankID
     * 2 = NorwegianBankID
     */
    electronicID?: ElectronicID
    /** @format int32 */
    signOrder?: number
}

/**
 * 0 = Signer
 * 1 = Copy
 * 2 = Approver
 */
export enum SignatureReceiver {
    Signer = 0,
    Copy = 1,
    Approver = 2,
}

/**
 * 0 = Digital
 * 1 = Touch
 */
export enum SignatureDigitalType {
    Digital = 0,
    Touch = 1,
}

/**
 * 0 = MitID
 * 1 = SwedishBankID
 * 2 = NorwegianBankID
 */
export enum ElectronicID {
    MitID = 0,
    SwedishBankID = 1,
    NorwegianBankID = 2,
}

export type CreateSignerDto = SignerBaseDto & object

export type UpdateSignerDto = SignerBaseDto & object

export interface GetBaseServicesInfo {
    baseServiceNumber: string
    description?: string
    /** @format int64 */
    vatId: number
    erpProductId: string
    /** @format decimal */
    vatPercentage: number
    /** @format int64 */
    documentMetaDataId?: number
    information?: string
    /**
     * 0 = Once
     * 1 = Monthly
     * 3 = ThreeMonthly
     * 4 = Quarterly
     * 5 = SixMonthly
     * 6 = Semiannual
     * 11 = TwelveMonthly
     * 12 = Annual
     */
    recurrence: Recurrence
    baseServiceValues: BaseServiceValueDTO[]
    productServices: ProductServiceDTO[]
}

/**
 * 0 = Once
 * 1 = Monthly
 * 3 = ThreeMonthly
 * 4 = Quarterly
 * 5 = SixMonthly
 * 6 = Semiannual
 * 11 = TwelveMonthly
 * 12 = Annual
 */
export enum Recurrence {
    Once = 0,
    Monthly = 1,
    ThreeMonthly = 3,
    Quarterly = 4,
    SixMonthly = 5,
    Semiannual = 6,
    TwelveMonthly = 11,
    Annual = 12,
}

export interface BaseServiceValueDTO {
    /** @format int64 */
    id: number
    /**
     * 0 = Fixed
     * 1 = PercentageFromAssetValue
     */
    priceCalculationMethod: BaseServicePriceCalculationMethod
    /** @format decimal */
    value?: number
    /** @format decimal */
    percentage?: number
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
    isCurrent: boolean
}

/**
 * 0 = Fixed
 * 1 = PercentageFromAssetValue
 */
export enum BaseServicePriceCalculationMethod {
    Fixed = 0,
    PercentageFromAssetValue = 1,
}

export interface ProductServiceDTO {
    /** @format int64 */
    productId: number
    isSelectable: boolean
}

export interface BaseServiceDTO {
    baseServiceNumber?: string
    description?: string
    /** @format int64 */
    vatId: number
    /** @format decimal */
    vatPercentage: number
    canChange: boolean
    erpProductId?: string
    /** @format int64 */
    documentMetaDataId?: number
    baseServiceValues: BaseServiceValueDTO[]
    productServices: ProductServiceDTO[]
}

export type CreateBaseServiceRequest = BaseServiceBaseCommand & {
    description?: string
    /** @format int64 */
    vatId: number
    erpProductId?: string
    productServices?: ProductServiceDTO[]
    information?: string
}

export interface BaseServiceBaseCommand {
    baseServiceValues: BaseServiceValue[]
}

export interface BaseServiceValue {
    /** @format int64 */
    id?: number
    /**
     * 0 = Fixed
     * 1 = PercentageFromAssetValue
     */
    priceCalculationMethod: BaseServicePriceCalculationMethod
    /** @format decimal */
    value?: number
    /** @format decimal */
    percentage?: number
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
}

export type UpdateBaseServiceRequest = BaseServiceBaseCommand & {
    /** @format int64 */
    vatId: number
    baseServiceNumber: string
    erpProductId?: string
    productServices?: ProductServiceDTO[]
    description?: string
    information?: string
}

export interface ScriveProcessInfo {
    documentId: string
    /** @format uri */
    processApiUri: string
}

export interface SendDocumentDigitalSignDto {
    /** @format int64 */
    documentId: number
    signers: SignerDto[]
    carbonCopy: ViewerOfSignedDocumentDto[]
}

export interface ViewerOfSignedDocumentDto {
    name?: string
    email?: string
    /** @format int32 */
    signOrder?: number
}

export interface ProductSummaryDTO {
    /** @format int64 */
    id: number
    name?: string
    description?: string
    /** @format date-time */
    validFromDateTime: string
    /** @format date-time */
    validToDateTime?: string
    /**
     * 10 = OperationalLeasing
     * 20 = FinancialLeasing
     * 30 = Sale
     * 40 = ShortTermRental
     * 50 = Rental
     * 60 = Subscription
     */
    productType: ProductType
    /**
     * 0 = Once
     * 1 = Monthly
     * 3 = ThreeMonthly
     * 4 = Quarterly
     * 5 = SixMonthly
     * 6 = Semiannual
     * 11 = TwelveMonthly
     * 12 = Annual
     */
    recurrence: Recurrence
}

/**
 * 10 = OperationalLeasing
 * 20 = FinancialLeasing
 * 30 = Sale
 * 40 = ShortTermRental
 * 50 = Rental
 * 60 = Subscription
 */
export enum ProductType {
    OperationalLeasing = 10,
    FinancialLeasing = 20,
    Sale = 30,
    ShortTermRental = 40,
    Rental = 50,
    Subscription = 60,
}

export interface ProductDTO {
    /** @format int64 */
    id: number
    name?: string
    description?: string
    /** @format int32 */
    maxAllowedAssets: number
    /** @format date-time */
    validFromDateTime: string
    /** @format date-time */
    validToDateTime?: string
    /**
     * 10 = OperationalLeasing
     * 20 = FinancialLeasing
     * 30 = Sale
     * 40 = ShortTermRental
     * 50 = Rental
     * 60 = Subscription
     */
    productType: ProductType
    allowedAssetTypes: AssetArcheType[]
    allowedCustomerTypes: CustomerType[]
    templates: ProductTemplateDTO[]
    productRules: ProductRuleDTO[]
    productServices: ProductServiceDTO[]
    isUsageEnabled: boolean
    /**
     * 0 = Once
     * 1 = Monthly
     * 3 = ThreeMonthly
     * 4 = Quarterly
     * 5 = SixMonthly
     * 6 = Semiannual
     * 11 = TwelveMonthly
     * 12 = Annual
     */
    recurrence: Recurrence
    canBeProlonged: boolean
    isShadow: boolean
    /** @format int64 */
    defaultAssetTypeId?: number
    canBeDiscounted: boolean
    hasChecklist?: boolean
}

/**
 * 10 = Car
 * 20 = Van
 * 30 = Truck
 * 31 = Minibus
 * 32 = Bus
 * 33 = Largebus
 * 40 = ConstructionEquipment
 * 50 = FarmingEquipment
 * 60 = Tractors
 * 70 = HospitalEquipment
 * 80 = ManufacturingEquipment
 * 90 = HeavyTrailers
 * 100 = ItEquipment
 * 110 = Other
 * 999 = Virtual
 */
export enum AssetArcheType {
    Car = 10,
    Van = 20,
    Truck = 30,
    Minibus = 31,
    Bus = 32,
    Largebus = 33,
    ConstructionEquipment = 40,
    FarmingEquipment = 50,
    Tractors = 60,
    HospitalEquipment = 70,
    ManufacturingEquipment = 80,
    HeavyTrailers = 90,
    ItEquipment = 100,
    Other = 110,
    Virtual = 999,
}

/**
 * 0 = Company
 * 1 = Private
 */
export enum CustomerType {
    Company = 0,
    Private = 1,
}

export interface ProductTemplateDTO {
    name?: string
    /** @format int64 */
    templateId: number
    /**
     * 0 = UserUploaded
     * 1 = UserUploadedUsage
     * 100 = Contract
     * 101 = Offer
     * 102 = Guarantor
     * 103 = ConcentForm
     * 104 = ChangeTotalPrincipal
     * 105 = CheckList
     * 200 = PurchaseOrder
     * 300 = DeliveryAcceptance
     * 310 = Invoice
     * 311 = CreditNote
     * 312 = InvoiceNotificationEmail
     * 313 = CreditNoteEmail
     * 314 = InvoiceReminderEmail
     * 400 = Appendices
     * 500 = ContractClosing
     * 501 = DebtorChangeAllonge
     * 502 = ProlongAllonge
     * 503 = TerminationSettlement
     * 504 = PreterminationOffer
     * 505 = PreterminationSettlement
     * 506 = ExpirationNotice
     * 600 = CreditReport
     * 700 = ContractFinanceReport
     * 701 = Pledge
     * 801 = AssetImage
     * 802 = PartnerLogoImage
     * 803 = QueuedInvoicesCsvFile
     * 804 = Email
     */
    documentType: DocumentType
}

/**
 * 0 = UserUploaded
 * 1 = UserUploadedUsage
 * 100 = Contract
 * 101 = Offer
 * 102 = Guarantor
 * 103 = ConcentForm
 * 104 = ChangeTotalPrincipal
 * 105 = CheckList
 * 200 = PurchaseOrder
 * 300 = DeliveryAcceptance
 * 310 = Invoice
 * 311 = CreditNote
 * 312 = InvoiceNotificationEmail
 * 313 = CreditNoteEmail
 * 314 = InvoiceReminderEmail
 * 400 = Appendices
 * 500 = ContractClosing
 * 501 = DebtorChangeAllonge
 * 502 = ProlongAllonge
 * 503 = TerminationSettlement
 * 504 = PreterminationOffer
 * 505 = PreterminationSettlement
 * 506 = ExpirationNotice
 * 600 = CreditReport
 * 700 = ContractFinanceReport
 * 701 = Pledge
 * 801 = AssetImage
 * 802 = PartnerLogoImage
 * 803 = QueuedInvoicesCsvFile
 * 804 = Email
 */
export enum DocumentType {
    UserUploaded = 0,
    UserUploadedUsage = 1,
    Contract = 100,
    Offer = 101,
    Guarantor = 102,
    ConcentForm = 103,
    ChangeTotalPrincipal = 104,
    CheckList = 105,
    PurchaseOrder = 200,
    DeliveryAcceptance = 300,
    Invoice = 310,
    CreditNote = 311,
    InvoiceNotificationEmail = 312,
    CreditNoteEmail = 313,
    InvoiceReminderEmail = 314,
    Appendices = 400,
    ContractClosing = 500,
    DebtorChangeAllonge = 501,
    ProlongAllonge = 502,
    TerminationSettlement = 503,
    PreterminationOffer = 504,
    PreterminationSettlement = 505,
    ExpirationNotice = 506,
    CreditReport = 600,
    ContractFinanceReport = 700,
    Pledge = 701,
    AssetImage = 801,
    PartnerLogoImage = 802,
    QueuedInvoicesCsvFile = 803,
    Email = 804,
}

export interface ProductRuleDTO {
    targeted: boolean
    /**
     * 1 = Downpayment
     * 2 = RestValue
     * 3 = Price
     * 4 = UsagePrice
     * 5 = UsagePriceOverLimit
     * 6 = Interest
     * 7 = VariableInterest
     * 8 = LeasingPeriod
     * 9 = CreationFee
     * 10 = Deposit
     * 11 = IncludeCreationFee
     * 12 = IsVariableInterest
     * 13 = ExternalReferenceString1
     * 14 = ExternalReferenceString2
     * 15 = ExternalReferenceString3
     * 16 = ExternalReferenceString4
     * 17 = CancellationPeriod
     * 18 = LockedInPeriod
     * 19 = ExternalReferenceText
     */
    ruleType: ProductRuleType
    hidden: boolean
    locked: boolean
    /** @format decimal */
    minimumValue?: number
    /** @format decimal */
    maximumValue?: number
    defaultValue?: string
    customText?: string
    /**
     * 0 = Date
     * 1 = Numeric
     * 2 = Boolean
     * 3 = Textual
     */
    valueType?: ValueType
    description?: string
}

/**
 * 1 = Downpayment
 * 2 = RestValue
 * 3 = Price
 * 4 = UsagePrice
 * 5 = UsagePriceOverLimit
 * 6 = Interest
 * 7 = VariableInterest
 * 8 = LeasingPeriod
 * 9 = CreationFee
 * 10 = Deposit
 * 11 = IncludeCreationFee
 * 12 = IsVariableInterest
 * 13 = ExternalReferenceString1
 * 14 = ExternalReferenceString2
 * 15 = ExternalReferenceString3
 * 16 = ExternalReferenceString4
 * 17 = CancellationPeriod
 * 18 = LockedInPeriod
 * 19 = ExternalReferenceText
 */
export enum ProductRuleType {
    Downpayment = 1,
    RestValue = 2,
    Price = 3,
    UsagePrice = 4,
    UsagePriceOverLimit = 5,
    Interest = 6,
    VariableInterest = 7,
    LeasingPeriod = 8,
    CreationFee = 9,
    Deposit = 10,
    IncludeCreationFee = 11,
    IsVariableInterest = 12,
    ExternalReferenceString1 = 13,
    ExternalReferenceString2 = 14,
    ExternalReferenceString3 = 15,
    ExternalReferenceString4 = 16,
    CancellationPeriod = 17,
    LockedInPeriod = 18,
    ExternalReferenceText = 19,
}

/**
 * 0 = Date
 * 1 = Numeric
 * 2 = Boolean
 * 3 = Textual
 */
export enum ValueType {
    Date = 0,
    Numeric = 1,
    Boolean = 2,
    Textual = 3,
}

export interface CreateProduct {
    name?: string
    description?: string
    /** @format int32 */
    maxAllowedAssets: number
    isUsageEnabled: boolean
    /** @format date-time */
    validToDateTime?: string
    /** @format date-time */
    validFromDateTime: string
    productRules?: ProductRuleCommand[]
    /**
     * 10 = OperationalLeasing
     * 20 = FinancialLeasing
     * 30 = Sale
     * 40 = ShortTermRental
     * 50 = Rental
     * 60 = Subscription
     */
    productType: ProductType
    /**
     * 0 = Once
     * 1 = Monthly
     * 3 = ThreeMonthly
     * 4 = Quarterly
     * 5 = SixMonthly
     * 6 = Semiannual
     * 11 = TwelveMonthly
     * 12 = Annual
     */
    recurrence: Recurrence
    canBeProlonged: boolean
    isShadow: boolean
    /** @format int64 */
    defaultAssetTypeId?: number
    canBeDiscounted: boolean
}

export interface ProductRuleCommand {
    /**
     * 1 = Downpayment
     * 2 = RestValue
     * 3 = Price
     * 4 = UsagePrice
     * 5 = UsagePriceOverLimit
     * 6 = Interest
     * 7 = VariableInterest
     * 8 = LeasingPeriod
     * 9 = CreationFee
     * 10 = Deposit
     * 11 = IncludeCreationFee
     * 12 = IsVariableInterest
     * 13 = ExternalReferenceString1
     * 14 = ExternalReferenceString2
     * 15 = ExternalReferenceString3
     * 16 = ExternalReferenceString4
     * 17 = CancellationPeriod
     * 18 = LockedInPeriod
     * 19 = ExternalReferenceText
     */
    ruleType: ProductRuleType
    hidden: boolean
    locked: boolean
    targeted: boolean
    defaultValue?: string
    /** @format decimal */
    maximumValue?: number
    /** @format decimal */
    minimumValue?: number
    customText?: string
    description?: string
    /**
     * 0 = Date
     * 1 = Numeric
     * 2 = Boolean
     * 3 = Textual
     */
    valueType: ValueType
}

export interface UpdateProduct {
    /** @format int64 */
    id: number
    name?: string
    description?: string
    /** @format int32 */
    maxAllowedAssets: number
    isUsageEnabled: boolean
    /** @format date-time */
    validToDateTime?: string
    /** @format date-time */
    validFromDateTime: string
    productRules?: ProductRuleCommand[]
    /**
     * 0 = Once
     * 1 = Monthly
     * 3 = ThreeMonthly
     * 4 = Quarterly
     * 5 = SixMonthly
     * 6 = Semiannual
     * 11 = TwelveMonthly
     * 12 = Annual
     */
    recurrence: Recurrence
    canBeProlonged: boolean
    isShadow: boolean
    /** @format int64 */
    defaultAssetTypeId?: number
    canBeDiscounted: boolean
}

export interface SetupPenneoDto {
    clientKey?: string
    clientSecret?: string
    baseApiIntegrationUrl?: string
}

export interface DocumentMetaDataDto {
    /** @format int64 */
    id: number
    name?: string
    /** @format date-time */
    creationDate: string
    /** @format int32 */
    versionNumber: number
    /**
     * 0 = UserUploaded
     * 1 = UserUploadedUsage
     * 100 = Contract
     * 101 = Offer
     * 102 = Guarantor
     * 103 = ConcentForm
     * 104 = ChangeTotalPrincipal
     * 105 = CheckList
     * 200 = PurchaseOrder
     * 300 = DeliveryAcceptance
     * 310 = Invoice
     * 311 = CreditNote
     * 312 = InvoiceNotificationEmail
     * 313 = CreditNoteEmail
     * 314 = InvoiceReminderEmail
     * 400 = Appendices
     * 500 = ContractClosing
     * 501 = DebtorChangeAllonge
     * 502 = ProlongAllonge
     * 503 = TerminationSettlement
     * 504 = PreterminationOffer
     * 505 = PreterminationSettlement
     * 506 = ExpirationNotice
     * 600 = CreditReport
     * 700 = ContractFinanceReport
     * 701 = Pledge
     * 801 = AssetImage
     * 802 = PartnerLogoImage
     * 803 = QueuedInvoicesCsvFile
     * 804 = Email
     */
    documentType: DocumentType
    /**
     * 10 = Bmp
     * 20 = Doc
     * 30 = Docx
     * 40 = Gif
     * 50 = Jpg
     * 60 = Jpeg
     * 70 = Png
     * 80 = Pdf
     * 90 = Ppt
     * 100 = Pptx
     * 110 = Xls
     * 120 = Xlsx
     * 130 = Zip
     * 140 = Csv
     * 150 = Xml
     */
    extension: DocumentExtension
    customerNumber?: string
    contractNumber?: string
    assetNumber?: string
    foreignSystemDocumentId?: string
    /** @format int32 */
    documentStatus?: number
    /**
     * 0 = Penneo
     * 1 = Scrive
     */
    foreignSignatureSystemId?: ForeignSignatureSystem
    isUserUploaded: boolean
}

/**
 * 10 = Bmp
 * 20 = Doc
 * 30 = Docx
 * 40 = Gif
 * 50 = Jpg
 * 60 = Jpeg
 * 70 = Png
 * 80 = Pdf
 * 90 = Ppt
 * 100 = Pptx
 * 110 = Xls
 * 120 = Xlsx
 * 130 = Zip
 * 140 = Csv
 * 150 = Xml
 */
export enum DocumentExtension {
    Bmp = 10,
    Doc = 20,
    Docx = 30,
    Gif = 40,
    Jpg = 50,
    Jpeg = 60,
    Png = 70,
    Pdf = 80,
    Ppt = 90,
    Pptx = 100,
    Xls = 110,
    Xlsx = 120,
    Zip = 130,
    Csv = 140,
    Xml = 150,
}

/**
 * 0 = Penneo
 * 1 = Scrive
 */
export enum ForeignSignatureSystem {
    Penneo = 0,
    Scrive = 1,
}

export interface RefreshDocumentsPenneoStatusCommand {
    contractNumber: string
}

export interface ModuleDTO {
    /** @format int64 */
    id: number
    /**
     * 1 = ManualInvoicing
     * 2 = ContractFinance
     * 3 = CreditCheck
     * 4 = PartRegistration
     * 5 = Insurance
     */
    moduleType: ModuleType
    isAccessEnabled: boolean
}

/**
 * 1 = ManualInvoicing
 * 2 = ContractFinance
 * 3 = CreditCheck
 * 4 = PartRegistration
 * 5 = Insurance
 */
export enum ModuleType {
    ManualInvoicing = 1,
    ContractFinance = 2,
    CreditCheck = 3,
    PartRegistration = 4,
    Insurance = 5,
}

export interface CurrentUserResponse {
    /** @format int64 */
    id: number
    name: string
    shortCode: string
}

export interface PartnerDTO {
    /** @format int64 */
    id: number
    name: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    countryCode: CountryCode
    /**
     * 0 = AED
     * 1 = AFN
     * 2 = ALL
     * 3 = AMD
     * 4 = ANG
     * 5 = AOA
     * 6 = ARS
     * 7 = AUD
     * 8 = AWG
     * 9 = AZN
     * 10 = BAM
     * 11 = BBD
     * 12 = BDT
     * 13 = BGN
     * 14 = BHD
     * 15 = BIF
     * 16 = BMD
     * 17 = BND
     * 18 = BOB
     * 19 = BRL
     * 20 = BSD
     * 21 = BTN
     * 22 = BWP
     * 23 = BYN
     * 24 = BZD
     * 25 = CAD
     * 26 = CDF
     * 27 = CHF
     * 28 = CLP
     * 29 = CNY
     * 30 = COP
     * 31 = CRC
     * 32 = CUC
     * 33 = CUP
     * 34 = CVE
     * 35 = CZK
     * 36 = DJF
     * 37 = DKK
     * 38 = DOP
     * 39 = DZD
     * 40 = EGP
     * 41 = ERN
     * 42 = ETB
     * 43 = EUR
     * 44 = FJD
     * 45 = FKP
     * 46 = GBP
     * 47 = GEL
     * 48 = GGP
     * 49 = GHS
     * 50 = GIP
     * 51 = GMD
     * 52 = GNF
     * 53 = GTQ
     * 54 = GYD
     * 55 = HKD
     * 56 = HNL
     * 57 = HRK
     * 58 = HTG
     * 59 = HUF
     * 60 = IDR
     * 61 = ILS
     * 62 = IMP
     * 63 = INR
     * 64 = IQD
     * 65 = IRR
     * 66 = ISK
     * 67 = JEP
     * 68 = JMD
     * 69 = JOD
     * 70 = JPY
     * 71 = KES
     * 72 = KGS
     * 73 = KHR
     * 74 = KMF
     * 75 = KPW
     * 76 = KRW
     * 77 = KWD
     * 78 = KYD
     * 79 = KZT
     * 80 = LAK
     * 81 = LBP
     * 82 = LKR
     * 83 = LRD
     * 84 = LSL
     * 85 = LYD
     * 86 = MAD
     * 87 = MDL
     * 88 = MGA
     * 89 = MKD
     * 90 = MMK
     * 91 = MNT
     * 92 = MOP
     * 93 = MRO
     * 94 = MUR
     * 95 = MVR
     * 96 = MWK
     * 97 = MXN
     * 98 = MYR
     * 99 = MZN
     * 100 = NAD
     * 101 = NGN
     * 102 = NIO
     * 103 = NOK
     * 104 = NPR
     * 105 = NZD
     * 106 = OMR
     * 107 = PAB
     * 108 = PEN
     * 109 = PGK
     * 110 = PHP
     * 111 = PKR
     * 112 = PLN
     * 113 = PYG
     * 114 = QAR
     * 115 = RON
     * 116 = RSD
     * 117 = RUB
     * 118 = RWF
     * 119 = SAR
     * 120 = SBD
     * 121 = SCR
     * 122 = SDG
     * 123 = SEK
     * 124 = SGD
     * 125 = SHP
     * 126 = SLL
     * 127 = SOS
     * 128 = SPL
     * 129 = SRD
     * 130 = STD
     * 131 = SVC
     * 132 = SYP
     * 133 = SZL
     * 134 = THB
     * 135 = TJS
     * 136 = TMT
     * 137 = TND
     * 138 = TOP
     * 139 = TRY
     * 140 = TTD
     * 141 = TVD
     * 142 = TWD
     * 143 = TZS
     * 144 = UAH
     * 145 = UGX
     * 146 = USD
     * 147 = UYU
     * 148 = UZS
     * 149 = VEF
     * 150 = VND
     * 151 = VUV
     * 152 = WST
     * 153 = XAF
     * 154 = XCD
     * 155 = XDR
     * 156 = XOF
     * 157 = XPF
     * 158 = YER
     * 159 = ZAR
     * 160 = ZMW
     * 161 = ZWD
     */
    currencyCode: CurrencyCode
    currencyName: string
    shortCode: string
    locationNumber?: string
    isDemo: boolean
    email: string
    organizationNumber?: string
    cvr: string
    /** @format byte */
    uiLogoData?: string
}

/**
 * 0 = AED
 * 1 = AFN
 * 2 = ALL
 * 3 = AMD
 * 4 = ANG
 * 5 = AOA
 * 6 = ARS
 * 7 = AUD
 * 8 = AWG
 * 9 = AZN
 * 10 = BAM
 * 11 = BBD
 * 12 = BDT
 * 13 = BGN
 * 14 = BHD
 * 15 = BIF
 * 16 = BMD
 * 17 = BND
 * 18 = BOB
 * 19 = BRL
 * 20 = BSD
 * 21 = BTN
 * 22 = BWP
 * 23 = BYN
 * 24 = BZD
 * 25 = CAD
 * 26 = CDF
 * 27 = CHF
 * 28 = CLP
 * 29 = CNY
 * 30 = COP
 * 31 = CRC
 * 32 = CUC
 * 33 = CUP
 * 34 = CVE
 * 35 = CZK
 * 36 = DJF
 * 37 = DKK
 * 38 = DOP
 * 39 = DZD
 * 40 = EGP
 * 41 = ERN
 * 42 = ETB
 * 43 = EUR
 * 44 = FJD
 * 45 = FKP
 * 46 = GBP
 * 47 = GEL
 * 48 = GGP
 * 49 = GHS
 * 50 = GIP
 * 51 = GMD
 * 52 = GNF
 * 53 = GTQ
 * 54 = GYD
 * 55 = HKD
 * 56 = HNL
 * 57 = HRK
 * 58 = HTG
 * 59 = HUF
 * 60 = IDR
 * 61 = ILS
 * 62 = IMP
 * 63 = INR
 * 64 = IQD
 * 65 = IRR
 * 66 = ISK
 * 67 = JEP
 * 68 = JMD
 * 69 = JOD
 * 70 = JPY
 * 71 = KES
 * 72 = KGS
 * 73 = KHR
 * 74 = KMF
 * 75 = KPW
 * 76 = KRW
 * 77 = KWD
 * 78 = KYD
 * 79 = KZT
 * 80 = LAK
 * 81 = LBP
 * 82 = LKR
 * 83 = LRD
 * 84 = LSL
 * 85 = LYD
 * 86 = MAD
 * 87 = MDL
 * 88 = MGA
 * 89 = MKD
 * 90 = MMK
 * 91 = MNT
 * 92 = MOP
 * 93 = MRO
 * 94 = MUR
 * 95 = MVR
 * 96 = MWK
 * 97 = MXN
 * 98 = MYR
 * 99 = MZN
 * 100 = NAD
 * 101 = NGN
 * 102 = NIO
 * 103 = NOK
 * 104 = NPR
 * 105 = NZD
 * 106 = OMR
 * 107 = PAB
 * 108 = PEN
 * 109 = PGK
 * 110 = PHP
 * 111 = PKR
 * 112 = PLN
 * 113 = PYG
 * 114 = QAR
 * 115 = RON
 * 116 = RSD
 * 117 = RUB
 * 118 = RWF
 * 119 = SAR
 * 120 = SBD
 * 121 = SCR
 * 122 = SDG
 * 123 = SEK
 * 124 = SGD
 * 125 = SHP
 * 126 = SLL
 * 127 = SOS
 * 128 = SPL
 * 129 = SRD
 * 130 = STD
 * 131 = SVC
 * 132 = SYP
 * 133 = SZL
 * 134 = THB
 * 135 = TJS
 * 136 = TMT
 * 137 = TND
 * 138 = TOP
 * 139 = TRY
 * 140 = TTD
 * 141 = TVD
 * 142 = TWD
 * 143 = TZS
 * 144 = UAH
 * 145 = UGX
 * 146 = USD
 * 147 = UYU
 * 148 = UZS
 * 149 = VEF
 * 150 = VND
 * 151 = VUV
 * 152 = WST
 * 153 = XAF
 * 154 = XCD
 * 155 = XDR
 * 156 = XOF
 * 157 = XPF
 * 158 = YER
 * 159 = ZAR
 * 160 = ZMW
 * 161 = ZWD
 */
export enum CurrencyCode {
    AED = 0,
    AFN = 1,
    ALL = 2,
    AMD = 3,
    ANG = 4,
    AOA = 5,
    ARS = 6,
    AUD = 7,
    AWG = 8,
    AZN = 9,
    BAM = 10,
    BBD = 11,
    BDT = 12,
    BGN = 13,
    BHD = 14,
    BIF = 15,
    BMD = 16,
    BND = 17,
    BOB = 18,
    BRL = 19,
    BSD = 20,
    BTN = 21,
    BWP = 22,
    BYN = 23,
    BZD = 24,
    CAD = 25,
    CDF = 26,
    CHF = 27,
    CLP = 28,
    CNY = 29,
    COP = 30,
    CRC = 31,
    CUC = 32,
    CUP = 33,
    CVE = 34,
    CZK = 35,
    DJF = 36,
    DKK = 37,
    DOP = 38,
    DZD = 39,
    EGP = 40,
    ERN = 41,
    ETB = 42,
    EUR = 43,
    FJD = 44,
    FKP = 45,
    GBP = 46,
    GEL = 47,
    GGP = 48,
    GHS = 49,
    GIP = 50,
    GMD = 51,
    GNF = 52,
    GTQ = 53,
    GYD = 54,
    HKD = 55,
    HNL = 56,
    HRK = 57,
    HTG = 58,
    HUF = 59,
    IDR = 60,
    ILS = 61,
    IMP = 62,
    INR = 63,
    IQD = 64,
    IRR = 65,
    ISK = 66,
    JEP = 67,
    JMD = 68,
    JOD = 69,
    JPY = 70,
    KES = 71,
    KGS = 72,
    KHR = 73,
    KMF = 74,
    KPW = 75,
    KRW = 76,
    KWD = 77,
    KYD = 78,
    KZT = 79,
    LAK = 80,
    LBP = 81,
    LKR = 82,
    LRD = 83,
    LSL = 84,
    LYD = 85,
    MAD = 86,
    MDL = 87,
    MGA = 88,
    MKD = 89,
    MMK = 90,
    MNT = 91,
    MOP = 92,
    MRO = 93,
    MUR = 94,
    MVR = 95,
    MWK = 96,
    MXN = 97,
    MYR = 98,
    MZN = 99,
    NAD = 100,
    NGN = 101,
    NIO = 102,
    NOK = 103,
    NPR = 104,
    NZD = 105,
    OMR = 106,
    PAB = 107,
    PEN = 108,
    PGK = 109,
    PHP = 110,
    PKR = 111,
    PLN = 112,
    PYG = 113,
    QAR = 114,
    RON = 115,
    RSD = 116,
    RUB = 117,
    RWF = 118,
    SAR = 119,
    SBD = 120,
    SCR = 121,
    SDG = 122,
    SEK = 123,
    SGD = 124,
    SHP = 125,
    SLL = 126,
    SOS = 127,
    SPL = 128,
    SRD = 129,
    STD = 130,
    SVC = 131,
    SYP = 132,
    SZL = 133,
    THB = 134,
    TJS = 135,
    TMT = 136,
    TND = 137,
    TOP = 138,
    TRY = 139,
    TTD = 140,
    TVD = 141,
    TWD = 142,
    TZS = 143,
    UAH = 144,
    UGX = 145,
    USD = 146,
    UYU = 147,
    UZS = 148,
    VEF = 149,
    VND = 150,
    VUV = 151,
    WST = 152,
    XAF = 153,
    XCD = 154,
    XDR = 155,
    XOF = 156,
    XPF = 157,
    YER = 158,
    ZAR = 159,
    ZMW = 160,
    ZWD = 161,
}

export interface PartnerDetailsDTO {
    email: string
    shortCode: string
    name: string
    /** @format decimal */
    daysPerMonth: number
    paymentTerms: string
    firstPaymentTerms: string
    /** @format int64 */
    logoDocumentId?: number
    /** @format int64 */
    uiLogoDocumentId?: number
    locationNumber?: string
    smtpHost?: string
    /** @format int32 */
    smtpPort?: number
    smtpUserName?: string
    smtpPassword?: string
    smtpEnableSsl?: boolean
    /** @format int32 */
    lightModeColor: number
    /** @format int32 */
    darkModeColor: number
    numberSeries: NumberSerieDTO[]
}

export interface NumberSerieDTO {
    /**
     * 1 = InvoiceLine
     * 2 = Contract
     * 3 = Customer
     * 4 = Asset
     * 5 = Vendor
     * 7 = ContractAsset
     * 8 = Location
     * 9 = User
     * 10 = BaseService
     * 11 = BaseTransientFee
     * 12 = Invoice
     * 13 = BaseInsurance
     */
    entityType: NumberSeriesEntityType
    template?: string
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
    /** @format int64 */
    sequenceNumber: number
}

/**
 * 1 = InvoiceLine
 * 2 = Contract
 * 3 = Customer
 * 4 = Asset
 * 5 = Vendor
 * 7 = ContractAsset
 * 8 = Location
 * 9 = User
 * 10 = BaseService
 * 11 = BaseTransientFee
 * 12 = Invoice
 * 13 = BaseInsurance
 */
export enum NumberSeriesEntityType {
    InvoiceLine = 1,
    Contract = 2,
    Customer = 3,
    Asset = 4,
    Vendor = 5,
    ContractAsset = 7,
    Location = 8,
    User = 9,
    BaseService = 10,
    BaseTransientFee = 11,
    Invoice = 12,
    BaseInsurance = 13,
}

export interface DeleteCommandResponseDTO {
    uniqueNumber: string
    object: string
    deleted: boolean
}

export interface UserDTO {
    userNumber?: string
    subject?: string
    name?: string
    email?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    culture: CountryCode
    /**
     * 0 = DisabledUser
     * 5 = RestrictedUser
     * 10 = RegularUser
     * 30 = SuperUser
     * 40 = AdminUser
     * 50 = GlobalAdminUser
     * 100 = SystemUser
     */
    userGroup: UserGroup
    /**
     * 0 = Dark
     * 1 = Light
     * 2 = Purple
     */
    theme: UserTheme
    branchName?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    formatting: CountryCode
}

export interface ChangeUserPartnerCommand {
    /** @format int64 */
    newPartnerId: number
}

export interface PartnerParameterDefinitionDTO {
    key: string
    /**
     * 0 = Date
     * 1 = Numeric
     * 2 = Boolean
     * 3 = Textual
     */
    valueType: ValueType
    valueOptionsEnum?: string
    key1?: string
    /**
     * 0 = Date
     * 1 = Numeric
     * 2 = Boolean
     * 3 = Textual
     */
    valueType1?: ValueType
    key2?: string
    /**
     * 0 = Date
     * 1 = Numeric
     * 2 = Boolean
     * 3 = Textual
     */
    valueType2?: ValueType
    key3?: string
    /**
     * 0 = Date
     * 1 = Numeric
     * 2 = Boolean
     * 3 = Textual
     */
    valueType3?: ValueType
    key4?: string
    /**
     * 0 = Date
     * 1 = Numeric
     * 2 = Boolean
     * 3 = Textual
     */
    valueType4?: ValueType
    key5?: string
    /**
     * 0 = Date
     * 1 = Numeric
     * 2 = Boolean
     * 3 = Textual
     */
    valueType5?: ValueType
}

export interface ParameterDTO {
    key: string
    value: string
    diplayValue: string
    /** @format int64 */
    productId?: number
    value1?: string
    value2?: string
    value3?: string
    value4?: string
    value5?: string
}

export interface ParameterBaseCommand {
    key: string
    value: string
    value1?: string
    value2?: string
    value3?: string
    value4?: string
    value5?: string
}

export interface UserNotificationDTO {
    text?: string
    isSeen: boolean
    /** @format int64 */
    id: number
    link?: string
    /**
     * 1 = Welcome
     * 2 = ResetPassword
     * 3 = Information
     * 4 = Warning
     * 5 = Error
     * 6 = Success
     * 7 = Reminder
     */
    type: NotificationType
}

/**
 * 1 = Welcome
 * 2 = ResetPassword
 * 3 = Information
 * 4 = Warning
 * 5 = Error
 * 6 = Success
 * 7 = Reminder
 */
export enum NotificationType {
    Welcome = 1,
    ResetPassword = 2,
    Information = 3,
    Warning = 4,
    Error = 5,
    Success = 6,
    Reminder = 7,
}

export interface UpdateUserNotificationsAsSeenCommand {
    notificationsToUpdate?: number[]
}

export interface LocationDTO {
    locationNumber?: string
    locationName?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    countryCode: CountryCode
    city?: string
    street?: string
    building?: string
    floor?: string
    postalCode?: string
    contactName?: string
    phone?: string
    /** @format decimal */
    latitude: number
    /** @format decimal */
    longitude: number
    customerNumber?: string
    /**
     * 1 = Partner
     * 2 = Customer
     */
    locationType?: LocationType
}

/**
 * 1 = Partner
 * 2 = Customer
 */
export enum LocationType {
    Partner = 1,
    Customer = 2,
}

export type CreateLocationCommand = LocationBaseCommand & object

export interface LocationBaseCommand {
    locationName?: string
    street?: string
    building?: string
    floor?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    countryCode: CountryCode
    city?: string
    postalCode?: string
    contactName?: string
    phone?: string
    /** @format double */
    latitude?: number
    /** @format double */
    longitude?: number
    customerNumber?: string
    /**
     * 1 = Partner
     * 2 = Customer
     */
    locationType: LocationType
}

export type UpdateLocationCommand = LocationBaseCommand & {
    locationNumber: string
}

export interface GeneratePeriodicInvoicesRequest {
    /** @format int32 */
    yearPeriod?: number
    /** @format int32 */
    monthPeriod?: number
    generateUpUntilToday: boolean
}

export interface GenerateEndInvoiceCommand {
    contractNumber: string
    customerNumber?: string
}

export interface GenerateIndependentInvoiceRequest {
    transientFeeIds: number[]
}

export interface UpdatePaidStatusCommand {
    invoiceNumberList: string[]
    isPaid: boolean
    /** @format date-time */
    paymentDate: string
}

export interface InvoiceDTO {
    invoiceNumber?: string
    contractNumber?: string
    customerNumber?: string
    customerName?: string
    customerEmail?: string
    invoiceText?: string
    /** @format date-time */
    periodStartDate?: string
    /** @format date-time */
    periodEndDate?: string
    /** @format date-time */
    documentDate: string
    /** @format date-time */
    dueDate?: string
    /** @format date-time */
    creationDateTime?: string
    /**
     * 0 = Open
     * 10 = Posted
     * 20 = Credited
     * 30 = Approved
     */
    status: InvoiceStatus
    /** @format decimal */
    vatAmount: number
    /** @format decimal */
    amountIncludingVat: number
    /** @format decimal */
    amountExcludingVat: number
    /**
     * 0 = NotSend
     * 1 = Send
     * 2 = Failed
     */
    isInvoiceEmailSent: InvoiceEmailStatus
    /**
     * 0 = First
     * 1 = Periodic
     * 2 = End
     * 3 = Independent
     */
    invoiceType: InvoiceType
    /** @format decimal */
    receivedAmount: number
    isPaid: boolean
    /** @format int64 */
    reminderCountAlreadySent: number
    lines: InvoiceLineDTO[]
}

/**
 * 0 = Open
 * 10 = Posted
 * 20 = Credited
 * 30 = Approved
 */
export enum InvoiceStatus {
    Open = 0,
    Posted = 10,
    Credited = 20,
    Approved = 30,
}

/**
 * 0 = NotSend
 * 1 = Send
 * 2 = Failed
 */
export enum InvoiceEmailStatus {
    NotSend = 0,
    Send = 1,
    Failed = 2,
}

/**
 * 0 = First
 * 1 = Periodic
 * 2 = End
 * 3 = Independent
 */
export enum InvoiceType {
    First = 0,
    Periodic = 1,
    End = 2,
    Independent = 3,
}

export interface InvoiceLineDTO {
    /**
     * 10 = PeriodicPayment
     * 11 = PeriodicPaymentWithOverUsage
     * 20 = PartPayment
     * 21 = PartPaymentWithOverUsage
     * 30 = Fee
     * 40 = Downpayment
     * 60 = Deposit
     * 70 = Creation
     * 80 = Service
     * 90 = InsurancePolicy
     * 110 = Assets
     * 111 = AssetsWithOverUsage
     */
    lineType: InvoiceLineType
    /** @format int64 */
    assetServiceId?: number
    /** @format decimal */
    amountExcludingVat: number
    /** @format decimal */
    amountIncludingVat: number
    /** @format decimal */
    vatAmount: number
    lineText?: string
    /** @format decimal */
    vatPercentage: number
    invoiceLineNumber?: string
    assetNumber?: string
    /** @format date-time */
    start?: string
    /** @format date-time */
    end?: string
    /** @format date-time */
    creationDate?: string
    /** @format decimal */
    receivedAmount: number
    /** @format decimal */
    interest: number
    /** @format decimal */
    depreciation: number
    discount?: DiscountDTO
}

/**
 * 10 = PeriodicPayment
 * 11 = PeriodicPaymentWithOverUsage
 * 20 = PartPayment
 * 21 = PartPaymentWithOverUsage
 * 30 = Fee
 * 40 = Downpayment
 * 60 = Deposit
 * 70 = Creation
 * 80 = Service
 * 90 = InsurancePolicy
 * 110 = Assets
 * 111 = AssetsWithOverUsage
 */
export enum InvoiceLineType {
    PeriodicPayment = 10,
    PeriodicPaymentWithOverUsage = 11,
    PartPayment = 20,
    PartPaymentWithOverUsage = 21,
    Fee = 30,
    Downpayment = 40,
    Deposit = 60,
    Creation = 70,
    Service = 80,
    InsurancePolicy = 90,
    Assets = 110,
    AssetsWithOverUsage = 111,
}

export interface DiscountDTO {
    /** @format int64 */
    id?: number
    /** @format decimal */
    value: number
    /** @format int32 */
    period?: number
}

export interface SendInvoiceReminderEmailCommand {
    invoiceNumber: string
}

export interface CreditInvoiceCommand {
    invoiceNumber: string
}

export interface PagedModelOfInvoiceDTO {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: InvoiceDTO[]
}

/**
 * 0 = Initialized
 * 1 = Failed
 * 2 = Exported
 * 4 = Paid
 */
export enum ExportStatus {
    Initialized = 0,
    Failed = 1,
    Exported = 2,
    Paid = 4,
}

export interface PagedModelOfInvoiceExportedDTO {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: InvoiceExportedDTO[]
}

export type InvoiceExportedDTO = InvoiceDTO & {
    initializedUser?: string
    /** @format date-time */
    initializedDate: string
    /** @format date-time */
    exportDate: string
    /**
     * 0 = Initialized
     * 1 = Failed
     * 2 = Exported
     * 4 = Paid
     */
    exportStatus: ExportStatus
    externalInvoiceId?: string
    /**
     * 1 = Pending
     * 2 = Completed
     * 3 = Failed
     */
    emailEntryStatus?: InvoiceEmailEntryStatus
    emailEntryErrorMessage?: string
}

/**
 * 1 = Pending
 * 2 = Completed
 * 3 = Failed
 */
export enum InvoiceEmailEntryStatus {
    Pending = 1,
    Completed = 2,
    Failed = 3,
}

/**
 * 100 = EconomicsErp
 * 101 = BusinessCentralErp
 * 102 = ErpAlternativeFileCreation
 * 103 = Fortnox
 * 104 = Uniconta
 * 105 = EAccounting
 * 106 = Netvisor
 * 200 = PenneoDigitalSignature
 * 201 = ScriveDigitalSignature
 * 300 = CreditSafe
 * 400 = HubSpot
 */
export enum ExternalPartnerSetup {
    EconomicsErp = 100,
    BusinessCentralErp = 101,
    ErpAlternativeFileCreation = 102,
    Fortnox = 103,
    Uniconta = 104,
    EAccounting = 105,
    Netvisor = 106,
    PenneoDigitalSignature = 200,
    ScriveDigitalSignature = 201,
    CreditSafe = 300,
    HubSpot = 400,
}

export interface InvoicePaymentReportDTO {
    invoiceNumber?: string
    customerNumber?: string
    /** @format decimal */
    interest: number
    /** @format decimal */
    depreciation: number
    /**
     * 0 = First
     * 1 = Periodic
     * 2 = End
     * 3 = Independent
     */
    invoiceType: InvoiceType
    /**
     * 0 = Initialized
     * 1 = Failed
     * 2 = Exported
     * 4 = Paid
     */
    exportStatus?: ExportStatus
}

export interface BaseInsuranceDto {
    baseInsuranceNumber: string
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
    description?: string
    /** @format int64 */
    vatId: number
    erpProductId?: string
    /** @format int64 */
    documentMetaDataId?: number
    baseInsurancePriceSteps: BaseInsurancePriceStepDto[]
    productInsurances: ProductInsuranceDto[]
}

export interface BaseInsurancePriceStepDto {
    /** @format int64 */
    id: number
    /** @format decimal */
    maxValue: number
    /** @format decimal */
    premiumPrice: number
    /** @format decimal */
    deductiblePrice: number
    /** @format decimal */
    netPrice?: number
    note?: string
}

export interface ProductInsuranceDto {
    /** @format int64 */
    productId: number
    isSelectable: boolean
}

export interface CreateBaseInsurance {
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
    description?: string
    /** @format int64 */
    vatId: number
    erpProductId?: string
    baseInsurancePriceSteps: BaseInsurancePriceStepDto[]
    productInsurances: ProductInsuranceDto[]
}

export interface UpdateBaseInsurance {
    baseInsuranceNumber: string
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
    description?: string
    /** @format int64 */
    vatId: number
    erpProductId?: string
    baseInsurancePriceSteps: BaseInsurancePriceStepDto[]
    productInsurances: ProductInsuranceDto[]
}

export interface InsurancePolicyNumbersDto {
    /** @format decimal */
    premiumPrice: number
    /** @format decimal */
    deductiblePrice: number
    /** @format decimal */
    netPrice?: number
}

export interface ContractAssetDTO {
    baseAssetNumber?: string
    assetNumber?: string
    make?: string
    model?: string
    registrationNumber?: string
    vin?: string
    serialNumber?: string
    /** @format decimal */
    originalPurchaseValue?: number
    /** @format int64 */
    assetTypeId?: number
    assetTypeData?: AssetTypeDTO
    /** @format date-time */
    firstRegistrationDateTime?: string
    /** @format date-time */
    expectedDeliveryDate?: string
    power?: string
    exteriorColor?: string
    interiorColor?: string
    modelYear?: string
    variant?: string
    productNumber?: string
    /**
     * 0 = Gasoline
     * 1 = Diesel
     * 2 = Electric
     * 3 = HybridGasoline
     * 4 = HybridDiesel
     * 5 = HydrogenFuelCell
     */
    fuelType?: FuelType
    gtin?: string
    /** @format decimal */
    purchaseValue: number
    /** @format decimal */
    deliveryCost: number
    /** @format decimal */
    restValue: number
    /** @format decimal */
    registrationValue?: number
    /** @format decimal */
    principal: number
    /** @format decimal */
    periodicPayment: number
    /** @format decimal */
    excessPaymentFee: number
    isPartRegistration: boolean
    isAssetUsed: boolean
    /** @format decimal */
    ageInMonths?: number
    /** @format decimal */
    usageLimit?: number
    /** @format decimal */
    usageStandAtStart?: number
    /** @format decimal */
    pricePerUnitOverUsageLimit?: number
    /** @format decimal */
    pricePerUnitUnderUsageLimit?: number
    /** @format decimal */
    downpayment?: number
    /**
     * 0 = Virtual
     * 1 = Ready
     * 2 = Active
     * 3 = Closed
     * 4 = Cancelled
     * 5 = ScheduledForClose
     */
    status: ContractAssetStatus
    /** @format date-time */
    dateTimeActivation?: string
    assetServices: AssetServiceDTO[]
    insurancePolicies: InsurancePolicyDto[]
    discount?: DiscountDTO
    location?: LocationDTO
    assetUsages?: UsageDTO[]
    locationNumber?: string
    /**
     * 0 = Hourly
     * 1 = Daily
     * 2 = Count
     * 3 = Distance
     */
    usageType?: UsageType
    /**
     * 0 = RoundUpToTheMinimum
     * 1 = PreciseCalculation
     */
    usagePriceCalculationMethod?: ContractAssetUsagePriceCalculationMethod
    /** @format int64 */
    deliveryDocumentId?: number
    /** @format int32 */
    lockInPeriod?: number
    /** @format int32 */
    cancellationPeriod?: number
    /** @format date-time */
    endDate?: string
}

export interface AssetTypeDTO {
    /** @format int64 */
    id: number
    name?: string
    /**
     * 10 = Car
     * 20 = Van
     * 30 = Truck
     * 31 = Minibus
     * 32 = Bus
     * 33 = Largebus
     * 40 = ConstructionEquipment
     * 50 = FarmingEquipment
     * 60 = Tractors
     * 70 = HospitalEquipment
     * 80 = ManufacturingEquipment
     * 90 = HeavyTrailers
     * 100 = ItEquipment
     * 110 = Other
     * 999 = Virtual
     */
    assetArcheType: AssetArcheType
    isActive: boolean
    isMotor: boolean
}

/**
 * 0 = Gasoline
 * 1 = Diesel
 * 2 = Electric
 * 3 = HybridGasoline
 * 4 = HybridDiesel
 * 5 = HydrogenFuelCell
 */
export enum FuelType {
    Gasoline = 0,
    Diesel = 1,
    Electric = 2,
    HybridGasoline = 3,
    HybridDiesel = 4,
    HydrogenFuelCell = 5,
}

/**
 * 0 = Virtual
 * 1 = Ready
 * 2 = Active
 * 3 = Closed
 * 4 = Cancelled
 * 5 = ScheduledForClose
 */
export enum ContractAssetStatus {
    Virtual = 0,
    Ready = 1,
    Active = 2,
    Closed = 3,
    Cancelled = 4,
    ScheduledForClose = 5,
}

export interface AssetServiceDTO {
    /** @format int64 */
    id: number
    baseServiceNumber?: string
    /** @format decimal */
    fixedValueOverride?: number
    /** @format decimal */
    percentage?: number
    isEnabled?: boolean
    discount?: DiscountDTO
}

export interface InsurancePolicyDto {
    /** @format int64 */
    id: number
    baseInsuranceNumber: string
    /** @format decimal */
    premiumPrice: number
    /** @format decimal */
    deductiblePrice: number
    /** @format decimal */
    netPrice?: number
    isEnabled: boolean
}

/**
 * 0 = RoundUpToTheMinimum
 * 1 = PreciseCalculation
 */
export enum ContractAssetUsagePriceCalculationMethod {
    RoundUpToTheMinimum = 0,
    PreciseCalculation = 1,
}

export interface CreateInsurancePolicy {
    contractAssetNumber: string
    baseInsuranceNumber: string
}

export interface ExternalAuthorizationDTO {
    /** @format int64 */
    partnerId: number
    authorizationCode?: string
    /** @format guid */
    state: string
    message?: string
    /**
     * 1 = ERP
     * 2 = DocumentSigningSystem
     * 3 = CreditBureau
     * 4 = Crm
     */
    externalSystemType: ExternalSystemType
    /**
     * 0 = New
     * 1 = Successful
     * 2 = Failed
     */
    status: AuthFlowStatus
    forwardTo?: string
    shouldForward: boolean
}

/**
 * 1 = ERP
 * 2 = DocumentSigningSystem
 * 3 = CreditBureau
 * 4 = Crm
 */
export enum ExternalSystemType {
    ERP = 1,
    DocumentSigningSystem = 2,
    CreditBureau = 3,
    Crm = 4,
}

/**
 * 0 = New
 * 1 = Successful
 * 2 = Failed
 */
export enum AuthFlowStatus {
    New = 0,
    Successful = 1,
    Failed = 2,
}

export interface StartExternalAuthorizationFlowRequest {
    /**
     * 100 = EconomicsErp
     * 101 = BusinessCentralErp
     * 102 = ErpAlternativeFileCreation
     * 103 = Fortnox
     * 104 = Uniconta
     * 105 = EAccounting
     * 106 = Netvisor
     * 200 = PenneoDigitalSignature
     * 201 = ScriveDigitalSignature
     * 300 = CreditSafe
     * 400 = HubSpot
     */
    externalSystem: ExternalPartnerSetup
}

export interface InitializeInvoiceExport {
    exportedInvoices: string[]
}

export interface ERPProductDTO {
    name?: string
    productNumber?: string
}

export interface ERPPaymentTermDTO {
    paymentTermNumber?: string
    name?: string
}

export interface ERPLayoutDTO {
    layoutNumber?: string
    name?: string
}

export interface ERPCustomerGroupDTO {
    /** @format int32 */
    groupNumber: number
    name?: string
}

export interface ERPAllDataDTO {
    products?: ERPProductDTO[]
    customerGroups?: ERPCustomerGroupDTO[]
    paymentTerms?: ERPPaymentTermDTO[]
    layouts?: ERPLayoutDTO[]
}

export interface ExternalPartnerSystemsSetupViewDto {
    externalSystemType?: string
    setupType?: string
    baseApiIntegrationUrl?: string
}

export interface UpdateExternalPartnerSystemSetupCommand {
    agreementToken?: string
    clientKey?: string
    clientSecret?: string
    /**
     * 100 = EconomicsErp
     * 101 = BusinessCentralErp
     * 102 = ErpAlternativeFileCreation
     * 103 = Fortnox
     * 104 = Uniconta
     * 105 = EAccounting
     * 106 = Netvisor
     * 200 = PenneoDigitalSignature
     * 201 = ScriveDigitalSignature
     * 300 = CreditSafe
     * 400 = HubSpot
     */
    setupType: ExternalPartnerSetup
    /**
     * 1 = ERP
     * 2 = DocumentSigningSystem
     * 3 = CreditBureau
     * 4 = Crm
     */
    externalSystemType: ExternalSystemType
    lineProductPairs?: ErpLineMappingDTO[]
    erpSetups?: ERPParametersDTO[]
}

export interface ErpLineMappingDTO {
    /** @format int64 */
    productId?: number
    /**
     * 10 = PeriodicPayment
     * 11 = PeriodicPaymentWithOverUsage
     * 20 = PartPayment
     * 21 = PartPaymentWithOverUsage
     * 30 = Fee
     * 40 = Downpayment
     * 60 = Deposit
     * 70 = Creation
     * 80 = Service
     * 90 = InsurancePolicy
     * 110 = Assets
     * 111 = AssetsWithOverUsage
     */
    lineType: InvoiceLineType
    erpProductId: string
    lineTextOverride?: string
}

export interface ERPParametersDTO {
    /** @format int32 */
    defaultLayout: number
    defaultPaymentTerm?: string
    /** @format int32 */
    defaultCustomerGroup: number
    /** @format int32 */
    productId: number
}

export interface ERPSetupDTO {
    agreementToken?: string
    lineProductPairs: ErpLineMappingDTO[]
    erpSetups: ERPParametersDTO[]
    clientKey?: string
    clientSecret?: string
    /**
     * 100 = EconomicsErp
     * 101 = BusinessCentralErp
     * 102 = ErpAlternativeFileCreation
     * 103 = Fortnox
     * 104 = Uniconta
     * 105 = EAccounting
     * 106 = Netvisor
     * 200 = PenneoDigitalSignature
     * 201 = ScriveDigitalSignature
     * 300 = CreditSafe
     * 400 = HubSpot
     */
    setupType?: ExternalPartnerSetup
    /**
     * 1 = ERP
     * 2 = DocumentSigningSystem
     * 3 = CreditBureau
     * 4 = Crm
     */
    externalSystemType: ExternalSystemType
    baseApiIntegrationUrl?: string
}

export interface ErrorMessage {
    errorCode?: string
    errorDescription?: string
}

export interface DocumentSigningSystemSetupDTO {
    /**
     * 100 = EconomicsErp
     * 101 = BusinessCentralErp
     * 102 = ErpAlternativeFileCreation
     * 103 = Fortnox
     * 104 = Uniconta
     * 105 = EAccounting
     * 106 = Netvisor
     * 200 = PenneoDigitalSignature
     * 201 = ScriveDigitalSignature
     * 300 = CreditSafe
     * 400 = HubSpot
     */
    setupType?: ExternalPartnerSetup
}

export interface SynchronizationInfoDTO {
    customerNumber?: string
    name?: string
    matchFound: boolean
    externalCustomerNumber?: string
}

export interface SynchronizeCustomersCommand {
    /**
     * 100 = EconomicsErp
     * 101 = BusinessCentralErp
     * 102 = ErpAlternativeFileCreation
     * 103 = Fortnox
     * 104 = Uniconta
     * 105 = EAccounting
     * 106 = Netvisor
     * 200 = PenneoDigitalSignature
     * 201 = ScriveDigitalSignature
     * 300 = CreditSafe
     * 400 = HubSpot
     */
    specificIntegration?: ExternalPartnerSetup
}

export interface AuthorizationRequest {
    aggrementToken: string
    clientId: string
    clientSecret: string
}

export interface PagedModelOfDocumentMetaDataDto {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: DocumentMetaDataDto[]
}

export interface DownloadMultipleRequest {
    ids: number[]
}

/**
 * 0 = NotSigned
 * 1 = Signed
 */
export enum UploadedDocumentStatus {
    NotSigned = 0,
    Signed = 1,
}

export interface CopyFromExistingAssetRequest {
    sourceAssetNumber?: string
    destinationAssetNumber?: string
}

export interface UpdateDocumentCommand {
    /** @format int64 */
    documentId: number
    newDocumentName: string
    /**
     * 0 = UserUploaded
     * 1 = UserUploadedUsage
     * 100 = Contract
     * 101 = Offer
     * 102 = Guarantor
     * 103 = ConcentForm
     * 104 = ChangeTotalPrincipal
     * 105 = CheckList
     * 200 = PurchaseOrder
     * 300 = DeliveryAcceptance
     * 310 = Invoice
     * 311 = CreditNote
     * 312 = InvoiceNotificationEmail
     * 313 = CreditNoteEmail
     * 314 = InvoiceReminderEmail
     * 400 = Appendices
     * 500 = ContractClosing
     * 501 = DebtorChangeAllonge
     * 502 = ProlongAllonge
     * 503 = TerminationSettlement
     * 504 = PreterminationOffer
     * 505 = PreterminationSettlement
     * 506 = ExpirationNotice
     * 600 = CreditReport
     * 700 = ContractFinanceReport
     * 701 = Pledge
     * 801 = AssetImage
     * 802 = PartnerLogoImage
     * 803 = QueuedInvoicesCsvFile
     * 804 = Email
     */
    documentType: DocumentType
    /**
     * 0 = NotSigned
     * 1 = Signed
     */
    documentStatus: UploadedDocumentStatus
}

export interface GenerateDocumentCommand {
    contractNumber: string
    /**
     * 0 = UserUploaded
     * 1 = UserUploadedUsage
     * 100 = Contract
     * 101 = Offer
     * 102 = Guarantor
     * 103 = ConcentForm
     * 104 = ChangeTotalPrincipal
     * 105 = CheckList
     * 200 = PurchaseOrder
     * 300 = DeliveryAcceptance
     * 310 = Invoice
     * 311 = CreditNote
     * 312 = InvoiceNotificationEmail
     * 313 = CreditNoteEmail
     * 314 = InvoiceReminderEmail
     * 400 = Appendices
     * 500 = ContractClosing
     * 501 = DebtorChangeAllonge
     * 502 = ProlongAllonge
     * 503 = TerminationSettlement
     * 504 = PreterminationOffer
     * 505 = PreterminationSettlement
     * 506 = ExpirationNotice
     * 600 = CreditReport
     * 700 = ContractFinanceReport
     * 701 = Pledge
     * 801 = AssetImage
     * 802 = PartnerLogoImage
     * 803 = QueuedInvoicesCsvFile
     * 804 = Email
     */
    documentType: DocumentType
    assetNumber?: string
}

export interface GenerateDeliveryAcceptanceDocumentCommand {
    contractNumber: string
    assetNumbers: string[]
    /** @format date-time */
    deliveryDate: string
}

export interface GenerateInvoiceDocumentCommand {
    invoiceNumber: string
}

export interface SendDocumentsAsEmail {
    documentIds: number[]
    carbonCopyRecipients: string[]
    to: string
    /** @format int64 */
    emailDocumentTemplateId: number
}

export interface DocumentTemplateDTO {
    /** @format int64 */
    id: number
    name?: string
    /**
     * 0 = UserUploaded
     * 1 = UserUploadedUsage
     * 100 = Contract
     * 101 = Offer
     * 102 = Guarantor
     * 103 = ConcentForm
     * 104 = ChangeTotalPrincipal
     * 105 = CheckList
     * 200 = PurchaseOrder
     * 300 = DeliveryAcceptance
     * 310 = Invoice
     * 311 = CreditNote
     * 312 = InvoiceNotificationEmail
     * 313 = CreditNoteEmail
     * 314 = InvoiceReminderEmail
     * 400 = Appendices
     * 500 = ContractClosing
     * 501 = DebtorChangeAllonge
     * 502 = ProlongAllonge
     * 503 = TerminationSettlement
     * 504 = PreterminationOffer
     * 505 = PreterminationSettlement
     * 506 = ExpirationNotice
     * 600 = CreditReport
     * 700 = ContractFinanceReport
     * 701 = Pledge
     * 801 = AssetImage
     * 802 = PartnerLogoImage
     * 803 = QueuedInvoicesCsvFile
     * 804 = Email
     */
    documentType: DocumentType
    headerHeight?: string
    footerHeight?: string
    documentTemplateContent: DocumentTemplateContentDTO
    /** @format int64 */
    appendixDocumentId?: number
}

export interface DocumentTemplateContentDTO {
    /** @format int64 */
    documentTemplateId: number
    headerContent?: string
    bodyContent?: string
    footerContent?: string
}

export interface UpdateDocumentProductMappingCommand {
    /** @format int64 */
    productId: number
    /** @format int64 */
    templateId: number
}

export interface DeleteDocumentProductMappingCommand {
    /** @format int64 */
    productId: number
    /** @format int64 */
    templateId: number
}

export interface ContractsPerStatusDto {
    /** @format int32 */
    month: number
    /** @format int32 */
    numberOfContracts: number
}

export interface GetContractsPerStatusData {
    /** @format int32 */
    year: number
    /** @format int64 */
    productId?: number
    /**
     * 10 = OperationalLeasing
     * 20 = FinancialLeasing
     * 30 = Sale
     * 40 = ShortTermRental
     * 50 = Rental
     * 60 = Subscription
     */
    productType?: ProductType
}

export interface ActivatedAssetsVolumeDto {
    /** @format int32 */
    activatedAssetsNumber: number
    /** @format decimal */
    totalPurchaseValue: number
    /** @format decimal */
    totalRestValue: number
    /** @format decimal */
    totalDownpayment?: number
}

export interface ContractsPerProductDto {
    /** @format int64 */
    productId: number
    productName?: string
    /** @format int32 */
    totalContractsNumber: number
}

export interface ContractsPaymentsPerMonthDto {
    /** @format int32 */
    year: number
    /** @format int32 */
    month: number
    /** @format decimal */
    totalPeriodicPayments: number
}

export interface CustomerContractsPerStatusDto {
    /**
     * 0 = Offer
     * 1 = Active
     * 2 = Closed
     * 3 = Cancelled
     * 4 = ScheduledForClose
     * 5 = UnderClosing
     */
    status: ContractStatus
    customerName?: string
    /** @format int32 */
    totalContractsNumber: number
}

/**
 * 0 = Offer
 * 1 = Active
 * 2 = Closed
 * 3 = Cancelled
 * 4 = ScheduledForClose
 * 5 = UnderClosing
 */
export enum ContractStatus {
    Offer = 0,
    Active = 1,
    Closed = 2,
    Cancelled = 3,
    ScheduledForClose = 4,
    UnderClosing = 5,
}

export interface ActivatedContractCountPerMonthDto {
    /** @format int32 */
    year: number
    /** @format int32 */
    month: number
    /** @format int32 */
    numberOfContracts: number
}

export type GetActivatedContractCountPerMonthRequest = BaseDashboardQueryParameters & object

export interface BaseDashboardQueryParameters {
    /** @format date */
    startDate: string
    /** @format date */
    endDate: string
    /** @format int64 */
    productId?: number
    /**
     * 10 = OperationalLeasing
     * 20 = FinancialLeasing
     * 30 = Sale
     * 40 = ShortTermRental
     * 50 = Rental
     * 60 = Subscription
     */
    productType?: ProductType
}

export interface AverageDurationOfContractsDto {
    /**
     * 10 = OperationalLeasing
     * 20 = FinancialLeasing
     * 30 = Sale
     * 40 = ShortTermRental
     * 50 = Rental
     * 60 = Subscription
     */
    productType: ProductType
    /** @format decimal */
    averageDuration: number
}

export type GetAverageDurationOfContractsPerProductTypeRequest = BaseDashboardQueryParameters & object

export interface AverageCreationFeeOfContractsDto {
    /** @format decimal */
    averageCreationFee: number
    /** @format int32 */
    numberOfContracts: number
}

export type GetAverageCreationFeeOfContractsRequest = BaseDashboardQueryParameters & object

export interface CustomerStatisticDto {
    /** @format int32 */
    year: number
    /** @format int32 */
    month: number
    /** @format int32 */
    totalNewCustomers: number
    /** @format int32 */
    totalChurnedCustomers: number
    /** @format int32 */
    activeCustomers: number
    /** @format double */
    churnRate: number
}

export interface GetCustomerChurnRateRequest {
    /** @format int64 */
    productId?: number
    /**
     * 10 = OperationalLeasing
     * 20 = FinancialLeasing
     * 30 = Sale
     * 40 = ShortTermRental
     * 50 = Rental
     * 60 = Subscription
     */
    productType?: ProductType
}

export interface AmountsPerMonthDto {
    /** @format int32 */
    year: number
    /** @format int32 */
    month: number
    /** @format decimal */
    amount: number
}

export type GetTotalAmountOfPartialPayment = BaseDashboardQueryParameters & object

export type GetAverageRevenue = BaseDashboardQueryParameters & object

export interface MonthlyFinancialSummary {
    /** @format int32 */
    year: number
    /** @format int32 */
    month: number
    /** @format decimal */
    revenue: number
}

export type GetMonthlyRecurringRevenueRequest = BaseDashboardQueryParameters & object

export interface CustomerDTO {
    customerNumber?: string
    /**
     * 0 = Company
     * 1 = Private
     */
    customerType: CustomerType
    cvr?: string
    cpr?: string
    name?: string
    contactName?: string
    email?: string
    invoicingEmail?: string
    /**
     * 10 = EmailAlways
     * 20 = EmailFirst
     * 999 = None
     */
    invoiceCommunication: InvoiceCommunicationType
    street?: string
    building?: string
    floor?: string
    postalCode?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    country: CountryCode
    city?: string
    phone?: string
    bankAccountNumber?: string
    bankRegistrationNumber?: string
    bsAgreementNumber?: string
    bsReferenceNumber?: string
    externalReferenceString1?: string
    externalReferenceString2?: string
    externalReferenceString3?: string
    externalReferenceString4?: string
    externalReferenceText1?: string
    externalCustomerId?: string
    canBeDeleted: boolean
    advisor?: AdvisorDTO
    crmCustomerNumber?: string
    creditSafeConnectId?: string
}

/**
 * 10 = EmailAlways
 * 20 = EmailFirst
 * 999 = None
 */
export enum InvoiceCommunicationType {
    EmailAlways = 10,
    EmailFirst = 20,
    None = 999,
}

export interface AdvisorDTO {
    userNumber?: string
    name?: string
    branchName?: string
}

export type CreateCustomerCommand = CustomerBaseCommand & {
    /**
     * 0 = Company
     * 1 = Private
     */
    customerType: CustomerType
    cvr?: string
    cpr?: string
    creditSafeConnectId?: string
    crmCustomerNumber?: string
}

export interface CustomerBaseCommand {
    customerNumber: string
    name?: string
    contactName?: string
    email?: string
    invoicingEmail?: string
    /**
     * 10 = EmailAlways
     * 20 = EmailFirst
     * 999 = None
     */
    invoiceCommunication: InvoiceCommunicationType
    street?: string
    building?: string
    floor?: string
    postalCode?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    country: CountryCode
    city?: string
    phone?: string
    bankAccountNumber?: string
    bankRegistrationNumber?: string
    bsAgreementNumber?: string
    bsReferenceNumber?: string
    externalReferenceString1?: string
    externalReferenceString2?: string
    externalReferenceString3?: string
    externalReferenceString4?: string
    externalReferenceText1?: string
    advisorUserNumber?: string
}

export type UpdateCustomerCommand = CustomerBaseCommand & {
    oldCustomerNumber: string
    creditSafeConnectId?: string
}

export interface ICvrEntity {
    cvr?: string
    name?: string
    businessCode?: string
    phone?: string
    email?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    country: CountryCode
    street?: string
    floor?: string
    building?: string
    city?: string
    postalCode?: string
}

export interface SearchForCvrRequest {
    query: string
}

export interface ICompanySearchResult {
    connectId?: string
    registrationNumber?: string
    name?: string
    businessCode?: string
    phone?: string
    email?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    country: CountryCode
    street?: string
    floor?: string
    building?: string
    city?: string
    postalCode?: string
}

export interface CreditReportBO {
    /** @format int64 */
    id: number
    /** @format int64 */
    customerId: number
    assessment?: string
    commonRatingValue?: string
    creditLimit?: string
    companyStatus?: string
    /** @format date-time */
    queryDate: string
    /** @format int64 */
    documentId: number
}

export interface CreditReportDTO {
    assessment?: string
    commonRatingValue?: string
    creditLimit?: string
    companyStatus?: string
}

export interface GetCreditReportCommand {
    customerNumber: string
}

export interface GuarantorDTO {
    /** @format int64 */
    id: number
    name?: string
    cpr?: string
    cvr?: string
    /**
     * 0 = Company
     * 1 = Private
     */
    guarantorType: CustomerType
    email?: string
    phone?: string
    roadAndNumber?: string
    street?: string
    building?: string
    floor?: string
    postalCode?: string
    city?: string
    state?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    country?: CountryCode
}

export type CreateGuarantorCommand = GuarantorBaseCommand & {
    cpr?: string
    cvr?: string
    /**
     * 0 = Company
     * 1 = Private
     */
    guarantorType: CustomerType
}

export interface GuarantorBaseCommand {
    name?: string
    email?: string
    phone?: string
    roadAndNumber?: string
    street?: string
    building?: string
    floor?: string
    postalCode?: string
    city?: string
    state?: string
    /**
     * 0 = AX
     * 1 = AF
     * 2 = AL
     * 3 = DZ
     * 4 = AS
     * 5 = AD
     * 6 = AO
     * 7 = AI
     * 8 = AQ
     * 9 = AG
     * 10 = AR
     * 11 = AM
     * 12 = AW
     * 13 = AU
     * 14 = AT
     * 15 = AZ
     * 16 = BS
     * 17 = BH
     * 18 = BD
     * 19 = BB
     * 20 = BY
     * 21 = BE
     * 22 = BZ
     * 23 = BJ
     * 24 = BM
     * 25 = BT
     * 26 = BO
     * 27 = BA
     * 28 = BW
     * 29 = BV
     * 30 = BR
     * 31 = IO
     * 32 = BN
     * 33 = BG
     * 34 = BF
     * 35 = BI
     * 36 = KH
     * 37 = CM
     * 38 = CA
     * 39 = CV
     * 40 = KY
     * 41 = CF
     * 42 = TD
     * 43 = CL
     * 44 = CN
     * 45 = CX
     * 46 = CC
     * 47 = CO
     * 48 = KM
     * 49 = CD
     * 50 = CG
     * 51 = CK
     * 52 = CR
     * 53 = CI
     * 54 = HR
     * 55 = CU
     * 56 = CY
     * 57 = CZ
     * 58 = DK
     * 59 = DJ
     * 60 = DM
     * 61 = DO
     * 62 = EC
     * 63 = EG
     * 64 = SV
     * 65 = GQ
     * 66 = ER
     * 67 = EE
     * 68 = ET
     * 69 = FK
     * 70 = FO
     * 71 = FJ
     * 72 = FI
     * 73 = FR
     * 74 = GF
     * 75 = PF
     * 76 = TF
     * 77 = GA
     * 78 = GM
     * 79 = GE
     * 80 = DE
     * 81 = GH
     * 82 = GI
     * 83 = GR
     * 84 = GL
     * 85 = GD
     * 86 = GP
     * 87 = GU
     * 88 = GT
     * 89 = GN
     * 90 = GW
     * 91 = GY
     * 92 = HT
     * 93 = HM
     * 94 = HN
     * 95 = HK
     * 96 = HU
     * 97 = IS
     * 98 = IN
     * 99 = ID
     * 100 = IR
     * 101 = IQ
     * 102 = IE
     * 103 = IL
     * 104 = IT
     * 105 = JM
     * 106 = JP
     * 107 = JO
     * 108 = KZ
     * 109 = KE
     * 110 = KI
     * 111 = KP
     * 112 = KR
     * 113 = KW
     * 114 = KG
     * 115 = LA
     * 116 = LV
     * 117 = LB
     * 118 = LS
     * 119 = LR
     * 120 = LY
     * 121 = LI
     * 122 = LT
     * 123 = LU
     * 124 = MO
     * 125 = MK
     * 126 = MG
     * 127 = MW
     * 128 = MY
     * 129 = MV
     * 130 = ML
     * 131 = MT
     * 132 = MH
     * 133 = MQ
     * 134 = MR
     * 135 = MU
     * 136 = YT
     * 137 = MX
     * 138 = FM
     * 139 = MD
     * 140 = MC
     * 141 = MN
     * 142 = MS
     * 143 = MA
     * 144 = MZ
     * 145 = MM
     * 146 = NA
     * 147 = NR
     * 148 = NP
     * 149 = NL
     * 150 = AN
     * 151 = NC
     * 152 = NZ
     * 153 = NI
     * 154 = NE
     * 155 = NG
     * 156 = NU
     * 157 = NF
     * 158 = MP
     * 159 = NO
     * 160 = OM
     * 161 = PK
     * 162 = PW
     * 163 = PS
     * 164 = PA
     * 165 = PG
     * 166 = PY
     * 167 = PE
     * 168 = PH
     * 169 = PN
     * 170 = PL
     * 171 = PT
     * 172 = PR
     * 173 = QA
     * 174 = RE
     * 175 = RO
     * 176 = RU
     * 177 = RW
     * 178 = SH
     * 179 = KN
     * 180 = LC
     * 181 = PM
     * 182 = VC
     * 183 = WS
     * 184 = SM
     * 185 = ST
     * 186 = SA
     * 187 = SN
     * 188 = CS
     * 189 = SC
     * 190 = SL
     * 191 = SG
     * 192 = SK
     * 193 = SI
     * 194 = SB
     * 195 = SO
     * 196 = ZA
     * 197 = GS
     * 198 = ES
     * 199 = LK
     * 200 = SD
     * 201 = SR
     * 202 = SJ
     * 203 = SZ
     * 204 = SE
     * 205 = CH
     * 206 = SY
     * 207 = TW
     * 208 = TJ
     * 209 = TZ
     * 210 = TH
     * 211 = TL
     * 212 = TG
     * 213 = TK
     * 214 = TO
     * 215 = TT
     * 216 = TN
     * 217 = TR
     * 218 = TM
     * 219 = TC
     * 220 = TV
     * 221 = UG
     * 222 = UA
     * 223 = AE
     * 224 = GB
     * 225 = US
     * 226 = UM
     * 227 = UY
     * 228 = UZ
     * 229 = VU
     * 230 = VA
     * 231 = VE
     * 232 = VN
     * 233 = VG
     * 234 = VI
     * 235 = WF
     * 236 = EH
     * 237 = YE
     * 238 = ZM
     * 239 = ZW
     */
    country: CountryCode
}

export type UpdateGuarantorCommand = GuarantorBaseCommand & {
    /** @format int64 */
    id: number
}

export type CancelContractCommand = BaseActionCommand & object

export interface BaseActionCommand {
    contractNumber: string
}

export interface MatchInterestRequest {
    /**
     * 0 = Once
     * 1 = Monthly
     * 3 = ThreeMonthly
     * 4 = Quarterly
     * 5 = SixMonthly
     * 6 = Semiannual
     * 11 = TwelveMonthly
     * 12 = Annual
     */
    recurrence: Recurrence
    /** @format decimal */
    totalPrincipal: number
    /** @format decimal */
    totalRestValue: number
    /** @format decimal */
    periodicPayment: number
    /** @format int32 */
    leasingPeriod: number
    /**
     * 0 = EndOfPeriod
     * 1 = BeginningOfPeriod
     */
    paymentType: PaymentType
}

/**
 * 0 = EndOfPeriod
 * 1 = BeginningOfPeriod
 */
export enum PaymentType {
    EndOfPeriod = 0,
    BeginningOfPeriod = 1,
}

export interface CalculatedFieldsDTO {
    /** @format decimal */
    totalPrincipal: number
    /** @format decimal */
    totalPrincipalIncludingVat: number
    /** @format decimal */
    totalPurchase: number
    /** @format decimal */
    totalPurchaseIncludingVat: number
    /** @format decimal */
    totalRestValue: number
    /** @format decimal */
    totalRestValueIncludingVat: number
    /** @format decimal */
    totalPeriodicPayment: number
    /** @format decimal */
    totalPeriodicPaymentIncludingVat: number
    /** @format decimal */
    totalInterestEarning: number
    /** @format decimal */
    totalInterestEarningIncludingVat: number
    /** @format decimal */
    totalServicePaymentIncludingVat: number
    /** @format decimal */
    firstPayment: number
    /** @format decimal */
    firstPaymentIncludingVat: number
    /** @format decimal */
    totalRentalPrice?: number
    /** @format decimal */
    totalRentalPriceIncludingVat?: number
    /** @format decimal */
    totalServicePayment: number
    /** @format decimal */
    totalPeriodicPaymentWithAll: number
    /** @format decimal */
    totalPeriodicPaymentWithAllIncludingVat: number
    /** @format decimal */
    estimatedUsagePayment: number
    /** @format decimal */
    estimatedUsagePaymentIncludingVat: number
    /** @format date-time */
    startDate?: string
    /** @format decimal */
    saleTotal?: number
    partRegistrationInfo?: PartRegistrationCalculationDTO
    /** @format decimal */
    totalInsurancePayment: number
    /** @format decimal */
    totalInsurancePaymentIncludingVat: number
}

export interface PartRegistrationCalculationDTO {
    /** @format decimal */
    expectedValueLoss?: number
    /** @format decimal */
    restRegistration?: number
    /** @format decimal */
    interestOfExpectedValueLoss?: number
    /** @format decimal */
    total?: number
}

export interface CalculateKeyNumbersRequest {
    contract: ContractDTO
}

export interface ContractDTO {
    contractNumber?: string
    /**
     * 1 = PreTermination
     * 2 = Prolongation
     */
    parentContractRelationType?: ContractRelationType
    customerName?: string
    customerNumber?: string
    /** @format int64 */
    productId: number
    /** @format date-time */
    creationDate?: string
    /** @format decimal */
    totalFinancialCost?: number
    /** @format date-time */
    activationDate?: string
    isVariableInterest: boolean
    /** @format decimal */
    interest: number
    /** @format decimal */
    variableInterest: number
    isVariableInterestLocked: boolean
    /** @format int64 */
    variableInterestLimitId?: number
    /** @format decimal */
    creationFee?: number
    /** @format decimal */
    deposit?: number
    /**
     * 0 = EndOfPeriod
     * 1 = BeginningOfPeriod
     */
    paymentType?: PaymentType
    includeCreationFee: boolean
    insuredByName?: string
    thirdPartyCvr?: string
    vendorCvr?: string
    primaryUserIsDifferent: boolean
    placementIsDifferent: boolean
    deliveredAtIsDifferent: boolean
    /** @format int32 */
    leasingPeriod: number
    deliveredAtState?: string
    contactEmail?: string
    contactName?: string
    contactPhone?: string
    /** @format date-time */
    startActualDate?: string
    /** @format date-time */
    endActualDate?: string
    /** @format date-time */
    signedDate?: string
    /** @format date-time */
    firstInvoiceDueDate?: string
    externalReferenceString1?: string
    externalReferenceString2?: string
    externalReferenceString3?: string
    externalReferenceString4?: string
    externalReferenceText?: string
    /** @format decimal */
    externalReferenceMoney1?: number
    /** @format decimal */
    externalReferenceMoney2?: number
    /**
     * 0 = Offer
     * 1 = Active
     * 2 = Closed
     * 3 = Cancelled
     * 4 = ScheduledForClose
     * 5 = UnderClosing
     */
    status: ContractStatus
    canStatusProgress: boolean
    splitCustomer?: SplitCustomerDTO
    calculatedFieldsInfo?: CalculatedFieldsDTO
    contractAssets: ContractAssetDTO[]
    transientFees: TransientFeeDTO[]
    /** @format int64 */
    createdById: number
    createdByName?: string
    createdByBranch?: string
    /** @format int64 */
    lastUpdateById: number
    lastUpdateByName?: string
    /** @format date-time */
    lastUpdateDate: string
    lastUpdateByBranch?: string
    isZendeskTicketOpen: boolean
    /** @format decimal */
    vendorPaymentAmount: number
    /** @format decimal */
    vendorPaymentVatAmount: number
    /** @format decimal */
    vendorCreditAmount: number
    /** @format decimal */
    vendorCreditVatAmount: number
    canReopen: boolean
    isAdministrationFeeFree: boolean
    guarantors: GuarantorDTO[]
    contractSigners: ContractSignerDTO[]
    /** @format date-time */
    endDate?: string
    /** @format date-time */
    startDate: string
    isLocked: boolean
    productName?: string
    /**
     * 0 = AED
     * 1 = AFN
     * 2 = ALL
     * 3 = AMD
     * 4 = ANG
     * 5 = AOA
     * 6 = ARS
     * 7 = AUD
     * 8 = AWG
     * 9 = AZN
     * 10 = BAM
     * 11 = BBD
     * 12 = BDT
     * 13 = BGN
     * 14 = BHD
     * 15 = BIF
     * 16 = BMD
     * 17 = BND
     * 18 = BOB
     * 19 = BRL
     * 20 = BSD
     * 21 = BTN
     * 22 = BWP
     * 23 = BYN
     * 24 = BZD
     * 25 = CAD
     * 26 = CDF
     * 27 = CHF
     * 28 = CLP
     * 29 = CNY
     * 30 = COP
     * 31 = CRC
     * 32 = CUC
     * 33 = CUP
     * 34 = CVE
     * 35 = CZK
     * 36 = DJF
     * 37 = DKK
     * 38 = DOP
     * 39 = DZD
     * 40 = EGP
     * 41 = ERN
     * 42 = ETB
     * 43 = EUR
     * 44 = FJD
     * 45 = FKP
     * 46 = GBP
     * 47 = GEL
     * 48 = GGP
     * 49 = GHS
     * 50 = GIP
     * 51 = GMD
     * 52 = GNF
     * 53 = GTQ
     * 54 = GYD
     * 55 = HKD
     * 56 = HNL
     * 57 = HRK
     * 58 = HTG
     * 59 = HUF
     * 60 = IDR
     * 61 = ILS
     * 62 = IMP
     * 63 = INR
     * 64 = IQD
     * 65 = IRR
     * 66 = ISK
     * 67 = JEP
     * 68 = JMD
     * 69 = JOD
     * 70 = JPY
     * 71 = KES
     * 72 = KGS
     * 73 = KHR
     * 74 = KMF
     * 75 = KPW
     * 76 = KRW
     * 77 = KWD
     * 78 = KYD
     * 79 = KZT
     * 80 = LAK
     * 81 = LBP
     * 82 = LKR
     * 83 = LRD
     * 84 = LSL
     * 85 = LYD
     * 86 = MAD
     * 87 = MDL
     * 88 = MGA
     * 89 = MKD
     * 90 = MMK
     * 91 = MNT
     * 92 = MOP
     * 93 = MRO
     * 94 = MUR
     * 95 = MVR
     * 96 = MWK
     * 97 = MXN
     * 98 = MYR
     * 99 = MZN
     * 100 = NAD
     * 101 = NGN
     * 102 = NIO
     * 103 = NOK
     * 104 = NPR
     * 105 = NZD
     * 106 = OMR
     * 107 = PAB
     * 108 = PEN
     * 109 = PGK
     * 110 = PHP
     * 111 = PKR
     * 112 = PLN
     * 113 = PYG
     * 114 = QAR
     * 115 = RON
     * 116 = RSD
     * 117 = RUB
     * 118 = RWF
     * 119 = SAR
     * 120 = SBD
     * 121 = SCR
     * 122 = SDG
     * 123 = SEK
     * 124 = SGD
     * 125 = SHP
     * 126 = SLL
     * 127 = SOS
     * 128 = SPL
     * 129 = SRD
     * 130 = STD
     * 131 = SVC
     * 132 = SYP
     * 133 = SZL
     * 134 = THB
     * 135 = TJS
     * 136 = TMT
     * 137 = TND
     * 138 = TOP
     * 139 = TRY
     * 140 = TTD
     * 141 = TVD
     * 142 = TWD
     * 143 = TZS
     * 144 = UAH
     * 145 = UGX
     * 146 = USD
     * 147 = UYU
     * 148 = UZS
     * 149 = VEF
     * 150 = VND
     * 151 = VUV
     * 152 = WST
     * 153 = XAF
     * 154 = XCD
     * 155 = XDR
     * 156 = XOF
     * 157 = XPF
     * 158 = YER
     * 159 = ZAR
     * 160 = ZMW
     * 161 = ZWD
     */
    currencyCode?: CurrencyCode
    isShadow: boolean
    thirdPartyLocationNumber?: string
    vendorLocationNumber?: string
    primaryUserLocationNumber?: string
    placementCityLocationNumber?: string
    deliveredAtLocationNumber?: string
    prolongationOffer?: ProlongationOfferState
    parentContractNumber?: string
    /** 1 = HubSpot */
    crmSystem?: CrmSystem
    externalContractNumber?: string
    isBundle: boolean
}

/**
 * 1 = PreTermination
 * 2 = Prolongation
 */
export enum ContractRelationType {
    PreTermination = 1,
    Prolongation = 2,
}

export interface SplitCustomerDTO {
    /** @format int64 */
    customerId: number
    /** @format decimal */
    companyPart: number
}

export interface TransientFeeDTO {
    /** @format int64 */
    id: number
    contractNumber?: string
    baseTransientFeeNumber?: string
    /** @format decimal */
    value: number
    text?: string
    /** @format int64 */
    vatId: number
    /** @format decimal */
    vatPercentage: number
    isConsumed: boolean
    discount?: DiscountDTO
}

export interface ContractSignerDTO {
    /** @format int64 */
    id: number
    name?: string
    email?: string
    /**
     * 0 = Signer
     * 1 = Copy
     * 2 = Approver
     */
    receiver: SignatureReceiver
    /**
     * 0 = Digital
     * 1 = Touch
     */
    digitalType?: SignatureDigitalType
    /**
     * 0 = MitID
     * 1 = SwedishBankID
     * 2 = NorwegianBankID
     */
    electronicId?: ElectronicID
    /** @format int32 */
    signOrder?: number
}

export interface ProlongationOfferState {
    /** @format int64 */
    targetProductId: number
    /** @format int32 */
    leasingPeriod?: number
    prolongationContractAssets: ProlongationContractAssetState[]
    unmatchedContractAssets: UnmatchedProlongationAssets[]
}

export interface ProlongationContractAssetState {
    contractAssetNumber: string
    /** @format decimal */
    targetRestValue?: number
    /**
     * 0 = Virtual
     * 1 = Ready
     * 2 = Active
     * 3 = Closed
     * 4 = Cancelled
     * 5 = ScheduledForClose
     */
    contractAssetStatus?: ContractAssetStatus
}

export interface UnmatchedProlongationAssets {
    contractAssetNumber: string
    /**
     * 0 = MissingOnContract
     * 1 = MissingOnOffer
     */
    contractAssetMismatch: ContractAssetMismatch
}

/**
 * 0 = MissingOnContract
 * 1 = MissingOnOffer
 */
export enum ContractAssetMismatch {
    MissingOnContract = 0,
    MissingOnOffer = 1,
}

/** 1 = HubSpot */
export enum CrmSystem {
    HubSpot = 1,
}

export interface MatchRestValueRequest {
    /** @format int32 */
    leasingPeriod: number
    /**
     * 10 = Car
     * 20 = Van
     * 30 = Truck
     * 31 = Minibus
     * 32 = Bus
     * 33 = Largebus
     * 40 = ConstructionEquipment
     * 50 = FarmingEquipment
     * 60 = Tractors
     * 70 = HospitalEquipment
     * 80 = ManufacturingEquipment
     * 90 = HeavyTrailers
     * 100 = ItEquipment
     * 110 = Other
     * 999 = Virtual
     */
    assetType: AssetArcheType
    /** @format decimal */
    assetValue: number
}

export interface MatchCreationFeeRequest {
    /** @format decimal */
    purchaseValue: number
    /**
     * 10 = Car
     * 20 = Van
     * 30 = Truck
     * 31 = Minibus
     * 32 = Bus
     * 33 = Largebus
     * 40 = ConstructionEquipment
     * 50 = FarmingEquipment
     * 60 = Tractors
     * 70 = HospitalEquipment
     * 80 = ManufacturingEquipment
     * 90 = HeavyTrailers
     * 100 = ItEquipment
     * 110 = Other
     * 999 = Virtual
     */
    assetType: AssetArcheType
}

export interface ConfirmContractDebtorChangeOffer {
    contractNumber: string
}

export interface CloseContractCommand {
    contractNumber: string
}

export interface StartContractCommand {
    contractNumber: string
    /** @format date-time */
    startDate?: string
}

export interface CheckContractActionCommand {
    contractNumber: string
}

export interface ContractBundleDTO {
    /** @format int64 */
    id: number
    contractBundleName: string
    isActive: boolean
    contractBundleDetails: ContractDTO
}

export interface CreateContractBundle {
    bundleName: string
    contractNumber: string
}

export interface ScheduleContractEndDateCommand {
    contractNumber: string
}

export interface ResetScheduledContractEndDate {
    contractNumber: string
}

export interface CheckContractWarningsResponse {
    warnings: string[]
}

export interface CheckContractWarningsCommand {
    contractNumber: string
}

export interface BaseTransientFeeDTO {
    baseTransientFeeNumber: string
    description: string
    /** @format int64 */
    vatId: number
    erpProductId: string
    /** @format decimal */
    value: number
    productTransientFees: ProductTransientFeeDTO[]
}

export interface ProductTransientFeeDTO {
    /** @format int64 */
    productId: number
    isSelectable: boolean
}

export type CreateBaseTransientFeeCommand = BaseTransientFeeBaseCommand & object

export interface BaseTransientFeeBaseCommand {
    /** @format decimal */
    value: number
    description: string
    /** @format int64 */
    vatId: number
    erpProductId: string
    productTransientFees: ProductTransientFeeDTO[]
}

export type UpdateBaseTransientFeeCommand = BaseTransientFeeBaseCommand & {
    baseTransientFeeNumber: string
}

export interface SetContractAssetServicesCommand {
    contractAssetNumber: string
    assetServices: AssetServiceDTO[]
}

export interface AmortizingEntriesDTO {
    /** @format int32 */
    month: number
    /** @format decimal */
    balance: number
    /** @format decimal */
    periodicPayment: number
    calculated: boolean
}

export interface ContractBalanceDTO {
    contractNumber?: string
    /** @format int32 */
    period: number
    /** @format int32 */
    month: number
    /** @format date-time */
    periodStartDate: string
    /** @format date-time */
    periodEndDate: string
    /** @format decimal */
    initialBalance: number
    /** @format decimal */
    endBalance: number
    /** @format decimal */
    interestPayed: number
    /** @format decimal */
    depreciation: number
    isCiborLocked: boolean
    /** @format decimal */
    invoicedInterest: number
    /** @format decimal */
    periodicPayment: number
}

export interface AssetBalanceByContractReportRequest {
    contractNumber: string
    /** @format date-time */
    periodStartLow?: string
    /** @format date-time */
    periodStartHigh?: string
    /** @format date-time */
    periodEndLow?: string
    /** @format date-time */
    periodEndHigh?: string
}

export interface ContractBundleListDTO {
    /** @format int64 */
    id: number
    contractBundleName: string
}

export interface UpdateContractBundle {
    /** @format int64 */
    id: number
    isActive: boolean
    contractBundleDetails: UpdateContract
}

export interface UpdateContract {
    contractNumber: string
    customerName?: string
    customerNumber?: string
    /** @format int64 */
    productId: number
    /** @format date-time */
    creationDate?: string
    /** @format date-time */
    startDate: string
    /** @format int32 */
    leasingPeriod: number
    /** @format decimal */
    totalFinancialCost?: number
    /** @format date-time */
    activationDate?: string
    /** @format date-time */
    endDate?: string
    isVariableInterest: boolean
    /** @format decimal */
    interest: number
    /** @format decimal */
    variableInterest: number
    isVariableInterestLocked: boolean
    /** @format int64 */
    variableInterestLimitId?: number
    /** @format decimal */
    downpayment?: number
    /** @format decimal */
    creationFee?: number
    /** @format decimal */
    deposit?: number
    /**
     * 0 = EndOfPeriod
     * 1 = BeginningOfPeriod
     */
    paymentType?: PaymentType
    includeCreationFee: boolean
    insuredByName?: string
    thirdPartyCvr?: string
    thirdPartyLocationNumber?: string
    thirdPartyLocationCommand?: UpdateLocationCommand
    vendorCvr?: string
    vendorLocationNumber?: string
    vendorLocationCommand?: UpdateLocationCommand
    primaryUserIsDifferent: boolean
    primaryUserLocationNumber?: string
    primaryUserLocationCommand?: UpdateLocationCommand
    placementIsDifferent: boolean
    placementCityLocationNumber?: string
    placementCityLocationCommand?: UpdateLocationCommand
    deliveredAtIsDifferent: boolean
    deliveredAtLocationNumber?: string
    deliveredAtLocationCommand?: UpdateLocationCommand
    contactEmail?: string
    contactName?: string
    contactPhone?: string
    /** @format date-time */
    endActualDate?: string
    /** @format date-time */
    expectedDeliveryDate?: string
    /** @format date-time */
    signedDate?: string
    /** @format date-time */
    firstInvoiceDueDate?: string
    externalReferenceString1?: string
    externalReferenceString2?: string
    externalReferenceString3?: string
    externalReferenceString4?: string
    externalReferenceText?: string
    /** @format decimal */
    externalReferenceMoney1?: number
    /** @format decimal */
    externalReferenceMoney2?: number
    canStatusProgress: boolean
    splitCustomer?: SplitCustomerDTO
    documentMetadata?: DocumentMetaDataDto[]
    contractAssets: ContractAssetDTO[]
    /** @format int64 */
    createdById: number
    createdByName?: string
    createdByBranch?: string
    /** @format int64 */
    lastUpdateById: number
    lastUpdateByName?: string
    /** @format date-time */
    lastUpdateDate: string
    lastUpdateByBranch?: string
    isZendeskTicketOpen: boolean
    /** @format decimal */
    vendorPaymentAmount: number
    /** @format decimal */
    vendorPaymentVatAmount: number
    /** @format decimal */
    vendorCreditAmount: number
    /** @format decimal */
    vendorCreditVatAmount: number
    canReopen: boolean
    isAdministrationFeeFree: boolean
    isLocked: boolean
    /**
     * 0 = AED
     * 1 = AFN
     * 2 = ALL
     * 3 = AMD
     * 4 = ANG
     * 5 = AOA
     * 6 = ARS
     * 7 = AUD
     * 8 = AWG
     * 9 = AZN
     * 10 = BAM
     * 11 = BBD
     * 12 = BDT
     * 13 = BGN
     * 14 = BHD
     * 15 = BIF
     * 16 = BMD
     * 17 = BND
     * 18 = BOB
     * 19 = BRL
     * 20 = BSD
     * 21 = BTN
     * 22 = BWP
     * 23 = BYN
     * 24 = BZD
     * 25 = CAD
     * 26 = CDF
     * 27 = CHF
     * 28 = CLP
     * 29 = CNY
     * 30 = COP
     * 31 = CRC
     * 32 = CUC
     * 33 = CUP
     * 34 = CVE
     * 35 = CZK
     * 36 = DJF
     * 37 = DKK
     * 38 = DOP
     * 39 = DZD
     * 40 = EGP
     * 41 = ERN
     * 42 = ETB
     * 43 = EUR
     * 44 = FJD
     * 45 = FKP
     * 46 = GBP
     * 47 = GEL
     * 48 = GGP
     * 49 = GHS
     * 50 = GIP
     * 51 = GMD
     * 52 = GNF
     * 53 = GTQ
     * 54 = GYD
     * 55 = HKD
     * 56 = HNL
     * 57 = HRK
     * 58 = HTG
     * 59 = HUF
     * 60 = IDR
     * 61 = ILS
     * 62 = IMP
     * 63 = INR
     * 64 = IQD
     * 65 = IRR
     * 66 = ISK
     * 67 = JEP
     * 68 = JMD
     * 69 = JOD
     * 70 = JPY
     * 71 = KES
     * 72 = KGS
     * 73 = KHR
     * 74 = KMF
     * 75 = KPW
     * 76 = KRW
     * 77 = KWD
     * 78 = KYD
     * 79 = KZT
     * 80 = LAK
     * 81 = LBP
     * 82 = LKR
     * 83 = LRD
     * 84 = LSL
     * 85 = LYD
     * 86 = MAD
     * 87 = MDL
     * 88 = MGA
     * 89 = MKD
     * 90 = MMK
     * 91 = MNT
     * 92 = MOP
     * 93 = MRO
     * 94 = MUR
     * 95 = MVR
     * 96 = MWK
     * 97 = MXN
     * 98 = MYR
     * 99 = MZN
     * 100 = NAD
     * 101 = NGN
     * 102 = NIO
     * 103 = NOK
     * 104 = NPR
     * 105 = NZD
     * 106 = OMR
     * 107 = PAB
     * 108 = PEN
     * 109 = PGK
     * 110 = PHP
     * 111 = PKR
     * 112 = PLN
     * 113 = PYG
     * 114 = QAR
     * 115 = RON
     * 116 = RSD
     * 117 = RUB
     * 118 = RWF
     * 119 = SAR
     * 120 = SBD
     * 121 = SCR
     * 122 = SDG
     * 123 = SEK
     * 124 = SGD
     * 125 = SHP
     * 126 = SLL
     * 127 = SOS
     * 128 = SPL
     * 129 = SRD
     * 130 = STD
     * 131 = SVC
     * 132 = SYP
     * 133 = SZL
     * 134 = THB
     * 135 = TJS
     * 136 = TMT
     * 137 = TND
     * 138 = TOP
     * 139 = TRY
     * 140 = TTD
     * 141 = TVD
     * 142 = TWD
     * 143 = TZS
     * 144 = UAH
     * 145 = UGX
     * 146 = USD
     * 147 = UYU
     * 148 = UZS
     * 149 = VEF
     * 150 = VND
     * 151 = VUV
     * 152 = WST
     * 153 = XAF
     * 154 = XCD
     * 155 = XDR
     * 156 = XOF
     * 157 = XPF
     * 158 = YER
     * 159 = ZAR
     * 160 = ZMW
     * 161 = ZWD
     */
    currencyCode?: CurrencyCode
    guarantors: GuarantorDTO[]
    contractSigners: ContractSignerDTO[]
}

export interface GetContractResponse {
    contract: ContractDTO
    actionsAllowed: ActionsAllowed
}

export interface ActionsAllowed {
    canRegenerateFirstInvoice: boolean
    canStartManually: boolean
}

export interface PagedModelOfContractInfoSummaryDTO {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: ContractInfoSummaryDTO[]
}

export interface ContractInfoSummaryDTO {
    contractNumber?: string
    customerNumber?: string
    customerName?: string
    /** @format date-time */
    endDate?: string
    createdByName?: string
    createdByBranch?: string
    productName?: string
    /**
     * 0 = Offer
     * 1 = Active
     * 2 = Closed
     * 3 = Cancelled
     * 4 = ScheduledForClose
     * 5 = UnderClosing
     */
    status: ContractStatus
    /** @format int32 */
    contractAssetCount?: number
    baseAssetInfo: BaseAssetInfoDTO[]
}

export interface BaseAssetInfoDTO {
    baseAssetNumber?: string
    serialNumber?: string
    vin?: string
    registrationNumber?: string
}

export interface PagedModelOfContractInfoDTO {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: ContractInfoDTO[]
}

export interface ContractInfoDTO {
    contractNumber?: string
    customerNumber?: string
    customerName?: string
    customerCvr?: string
    customerCpr?: string
    /**
     * 0 = Company
     * 1 = Private
     */
    customerType?: CustomerType
    /** @format decimal */
    totalRestValue: number
    /** @format date-time */
    creationDate: string
    externalReferenceString1?: string
    externalReferenceString2?: string
    externalReferenceString3?: string
    externalReferenceString4?: string
    createdByName?: string
    createdByBranch?: string
    lastUpdateByName?: string
    lastUpdateByBranch?: string
    /** @format date-time */
    lastUpdateDate: string
    advisorName?: string
    advisorBranch?: string
    /**
     * 0 = Offer
     * 1 = Active
     * 2 = Closed
     * 3 = Cancelled
     * 4 = ScheduledForClose
     * 5 = UnderClosing
     */
    status: ContractStatus
    isLocked: boolean
    /** @format date-time */
    endDate?: string
    /** @format date-time */
    endDateActual?: string
    /** @format date-time */
    startDate?: string
    /** @format date-time */
    startDateActual?: string
    /** @format int32 */
    serviceCount: number
    productName?: string
    productDescription?: string
    /** @format int32 */
    assetsDelivered?: number
    /** @format int32 */
    totalAssets?: number
    /** @format decimal */
    totalPeriodicPayment: number
    /** @format decimal */
    totalPeriodicPaymentServiceIncluded: number
    assets: ContractAssetInfoDTO[]
}

export interface ContractAssetInfoDTO {
    assetType: AssetArcheType
    assetTypeData?: AssetTypeDTO
    assetMake?: string
    assetModel?: string
    assetRegistrationNumber?: string
    assetIdentifier?: string
}

export interface CalculatedDiscountValuesDTO {
    /**
     * 1 = AssetPrice
     * 2 = AssetService
     * 3 = TransientFee
     */
    discountType: DiscountType
    description?: string
    /** @format decimal */
    originalValue: number
    /** @format decimal */
    discountRate?: number
    /** @format decimal */
    discountedValue?: number
    /** @format int32 */
    discountPeriod?: number
}

/**
 * 1 = AssetPrice
 * 2 = AssetService
 * 3 = TransientFee
 */
export enum DiscountType {
    AssetPrice = 1,
    AssetService = 2,
    TransientFee = 3,
}

export interface CreateContractCommand {
    parentContractNumber?: string
    /**
     * 1 = PreTermination
     * 2 = Prolongation
     */
    parentContractRelationType?: ContractRelationType
    customerNumber?: string
    customerName?: string
    /** @format int64 */
    productId: number
    /** @format date-time */
    creationDate?: string
    /** @format date-time */
    startDate: string
    /** @format int32 */
    leasingPeriod: number
    /** @format decimal */
    totalFinancialCost?: number
    /** @format date-time */
    activationDate?: string
    /** @format date-time */
    endDate?: string
    isVariableInterest: boolean
    /** @format decimal */
    interest: number
    /** @format decimal */
    variableInterest: number
    isVariableInterestLocked: boolean
    /** @format int64 */
    variableInterestLimitId?: number
    /** @format decimal */
    downpayment?: number
    /** @format decimal */
    creationFee?: number
    /** @format decimal */
    deposit?: number
    /**
     * 0 = EndOfPeriod
     * 1 = BeginningOfPeriod
     */
    paymentType?: PaymentType
    includeCreationFee: boolean
    insuredByName?: string
    thirdPartyCvr?: string
    thirdPartyLocationNumber?: string
    thirdPartyLocationCommand?: CreateLocationCommand
    vendorCvr?: string
    vendorLocationNumber?: string
    vendorLocationCommand?: CreateLocationCommand
    primaryUserLocationNumber?: string
    primaryUserLocationCommand?: CreateLocationCommand
    primaryUserIsDifferent: boolean
    placementCityLocationNumber?: string
    placementCityLocationCommand?: CreateLocationCommand
    placementIsDifferent: boolean
    deliveredAtLocationNumber?: string
    deliveredAtLocationCommand?: CreateLocationCommand
    deliveredAtIsDifferent: boolean
    contactEmail?: string
    contactName?: string
    contactPhone?: string
    /** @format date-time */
    endActualDate?: string
    /** @format date-time */
    expectedDeliveryDate?: string
    /** @format date-time */
    signedDate?: string
    /** @format date-time */
    firstInvoiceDueDate?: string
    externalReferenceString1?: string
    externalReferenceString2?: string
    externalReferenceString3?: string
    externalReferenceString4?: string
    externalReferenceText?: string
    /** @format decimal */
    externalReferenceMoney1?: number
    /** @format decimal */
    externalReferenceMoney2?: number
    canStatusProgress: boolean
    splitCustomer?: SplitCustomerDTO
    documentMetadata?: DocumentMetaDataDto[]
    contractAssets: ContractAssetDTO[]
    isAdministrationFeeFree: boolean
    /** @format int64 */
    createdById: number
    createdByName?: string
    createdByBranch?: string
    /** @format int64 */
    lastUpdateById: number
    lastUpdateByName?: string
    /** @format date-time */
    lastUpdateDate: string
    lastUpdateByBranch?: string
    isZendeskTicketOpen: boolean
    /** @format decimal */
    vendorPaymentAmount: number
    /** @format decimal */
    vendorPaymentVatAmount: number
    isLocked: boolean
    /**
     * 0 = AED
     * 1 = AFN
     * 2 = ALL
     * 3 = AMD
     * 4 = ANG
     * 5 = AOA
     * 6 = ARS
     * 7 = AUD
     * 8 = AWG
     * 9 = AZN
     * 10 = BAM
     * 11 = BBD
     * 12 = BDT
     * 13 = BGN
     * 14 = BHD
     * 15 = BIF
     * 16 = BMD
     * 17 = BND
     * 18 = BOB
     * 19 = BRL
     * 20 = BSD
     * 21 = BTN
     * 22 = BWP
     * 23 = BYN
     * 24 = BZD
     * 25 = CAD
     * 26 = CDF
     * 27 = CHF
     * 28 = CLP
     * 29 = CNY
     * 30 = COP
     * 31 = CRC
     * 32 = CUC
     * 33 = CUP
     * 34 = CVE
     * 35 = CZK
     * 36 = DJF
     * 37 = DKK
     * 38 = DOP
     * 39 = DZD
     * 40 = EGP
     * 41 = ERN
     * 42 = ETB
     * 43 = EUR
     * 44 = FJD
     * 45 = FKP
     * 46 = GBP
     * 47 = GEL
     * 48 = GGP
     * 49 = GHS
     * 50 = GIP
     * 51 = GMD
     * 52 = GNF
     * 53 = GTQ
     * 54 = GYD
     * 55 = HKD
     * 56 = HNL
     * 57 = HRK
     * 58 = HTG
     * 59 = HUF
     * 60 = IDR
     * 61 = ILS
     * 62 = IMP
     * 63 = INR
     * 64 = IQD
     * 65 = IRR
     * 66 = ISK
     * 67 = JEP
     * 68 = JMD
     * 69 = JOD
     * 70 = JPY
     * 71 = KES
     * 72 = KGS
     * 73 = KHR
     * 74 = KMF
     * 75 = KPW
     * 76 = KRW
     * 77 = KWD
     * 78 = KYD
     * 79 = KZT
     * 80 = LAK
     * 81 = LBP
     * 82 = LKR
     * 83 = LRD
     * 84 = LSL
     * 85 = LYD
     * 86 = MAD
     * 87 = MDL
     * 88 = MGA
     * 89 = MKD
     * 90 = MMK
     * 91 = MNT
     * 92 = MOP
     * 93 = MRO
     * 94 = MUR
     * 95 = MVR
     * 96 = MWK
     * 97 = MXN
     * 98 = MYR
     * 99 = MZN
     * 100 = NAD
     * 101 = NGN
     * 102 = NIO
     * 103 = NOK
     * 104 = NPR
     * 105 = NZD
     * 106 = OMR
     * 107 = PAB
     * 108 = PEN
     * 109 = PGK
     * 110 = PHP
     * 111 = PKR
     * 112 = PLN
     * 113 = PYG
     * 114 = QAR
     * 115 = RON
     * 116 = RSD
     * 117 = RUB
     * 118 = RWF
     * 119 = SAR
     * 120 = SBD
     * 121 = SCR
     * 122 = SDG
     * 123 = SEK
     * 124 = SGD
     * 125 = SHP
     * 126 = SLL
     * 127 = SOS
     * 128 = SPL
     * 129 = SRD
     * 130 = STD
     * 131 = SVC
     * 132 = SYP
     * 133 = SZL
     * 134 = THB
     * 135 = TJS
     * 136 = TMT
     * 137 = TND
     * 138 = TOP
     * 139 = TRY
     * 140 = TTD
     * 141 = TVD
     * 142 = TWD
     * 143 = TZS
     * 144 = UAH
     * 145 = UGX
     * 146 = USD
     * 147 = UYU
     * 148 = UZS
     * 149 = VEF
     * 150 = VND
     * 151 = VUV
     * 152 = WST
     * 153 = XAF
     * 154 = XCD
     * 155 = XDR
     * 156 = XOF
     * 157 = XPF
     * 158 = YER
     * 159 = ZAR
     * 160 = ZMW
     * 161 = ZWD
     */
    currencyCode?: CurrencyCode
    isBundle: boolean
    /** 1 = HubSpot */
    crmSystem?: CrmSystem
    externalContractNumber?: string
}

export interface EstimatedEndDatesResponse {
    estimatedEndDates: EstimatedContractAssetEndDatesResponse[]
}

export interface EstimatedContractAssetEndDatesResponse {
    contractAssetNumber: string
    availableFutureEndDates: string[]
}

export interface CreateContractWithRulesAppliedCommand {
    customerNumber?: string
    /** @format int64 */
    productId: number
    /** @format date-time */
    creationDate?: string
    /** @format date-time */
    startDate: string
    /** @format date-time */
    endDate?: string
    isVariableInterestLocked: boolean
    canStatusProgress: boolean
}

export interface ContractDebtorChangeOfferDTO {
    /** @format int64 */
    contractDebtorChangeId: number
    customerNumber?: string
    /** @format decimal */
    fee: number
    externalReferenceString1?: string
    externalReferenceString2?: string
    externalReferenceString3?: string
    externalReferenceString4?: string
}

export type CreateContractDebtorChangeOffer = BaseContractDebtorChangeOfferCommand & object

export interface BaseContractDebtorChangeOfferCommand {
    contractNumber: string
    customerNumber: string
    /** @format decimal */
    fee: number
    externalReferenceString1?: string
    externalReferenceString2?: string
    externalReferenceString3?: string
    externalReferenceString4?: string
}

export type UpdateContractDebtorChangeOfferCommand = BaseContractDebtorChangeOfferCommand & object

export interface DeleteContractDebtorChangeOfferCommand {
    contractNumber: string
}

export interface GenerateContractFinanceReportCommand {
    outstandingContracts: string[]
}

export interface SendContractFinanceReportCommand {
    /** @format int64 */
    documentId: number
}

export type CreateProlongationOfferCommand = ProlongationOfferCommandBase & object

export interface ProlongationOfferCommandBase {
    contractNumber: string
    /** @format int64 */
    targetProductId: number
    /** @format int32 */
    leasingPeriod?: number
    prolongationContractAssets: ProlongationContractAssetCommand[]
}

export interface ProlongationContractAssetCommand {
    contractAssetNumber: string
    /** @format decimal */
    targetRestValue?: number
}

export type UpdateProlongationOfferCommand = ProlongationOfferCommandBase & object

export interface ProlongationNumbersDTO {
    /** @format decimal */
    totalPeriodicPaymentWithAll: number
}

export interface ProlongContractCommand {
    contractNumber: string
    /** @format int64 */
    productId: number
}

export interface PagedModelOfContractTracesDTO {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: ContractTracesDTO[]
}

export interface ContractTracesDTO {
    contractNumber?: string
    /**
     * 0 = Creation
     * 1 = Cancel
     * 2 = Start
     * 3 = Close
     * 4 = Preterminate
     * 5 = ChangeDebtor
     * 6 = AddSigner
     * 7 = SetAllLocations
     * 8 = SetPrimaryUserLocation
     * 9 = SetThirdPartyLocation
     * 10 = SetDeliveredAtLocation
     * 11 = SetPlacementLocation
     * 12 = SetVendorLocation
     * 13 = UpdateDetails
     * 14 = SetIsAdministrationFeeFree
     * 15 = AddGuarantor
     * 16 = AddTransientFee
     * 17 = AddContractAsset
     * 18 = RemoveGuarantors
     * 19 = SetProlongationOffer
     * 20 = TryScheduleForClose
     * 21 = TryResetScheduledForCloseStateAndEndDate
     * 22 = SetStatusUnderClosing
     * 23 = UndoUnderClosingStatus
     */
    type: ContractTraceType
    /** @format date-time */
    date: string
    userNumber?: string
    userName?: string
}

/**
 * 0 = Creation
 * 1 = Cancel
 * 2 = Start
 * 3 = Close
 * 4 = Preterminate
 * 5 = ChangeDebtor
 * 6 = AddSigner
 * 7 = SetAllLocations
 * 8 = SetPrimaryUserLocation
 * 9 = SetThirdPartyLocation
 * 10 = SetDeliveredAtLocation
 * 11 = SetPlacementLocation
 * 12 = SetVendorLocation
 * 13 = UpdateDetails
 * 14 = SetIsAdministrationFeeFree
 * 15 = AddGuarantor
 * 16 = AddTransientFee
 * 17 = AddContractAsset
 * 18 = RemoveGuarantors
 * 19 = SetProlongationOffer
 * 20 = TryScheduleForClose
 * 21 = TryResetScheduledForCloseStateAndEndDate
 * 22 = SetStatusUnderClosing
 * 23 = UndoUnderClosingStatus
 */
export enum ContractTraceType {
    Creation = 0,
    Cancel = 1,
    Start = 2,
    Close = 3,
    Preterminate = 4,
    ChangeDebtor = 5,
    AddSigner = 6,
    SetAllLocations = 7,
    SetPrimaryUserLocation = 8,
    SetThirdPartyLocation = 9,
    SetDeliveredAtLocation = 10,
    SetPlacementLocation = 11,
    SetVendorLocation = 12,
    UpdateDetails = 13,
    SetIsAdministrationFeeFree = 14,
    AddGuarantor = 15,
    AddTransientFee = 16,
    AddContractAsset = 17,
    RemoveGuarantors = 18,
    SetProlongationOffer = 19,
    TryScheduleForClose = 20,
    TryResetScheduledForCloseStateAndEndDate = 21,
    SetStatusUnderClosing = 22,
    UndoUnderClosingStatus = 23,
}

export interface ContractTimelineDTO {
    contractNumber: string
    /** @format date-time */
    expectedStartDate?: string
    /** @format date-time */
    expectedEndDate?: string
    /** @format date-time */
    actualStartDate?: string
    /** @format date-time */
    actualEndDate?: string
    parentContractNumber: string
    /**
     * 1 = PreTermination
     * 2 = Prolongation
     */
    parentContractRelationType?: ContractRelationType
    /** @format date-time */
    parentContractExpectedStartDate?: string
    /** @format date-time */
    parentContractExpectedEndDate?: string
    /** @format date-time */
    parentContractActualStartDate?: string
    /** @format date-time */
    parentContractActualEndDate?: string
    childContractNumber: string
    /**
     * 1 = PreTermination
     * 2 = Prolongation
     */
    childContractRelationType?: ContractRelationType
    /** @format date-time */
    childContractExpectedStartDate?: string
    /** @format date-time */
    childContractExpectedEndDate?: string
    /** @format date-time */
    childContractActualStartDate?: string
    /** @format date-time */
    childContractActualEndDate?: string
}

export type CreateTransientFeeCommand = TransientFeeBaseCommand & {
    baseTransientFeeNumber?: string
}

export interface TransientFeeBaseCommand {
    contractNumber: string
    /** @format decimal */
    value: number
    text?: string
    /** @format int64 */
    vatId: number
    discount?: DiscountDTO
}

export type UpdateTransientFeeCommand = TransientFeeBaseCommand & {
    /** @format int64 */
    id: number
}

export interface ChecklistDTO {
    /** @format int64 */
    id: number
    /** @format int64 */
    productId: number
    /**
     * 1 = ContractActivation
     * 2 = ContractPreTermination
     * 3 = ContractAssetActivation
     */
    domainOperationType: DomainOperationType
    description?: string
    isActive: boolean
    checklistItems: ChecklistItemDTO[]
}

/**
 * 1 = ContractActivation
 * 2 = ContractPreTermination
 * 3 = ContractAssetActivation
 */
export enum DomainOperationType {
    ContractActivation = 1,
    ContractPreTermination = 2,
    ContractAssetActivation = 3,
}

export interface ChecklistItemDTO {
    /** @format int64 */
    id: number
    /**
     * 1 = ContractDocumentCreated
     * 2 = CustomerCreditReportAvailable
     * 3 = CustomerAddressProvided
     * 4 = HumanInteractionStatus
     */
    itemType: ChecklistItemType
    /** @format int64 */
    checklistHumanInteractionTypeId?: number
    /** @format int32 */
    order: number
    isOptional: boolean
    isActive: boolean
}

/**
 * 1 = ContractDocumentCreated
 * 2 = CustomerCreditReportAvailable
 * 3 = CustomerAddressProvided
 * 4 = HumanInteractionStatus
 */
export enum ChecklistItemType {
    ContractDocumentCreated = 1,
    CustomerCreditReportAvailable = 2,
    CustomerAddressProvided = 3,
    HumanInteractionStatus = 4,
}

export interface CreateChecklistCommand {
    /** @format int64 */
    productId: number
    /**
     * 1 = ContractActivation
     * 2 = ContractPreTermination
     * 3 = ContractAssetActivation
     */
    domainOperationType: DomainOperationType
    description?: string
    isActive: boolean
    checklistItems: ChecklistItemCommand[]
}

export interface ChecklistItemCommand {
    /** @format int64 */
    id: number
    /**
     * 1 = ContractDocumentCreated
     * 2 = CustomerCreditReportAvailable
     * 3 = CustomerAddressProvided
     * 4 = HumanInteractionStatus
     */
    itemType: ChecklistItemType
    /** @format int64 */
    checklistHumanInteractionTypeId?: number
    /** @format int32 */
    order: number
    isOptional: boolean
    isActive: boolean
}

export interface UpdateChecklistCommand {
    /** @format int64 */
    id: number
    /** @format int64 */
    productId: number
    /**
     * 1 = ContractActivation
     * 2 = ContractPreTermination
     * 3 = ContractAssetActivation
     */
    domainOperationType: DomainOperationType
    description?: string
    checklistItems: ChecklistItemCommand[]
    isActive: boolean
}

export interface AvailableChecklistItemTypesDTO {
    checklistItemTypes: ChecklistItemType[]
}

export interface ChecklistSpecificStateDTO {
    /** @format int64 */
    checklistId: number
    checklistDescription?: string
    /**
     * 0 = Incomplete
     * 1 = Complete
     * 2 = DoesNotExist
     */
    status: ChecklistStatus
    checklistItemStatuses: ChecklistItemSpecificStateDTO[]
}

/**
 * 0 = Incomplete
 * 1 = Complete
 * 2 = DoesNotExist
 */
export enum ChecklistStatus {
    Incomplete = 0,
    Complete = 1,
    DoesNotExist = 2,
}

export interface ChecklistItemSpecificStateDTO {
    /** @format int64 */
    checklistItemId: number
    /**
     * 1 = ContractDocumentCreated
     * 2 = CustomerCreditReportAvailable
     * 3 = CustomerAddressProvided
     * 4 = HumanInteractionStatus
     */
    itemType: ChecklistItemType
    /** @format int64 */
    humanInteractionTypeId?: number
    humanInteractionTypeName?: string
    /** @format int64 */
    humanInteractionRecordId?: number
    note?: string
    isCompleted: boolean
    isOptional: boolean
}

export interface ChecklistHumanInteractionRecordDTO {
    /** @format int64 */
    id: number
    /** @format int64 */
    checklistItemId: number
    userNote: string
    isCompleted: boolean
}

export interface ChecklistHumanInteractionRecordRequest {
    userNote: string
    isCompleted: boolean
}

export type ChecklistHumanInteractionTypeBO = EntityBOOfChecklistHumanInteractionType & {
    /** @format int64 */
    id: number
    name: string
    description?: string
    isActive: boolean
}

export type EntityBOOfChecklistHumanInteractionType = object

export interface CreateChecklistHumanInteractionTypeCommand {
    name: string
    description?: string
    isActive: boolean
}

export interface UpdateChecklistHumanInteractionTypeCommand {
    /** @format int64 */
    id: number
    name: string
    description?: string
    isActive: boolean
}

export interface GetVatDetail {
    /** @format int64 */
    id: number
    name?: string
    /** @format decimal */
    percentage: number
    /** @format int64 */
    bookAccountId?: number
    canBeDeleted: boolean
    canUpdated: boolean
}

export type CreateVatCommand = VatBaseCommand & object

export interface VatBaseCommand {
    name?: string
    /** @format decimal */
    percentage: number
    /** @format int64 */
    bookAccountId?: number
}

export type UpdateVatCommand = VatBaseCommand & {
    /** @format int64 */
    id: number
}

export interface AssetBalanceDTO {
    contractNumber?: string
    assetNumber?: string
    /** @format decimal */
    initialBalance: number
    /** @format int32 */
    period: number
    /** @format int32 */
    month: number
    /** @format decimal */
    endBalance: number
    /** @format date-time */
    periodStartDate: string
    /** @format date-time */
    periodEndDate: string
    /** @format decimal */
    periodicPayment: number
    /** @format decimal */
    variableReferenceInterestUsed: number
    /** @format decimal */
    depreciation: number
    /** @format decimal */
    interestPayed: number
    isCiborLocked: boolean
}

export interface AssetBalanceReportRequest {
    assetNumber?: string
    /** @format date-time */
    periodStartLow?: string
    /** @format date-time */
    periodStartHigh?: string
    /** @format date-time */
    periodEndLow?: string
    /** @format date-time */
    periodEndHigh?: string
}

export interface PagedModelOfAssetBalanceDTO {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: AssetBalanceDTO[]
}

export interface GetAssetDetail {
    baseAssetNumber: string
    assetNumber: string
    assetTypeData: AssetTypeDTO
    /**
     * 0 = Draft
     * 1 = Ready
     * 2 = Closed
     * 3 = InUse
     */
    assetStatus: AssetStatus
    exteriorColor?: string
    /** @format date-time */
    firstRegistrationDateTime?: string
    /** @format date-time */
    expectedDeliveryDate?: string
    /**
     * 0 = Gasoline
     * 1 = Diesel
     * 2 = Electric
     * 3 = HybridGasoline
     * 4 = HybridDiesel
     * 5 = HydrogenFuelCell
     */
    fuelType?: FuelType
    gtin?: string
    interiorColor?: string
    make: string
    model?: string
    modelYear?: string
    /** @format decimal */
    originalPurchaseValue?: number
    power?: string
    productNumber?: string
    registrationNumber?: string
    serialNumber?: string
    variant?: string
    vin?: string
    addons?: string
    canBeDeleted: boolean
    canChangeAssetType: boolean
    canUpdateStatus: boolean
    depreciations: AssetDepreciationDetail[]
    /** @format date-time */
    dateTimeActivation?: string
    /** @format date-time */
    contractEndDate?: string
    /** @format decimal */
    restValue: number
    /** @format decimal */
    periodicPayment?: number
    /** @format decimal */
    downpayment?: number
    /** @format decimal */
    purchaseValue: number
    contractNumber?: string
    /**
     * 0 = Virtual
     * 1 = Ready
     * 2 = Active
     * 3 = Closed
     * 4 = Cancelled
     * 5 = ScheduledForClose
     */
    status: ContractAssetStatus
    /**
     * 0 = Offer
     * 1 = Active
     * 2 = Closed
     * 3 = Cancelled
     * 4 = ScheduledForClose
     * 5 = UnderClosing
     */
    contractStatus: ContractStatus
    /**
     * 0 = Hourly
     * 1 = Daily
     * 2 = Count
     * 3 = Distance
     */
    usageType?: UsageType
    /** @format int64 */
    deliveryDocumentId?: number
    /** @format int32 */
    lockInPeriod?: number
    /** @format int32 */
    cancellationPeriod?: number
    location?: LocationDTO
}

/**
 * 0 = Draft
 * 1 = Ready
 * 2 = Closed
 * 3 = InUse
 */
export enum AssetStatus {
    Draft = 0,
    Ready = 1,
    Closed = 2,
    InUse = 3,
}

export interface AssetDepreciationDetail {
    /** @format int32 */
    period: number
    /** @format date-time */
    dateTime: string
    /** @format decimal */
    depreciationValue: number
    /** @format decimal */
    currentAssetValue: number
    /** @format decimal */
    currentAssetFiscalValue: number
}

export interface GetBaseAssetDetail {
    baseAssetNumber?: string
    /**
     * 0 = Draft
     * 1 = Ready
     * 2 = Closed
     * 3 = InUse
     */
    assetStatus: AssetStatus
    exteriorColor?: string
    /** @format date-time */
    firstRegistrationDateTime?: string
    /**
     * 0 = Gasoline
     * 1 = Diesel
     * 2 = Electric
     * 3 = HybridGasoline
     * 4 = HybridDiesel
     * 5 = HydrogenFuelCell
     */
    fuelType?: FuelType
    gtin?: string
    interiorColor?: string
    make?: string
    model?: string
    modelYear?: string
    /** @format decimal */
    originalPurchaseValue?: number
    power?: string
    productNumber?: string
    registrationNumber?: string
    serialNumber?: string
    variant?: string
    vin?: string
    canBeDeleted: boolean
    canChangeAssetType: boolean
    canUpdateStatus: boolean
    location?: LocationDTO
    assetType?: AssetTypeDTO
    contractAssets: GetAssetDetail[]
    /**
     * 0 = Hourly
     * 1 = Daily
     * 2 = Count
     * 3 = Distance
     */
    usageType?: UsageType
    /** @format decimal */
    usageLimit?: number
    /** @format decimal */
    pricePerUnitOverUsageLimit?: number
    /** @format decimal */
    pricePerUnitUnderUsageLimit?: number
    /**
     * 0 = RoundUpToTheMinimum
     * 1 = PreciseCalculation
     */
    usagePriceCalculationMethod?: ContractAssetUsagePriceCalculationMethod
}

export interface PagedModelOfGetBaseAssetDetail {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: GetBaseAssetDetail[]
}

export interface PagedModelOfGetAssetList {
    /** @format int32 */
    pageSize: number
    /** @format int32 */
    currentPage: number
    /** @format int32 */
    totalItems: number
    /** @format int32 */
    totalPages: number
    previous?: string
    next?: string
    message?: string
    items: GetAssetList[]
}

export interface GetAssetList {
    baseAssetNumber?: string
    assetNumber?: string
    /**
     * 0 = Draft
     * 1 = Ready
     * 2 = Closed
     * 3 = InUse
     */
    assetStatus: AssetStatus
    exteriorColor?: string
    /** @format date-time */
    firstRegistrationDateTime?: string
    /** @format date-time */
    expectedDeliveryDateTime?: string
    /**
     * 0 = Gasoline
     * 1 = Diesel
     * 2 = Electric
     * 3 = HybridGasoline
     * 4 = HybridDiesel
     * 5 = HydrogenFuelCell
     */
    fuelType?: FuelType
    gtin?: string
    interiorColor?: string
    make?: string
    model?: string
    modelYear?: string
    /** @format decimal */
    originalPurchaseValue?: number
    power?: string
    productNumber?: string
    registrationNumber?: string
    serialNumber?: string
    variant?: string
    vin?: string
    addons?: string
    canBeDeleted: boolean
    canChangeAssetType: boolean
    canUpdateStatus: boolean
    /** @format date-time */
    dateTimeActivation?: string
    /** @format date-time */
    dateTimeEnd?: string
    /** @format decimal */
    restValue: number
    /** @format decimal */
    downpayment?: number
    /** @format decimal */
    purchaseValue: number
    contractNumber?: string
    /**
     * 0 = Offer
     * 1 = Active
     * 2 = Closed
     * 3 = Cancelled
     * 4 = ScheduledForClose
     * 5 = UnderClosing
     */
    contractStatus?: ContractStatus
    /** @format date-time */
    contractEndDate?: string
    location: LocationDTO
    assetTypeData: AssetTypeDTO
}

export interface ActivateAssetRequest {
    assetsToActivate: string[]
    /** @format date-time */
    activationDate?: string
}

export interface SimulateAssetActionCommand {
    assetsToActivate: string[]
}

export type CreateBaseAssetCommand = AssetBaseCommand & object

export interface AssetBaseCommand {
    make?: string
    model?: string
    registrationNumber?: string
    vin?: string
    serialNumber?: string
    /** @format decimal */
    originalPurchaseValue?: number
    /** @format int64 */
    assetTypeId: number
    /** @format date-time */
    firstRegistrationDateTime?: string
    power?: string
    exteriorColor?: string
    interiorColor?: string
    modelYear?: string
    variant?: string
    productNumber?: string
    /**
     * 0 = Gasoline
     * 1 = Diesel
     * 2 = Electric
     * 3 = HybridGasoline
     * 4 = HybridDiesel
     * 5 = HydrogenFuelCell
     */
    fuelType?: FuelType
    gtin?: string
    locationNumber?: string
    /**
     * 0 = Hourly
     * 1 = Daily
     * 2 = Count
     * 3 = Distance
     */
    usageType?: UsageType
    /** @format decimal */
    usageLimit?: number
    /** @format decimal */
    pricePerUnitOverUsageLimit?: number
    /** @format decimal */
    pricePerUnitUnderUsageLimit?: number
    /**
     * 0 = RoundUpToTheMinimum
     * 1 = PreciseCalculation
     */
    usagePriceCalculationMethod?: ContractAssetUsagePriceCalculationMethod
}

export type UpdateBaseAssetCommand = AssetBaseCommand & {
    baseAssetNumber: string
}

export type CopyBaseAssetCommand = CopyAssetBaseCommand & {
    baseAssetNumber: string
}

export interface CopyAssetBaseCommand {
    registrationNumber?: string
    vin?: string
    serialNumber?: string
    /** @format date-time */
    firstRegistrationDateTime?: string
    gtin?: string
}

export type CopyContractAssetCommand = CopyAssetBaseCommand & {
    contractAssetNumber: string
    copyServices: boolean
}

export interface ScheduleContractAssetEndDate {
    contractAssetNumber: string
    /** @format date-time */
    endDate?: string
}

export interface ResetScheduledContractAssetEndDate {
    contractAssetNumber: string
}

export interface AssetPreterminateOfferResultDTO {
    /** @format int64 */
    preterminateOfferId: number
    /** @format int64 */
    customerId?: number
    /** @format decimal */
    currentBalance: number
    /** @format decimal */
    remainingInterestEarnings: number
    /** @format decimal */
    suggestedPreterminatePrice: number
    /** @format decimal */
    fee: number
    /** @format decimal */
    agreedRestValue?: number
    customerBoughtAsset: boolean
    /** @format date-time */
    expirationDate: string
    /** @format int64 */
    documentId: number
    /**
     * 0 = Active
     * 1 = Completed
     * 2 = Cancelled
     */
    status: AssetPreterminateOfferStatus
    preterminateNumbers: PreterminateNumbersDTO[]
}

/**
 * 0 = Active
 * 1 = Completed
 * 2 = Cancelled
 */
export enum AssetPreterminateOfferStatus {
    Active = 0,
    Completed = 1,
    Cancelled = 2,
}

export interface PreterminateNumbersDTO {
    assetNumber?: string
    baseAssetNumber?: string
    /** @format decimal */
    currentBalance: number
    /** @format decimal */
    remainingInterestEarnings: number
    /** @format decimal */
    suggestedPreterminatePrice: number
    /** @format date-time */
    expirationDate: string
    vin?: string
    registrationNumber?: string
    serialNumber?: string
}

export interface CreateContractPreterminateOffer {
    contractNumber: string
    /** @format int64 */
    productId: number
    customerNumber: string
    customerBoughtAsset: boolean
    contractAssets: ContractAssetPreterminateOffer[]
}

export interface ContractAssetPreterminateOffer {
    assetNumber: string
    /** @format decimal */
    agreedPrice: number
}

export interface UpdateContractPreterminateOfferCommand {
    contractNumber: string
    contractAssets: ContractAssetPreterminateOffer[]
}

export interface AssetPreterminateContractBalanceDTO {
    contractNumber?: string
    /** @format int32 */
    period: number
    /** @format int32 */
    month: number
    /** @format date-time */
    periodStartDate: string
    /** @format date-time */
    periodEndDate: string
    /** @format decimal */
    initialBalance: number
    /** @format decimal */
    endBalance: number
    /** @format decimal */
    remainingPayments: number
    /** @format decimal */
    restValue: number
    /** @format decimal */
    suggestedPretermination: number
    /** @format decimal */
    interestPayed: number
    /** @format decimal */
    depreciation: number
    isCiborLocked: boolean
    /** @format decimal */
    invoicedInterest: number
    assetBalance: AssetPreterminateAssetBalanceDTO[]
}

export interface AssetPreterminateAssetBalanceDTO {
    assetNumber?: string
    /** @format decimal */
    initialBalance: number
    /** @format decimal */
    endBalance: number
    /** @format decimal */
    remainingPayments: number
    /** @format decimal */
    restValue: number
    /** @format decimal */
    suggestedPretermination: number
    /** @format decimal */
    periodicPayment: number
    /** @format decimal */
    variableReferenceInterestUsed: number
    /** @format decimal */
    depreciation: number
    /** @format decimal */
    interestPayed: number
    isCiborLocked: boolean
}

export interface CreateAssetTypeCommand {
    name: string
    /**
     * 10 = Car
     * 20 = Van
     * 30 = Truck
     * 31 = Minibus
     * 32 = Bus
     * 33 = Largebus
     * 40 = ConstructionEquipment
     * 50 = FarmingEquipment
     * 60 = Tractors
     * 70 = HospitalEquipment
     * 80 = ManufacturingEquipment
     * 90 = HeavyTrailers
     * 100 = ItEquipment
     * 110 = Other
     * 999 = Virtual
     */
    assetArcheType: AssetArcheType
}

export interface UpdateAssetTypeCommand {
    /** @format int64 */
    id: number
    name: string
    isActive: boolean
}

export interface AssetTypeBO {
    /** @format int64 */
    id: number
    name?: string
    /**
     * 10 = Car
     * 20 = Van
     * 30 = Truck
     * 31 = Minibus
     * 32 = Bus
     * 33 = Largebus
     * 40 = ConstructionEquipment
     * 50 = FarmingEquipment
     * 60 = Tractors
     * 70 = HospitalEquipment
     * 80 = ManufacturingEquipment
     * 90 = HeavyTrailers
     * 100 = ItEquipment
     * 110 = Other
     * 999 = Virtual
     */
    assetArcheType: AssetArcheType
    isActive: boolean
    isMotor: boolean
}
