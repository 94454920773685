import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { DownloadIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    IconButton,
    Tooltip,
    useBoolean,
    useToast,
} from '@chakra-ui/react'

import ToolbarSkeleton from '../../../../components/skeleton/toolbar/toolbar.skeleton.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../services/contexts/NumberFormatter.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    ColumnActionConfiguration,
    downloadFile,
} from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { CustomerDTO } from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'

export default function CustomerDetailsCreditSafe(): ReactElement {
    const [customer, setCustomer] = useState<CustomerDTO>()
    const [isCustomerLoading, setCustomerLoading] = useBoolean()
    const [creditSafe, setCreditSafe] = useState<any>()
    const [isCreditSafeLoading, setCreditSafeLoading] = useBoolean()
    const [, setCreditSafeHistory] = useState<Array<any>>([])

    const [creditSafeConnectLoading, setCreditSafeConnectLoading] =
        useState<boolean>(false)
    const routerParams = useParams<{ customerNumber?: string }>()
    const { customerNumber } = routerParams
    const translate = useTranslation().t
    const toast = useToast()
    const [customerCreditSafe, setCustomerCreditSafe] = useState()
    const { formatValue } = useNumberFormatterContext()
    const handleCreditSafeConnect = async (): Promise<void> => {
        if (!customer?.cvr) {
            toast(baseErrorToastOptions(translate('cVRNotFound')))
            return
        }

        if (creditSafeConnectLoading) return
        const { cvr } = customer
        setCreditSafeConnectLoading(true)
        const request = await generalGetAPI(
            `${API_ENDPOINTS.creditSafe}?Query=${cvr}`
        )
        setCreditSafeConnectLoading(false)
        if (!request.isOk) {
            toast(baseErrorToastOptions(request.message))
            return
        }

        const { data } = request
        if (!data[0]?.connectId) {
            toast(baseErrorToastOptions(translate('creditSafeNotFound')))
            return
        }

        const creditSafeConnectId = data[0].connectId
        const updatedCustomer = { ...customer, creditSafeConnectId }
        const customerUpdateRequest = await generalPutAPI(
            API_ENDPOINTS.customer,
            { ...updatedCustomer }
        )
        if (!customerUpdateRequest.isOk) {
            toast(baseErrorToastOptions(customerUpdateRequest.message))
            return
        }

        setCustomer(updatedCustomer)
        toast(baseSuccessToastOptions(translate('creditSafeConnected')))
    }

    const loadCustomer = async (): Promise<void> => {
        setCustomerLoading.on()
        const response = await generalGetAPI(
            `${API_ENDPOINTS.customer}/${customerNumber}`
        )
        if (response.isOk) {
            setCustomer(response.data)
        }
        if (!response.isOk) toast(baseErrorToastOptions(response.message))
        setCustomerLoading.off()
    }

    const loadCreditSafe = async (): Promise<void> => {
        setCreditSafeLoading.on()
        const response = await generalPostAPI(`${API_ENDPOINTS.creditSafe}`, {
            customerNumber,
        })
        setCreditSafeLoading.off()

        if (response.isOk) {
            setCreditSafe(response.data)
        }
        if (!response.isOk) toast(baseErrorToastOptions(response.message))
    }

    const loadCreditSafeHistory = async (): Promise<void> => {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.creditSafe}/${customerNumber}`
        )
        if (response.isOk) {
            setCreditSafeHistory(response.data)
        }
        if (!response.isOk) toast(baseErrorToastOptions(response.message))
    }

    useEffect((): void => {
        loadCustomer()
        generalGetAPI(`${API_ENDPOINTS.creditSafe}/${customerNumber}`).then(
            (result) => {
                if (result.isOk) setCustomerCreditSafe(result.data)
            }
        )
    }, [])

    useEffect((): void => {
        if (!isCreditSafeLoading) {
            loadCreditSafeHistory()
        }
    }, [creditSafe, isCreditSafeLoading])

    const columns = [
        { headerName: translate('companyStatus'), field: 'companyStatus' },
        { headerName: translate('assessment'), field: 'assessment' },
        {
            headerName: translate('commonRatingValue'),
            field: 'commonRatingValue',
        },
        {
            headerName: translate('creditLimit'),
            field: 'creditLimit',
            valueFormatter: (params: any) => formatValue(params, 'creditLimit'),
        },
        {
            headerName: translate('queryDate'),
            field: 'queryDate',
            valueGetter: (params: any) => formatDate(params.data.queryDate),
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(
                translate,
                (params: any) => (
                    <Tooltip
                        label={translate('download')}
                        placement="top"
                        hasArrow
                    >
                        <IconButton
                            aria-label="download"
                            size="sm"
                            onClick={(): void => {
                                downloadFile(
                                    params.data.documentId,
                                    `Credit-Safe_${customerNumber}_${params.data.queryDate}`,
                                    'pdf'
                                )
                            }}
                            icon={<DownloadIcon />}
                        />
                    </Tooltip>
                ),
                100
            ),
        },
    ]

    return (
        <>
            {isCustomerLoading ? (
                <ToolbarSkeleton />
            ) : (
                <Flex mb={6} justifyContent="flex-end" gap={4}>
                    {!customer?.creditSafeConnectId && (
                        <Button
                            onClick={handleCreditSafeConnect}
                            isLoading={creditSafeConnectLoading}
                        >
                            {translate('creditSafeConnect')}
                        </Button>
                    )}
                    <Button
                        onClick={(): void => {
                            loadCreditSafe()
                        }}
                        disabled={customer && !customer.creditSafeConnectId}
                    >
                        {translate('getCreditScore')}
                    </Button>
                </Flex>
            )}

            <DynamicGrid
                tableId="customerDetailsCreditSafeTable"
                columns={columns}
                rowData={customerCreditSafe}
                pagination={true}
                rowMultiSelectWithClick={true}
            />
        </>
    )
}
