import find from 'lodash/find'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import { IFormField } from '../../../features/dynamicForm/DynamicForm.interfaces'
import { ColumnActionConfiguration } from '../../../utils/functions.utils'
import {
    CreateBaseTransientFeeCommand,
    GetVatDetail,
    ProductDTO,
    UpdateBaseTransientFeeCommand,
} from '../../../utils/types/types'

interface ERPProductInterface {
    name: string
    productNumber: string | number
}

export const getDefaultTransientFee = (products: ProductDTO[]) => ({
    value: 0,
    description: '',
    vatId: 0,
    erpProductId: '',
    productTransientFees:
        products?.map((product) => ({
            productId: product.id,
            isSelectable: false,
        })) ?? [],
})

export function AddTransientFeeFormFields(
    ERPProducts: ERPProductInterface[],
    products: ProductDTO[],
    VAT: GetVatDetail[]
): IFormField<CreateBaseTransientFeeCommand>[] {
    return [
        {
            slug: 'value',
            label: 'value',
            inputProps: { type: 'number', isRequired: true },
        },
        {
            slug: 'description',
            label: 'description',
            inputProps: { type: 'text', isRequired: true },
        },
        {
            slug: 'vatId',
            label: 'vatId',
            inputProps: { type: 'select', isRequired: true },
            optionsKeysValues: [...VAT.map((elem) => [elem.id, elem?.name])],
        },
        {
            slug: 'erpProductId',
            label: 'mapping',
            inputProps: {
                type: ERPProducts?.length ? 'select' : 'text',
            },
            isValueString: true,
            skipTranslation: true,
            optionsKeysValues: ERPProducts?.length
                ? [
                      ...ERPProducts.map((product) => [
                          product.productNumber,
                          product?.name,
                      ]),
                  ]
                : [],
        },
    ]
}

export function EditTransientFeeFormFields(
    transientFee: UpdateBaseTransientFeeCommand,
    ERPProducts: ERPProductInterface[],
    VAT: GetVatDetail[]
): IFormField<CreateBaseTransientFeeCommand>[] {
    return [
        {
            slug: 'value',
            label: 'value',
            inputProps: { type: 'number', isRequired: true },
            value: transientFee?.value,
        },
        {
            slug: 'description',
            label: 'description',
            inputProps: { type: 'text', isRequired: true },
        },
        {
            slug: 'vatId',
            label: 'vatId',
            inputProps: { type: 'select', isRequired: true },
            optionsKeysValues: [...VAT.map((elem) => [elem.id, elem?.name])],
        },
        {
            slug: 'erpProductId',
            label: 'mapping',
            inputProps: {
                type: ERPProducts?.length ? 'select' : 'text',
            },
            isValueString: true,
            skipTranslation: true,
            optionsKeysValues: ERPProducts?.length
                ? [
                      ...ERPProducts.map((product) => [
                          product.productNumber,
                          product?.name,
                      ]),
                  ]
                : [],
        },
    ]
}

export const columnsConfig = (
    translate: Function,
    onEdit: Function,
    onDelete: Function,
    selectionEnabled: boolean,
    hasActions: boolean,
    VAT: GetVatDetail[],
    formatValue: Function
) => [
    {
        field: 'baseTransientFeeNumber',
        headerName: translate('baseTransientFeeNumber'),
        filter: 'agTextColumnFilter',
        editable: false,
        headerCheckboxSelection: selectionEnabled,
        checkboxSelection: selectionEnabled,
    },
    {
        headerName: translate('description'),
        field: 'description',
        filter: 'agTextColumnFilter',
    },
    {
        field: 'vat',
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) =>
            `${find(VAT, { id: params.data.vatId })?.percentage}%` || '',
        headerName: translate('vat'),
    },
    {
        field: 'value',
        headerName: translate('value'),
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => formatValue(params, 'value'),
        flex: 1,
    },
    ...(hasActions
        ? [
              {
                  ...ColumnActionConfiguration(
                      translate,
                      (params: any) => (
                          <>
                              <Tooltip
                                  label={translate('edit')}
                                  placement="top"
                                  hasArrow
                              >
                                  <IconButton
                                      mr={2}
                                      aria-label="Edit"
                                      size="sm"
                                      onClick={(): void => onEdit(params.data)}
                                      icon={<EditIcon />}
                                  />
                              </Tooltip>

                              <Tooltip
                                  label={translate('delete')}
                                  placement="top"
                                  hasArrow
                              >
                                  <IconButton
                                      aria-label="delete"
                                      size="sm"
                                      onClick={(): void =>
                                          onDelete(params.data)
                                      }
                                      icon={<DeleteIcon />}
                                  />
                              </Tooltip>
                          </>
                      ),
                      100
                  ),
              },
          ]
        : []),
]
