import { camelCase } from 'lodash'
import { IFormField } from '../../../../features/dynamicForm/DynamicForm.interfaces'
import { DiscountDTO, DiscountType } from '../../../../utils/types/types'

export const columnsConfig = (
    translate: Function,
    editable: boolean,
    hasDiscount: boolean
) => [
    {
        field: 'discountType',
        filter: 'agTextColumnFilter',
        headerName: translate('discountType'),
        valueGetter: (params: any) =>
            translate(camelCase(DiscountType[params?.data?.discountType])),
    },
    {
        field: 'description',
        filter: 'agTextColumnFilter',
        headerName: translate('description'),
    },
    ...(hasDiscount
        ? [
              {
                  field: 'discountRate',
                  filter: 'agNumberColumnFilter',
                  headerName: translate('discountRate'),
                  editable,
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                      min: 0,
                      max: 100,
                  },
              },
              {
                  field: 'discountPeriod',
                  filter: 'agNumberColumnFilter',
                  headerName: translate('discountPeriod'),
                  editable,
              },
          ]
        : []),
    {
        field: 'originalValue',
        filter: 'agNumberColumnFilter',
        headerName: translate('price'),
    },
    ...(hasDiscount
        ? [
              {
                  field: 'discountedValue',
                  filter: 'agNumberColumnFilter',
                  headerName: translate('discountedValue'),
              },
          ]
        : []),
]

export const addDiscountFormFields = (): IFormField<Partial<DiscountDTO>>[] => [
    {
        slug: 'value',
        label: 'value',
        enableDecimal: false,
        inputProps: { type: 'number', min: 0 },
        validation: (value: number) =>
            !Number.isNaN(value) && value > 0 && value !== null,
    },
    {
        slug: 'period',
        label: 'period',
        enableDecimal: false,
        inputProps: { type: 'number', min: 0 },
        validation: (value: number) =>
            !Number.isNaN(value) && value > 0 && value !== null,
    },
]
