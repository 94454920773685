import camelCase from 'lodash/camelCase'
/* eslint-disable no-unused-vars */
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditIcon } from '@chakra-ui/icons'
import { Button, IconButton, Tooltip } from '@chakra-ui/react'

import PageContainer from '../../../components/pageContainer/PageContainer.component'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../services/API/general.api'
import {
    ParameterBaseCommand,
    ParameterDTO,
    PartnerParameterDefinitionDTO,
    ProductDTO,
} from '../../../utils/types/types'
import DynamicGrid from '../../demo/DynamicAGGrid.component'
import AddProductParameter from './components/add/AddProductParameter'
import EditProductParameter from './components/edit/EditProductParameter'
import { columnsConfig } from './SetupGeneral.config'

export type ParameterAndDefinition = ParameterDTO &
    PartnerParameterDefinitionDTO

export default function SetupGeneral(): ReactElement {
    const translate = useTranslation().t
    const gridApiRef = useRef(null)

    const [definitions, setDefinitions] =
        useState<PartnerParameterDefinitionDTO[]>()
    const [parameters, setParameters] = useState<ParameterDTO[]>()
    const [parametersAndDefinitions, setParametersAndDefinitions] =
        useState<ParameterAndDefinition[]>()
    const [products, setProducts] = useState<ProductDTO[]>()
    const [selectedParameterAndDefinition, setSelectedParameterAndDefinition] =
        useState<ParameterAndDefinition>()
    const [openModal, setOpenModal] = useState(false)
    const [addModal, setAddModal] = useState(false)

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    async function getParametersDefinitionsAndProductParameter(): Promise<void> {
        const responseDefinitions = await generalGetAPI(
            `${API_ENDPOINTS.parameterDefinitions}`
        )
        const responseParameters = await generalGetAPI(
            `${API_ENDPOINTS.parameter}`
        )

        if (responseDefinitions.isOk && responseParameters.isOk) {
            setDefinitions(responseDefinitions.data)
            setParameters(responseParameters.data)
        }
    }

    function buildParametersAndDefinitions(): void {
        const tempParameterAndDefinitions: ParameterAndDefinition[] = []
        definitions &&
            parameters &&
            parameters?.forEach((parameter) => {
                const definition = definitions.find(
                    (item) => item.key === parameter.key
                ) as PartnerParameterDefinitionDTO
                tempParameterAndDefinitions.push({
                    ...parameter,
                    ...definition,
                })
            })
        setParametersAndDefinitions(tempParameterAndDefinitions)
    }

    async function getProducts(): Promise<void> {
        const response = await generalGetAPI(`${API_ENDPOINTS.product}`)
        if (response.isOk) {
            setProducts(response.data)
        }
    }

    function buildTitleForModal(): string {
        const hasProduct = !!selectedParameterAndDefinition?.productId
        if (hasProduct) {
            const product = products?.find(
                (item) => item.id === selectedParameterAndDefinition.productId
            )
            return `${translate('editParameter')} - ${camelCase(
                selectedParameterAndDefinition.key
            )} - ${translate('for')} - ${product?.name}`
        }
        return `${translate('editParameter')} - ${camelCase(
            selectedParameterAndDefinition?.key
        )}`
    }

    async function editProductCallback(data: ParameterAndDefinition) {
        const hasProductId = data.productId !== null
        const {
            productId,
            key,
            value,
            value1,
            value2,
            value3,
            value4,
            value5,
        } = data

        await generalPostAPI(
            `${API_ENDPOINTS.parameter}${
                hasProductId ? `?productId=${productId}` : ''
            }`,
            {
                key,
                value,
                ...(value1 ? { value1 } : {}),
                ...(value2 ? { value2 } : {}),
                ...(value3 ? { value3 } : {}),
                ...(value4 ? { value4 } : {}),
                ...(value5 ? { value5 } : {}),
            } as ParameterBaseCommand
        )
        const parameterIndex = parameters?.findIndex(
            (item) => item.productId === data.productId && data.key === item.key
        )
        const localParameters = [...(parameters as ParameterDTO[])]

        localParameters[parameterIndex as number] = {
            ...(data as ParameterDTO),
        }

        setParameters(localParameters)
    }

    useEffect(() => {
        getParametersDefinitionsAndProductParameter()
        getProducts()
    }, [])

    useEffect(() => {
        if (definitions && parameters) buildParametersAndDefinitions()
    }, [JSON.stringify(parameters), JSON.stringify(definitions)])

    return (
        <PageContainer>
            <DynamicGrid
                tableId="setupGeneralTable"
                onGridReady={onGridReady}
                columns={columnsConfig(
                    translate,
                    (params: any): any => (
                        <Tooltip
                            label={translate('edit')}
                            placement="top"
                            hasArrow
                        >
                            <IconButton
                                mr={2}
                                aria-label="Edit"
                                size="sm"
                                onClick={(): any => {
                                    setSelectedParameterAndDefinition(
                                        params?.data
                                    )
                                    setOpenModal(true)
                                }}
                                icon={<EditIcon />}
                            />
                        </Tooltip>
                    ),
                    products
                )}
                rowData={parametersAndDefinitions}
                pagination={true}
                rowMultiSelectWithClick={true}
                headers={
                    <>
                        <Button onClick={(): void => setAddModal(true)}>
                            {translate('add')}
                        </Button>
                    </>
                }
            ></DynamicGrid>

            {addModal && definitions && products && parameters && (
                <AddProductParameter
                    definitions={definitions}
                    products={products}
                    parameters={parameters}
                    isCentered={true}
                    isModalOpen={addModal}
                    maxWidth="500px"
                    modalTitle={translate('addParameter')}
                    onClose={(): void => {
                        setAddModal(false)
                    }}
                    onSubmitCallback={async (
                        _data: ParameterAndDefinition
                    ): Promise<void> =>
                        getParametersDefinitionsAndProductParameter()
                    }
                ></AddProductParameter>
            )}

            {selectedParameterAndDefinition && (
                <EditProductParameter
                    parameter={selectedParameterAndDefinition}
                    isCentered={true}
                    isModalOpen={openModal}
                    maxWidth="500px"
                    modalTitle={buildTitleForModal()}
                    onClose={(): void => {
                        setOpenModal(false)
                    }}
                    onSubmitCallback={async (
                        data: ParameterAndDefinition
                    ): Promise<void> => editProductCallback(data)}
                ></EditProductParameter>
            )}
        </PageContainer>
    )
}
