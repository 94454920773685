import camelCase from 'lodash/camelCase'

import { CheckIcon, DeleteIcon, DownloadIcon, EditIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import {
    buildListOfKeyValues,
    ColumnActionConfiguration,
    findValueInArray,
    getSpecificKeyFromObject,
} from '../../../utils/functions.utils'
import {
    DocumentMetaDataDto,
    DocumentType,
    UpdateDocumentCommand,
    UploadedDocumentStatus,
} from '../../../utils/types/types'
import { IFormField } from '../../dynamicForm/DynamicForm.interfaces'

enum PenneoDocumentStatus {
    New,
    Pending,
    Rejected,
    Deleted,
    Signed,
    Completed,
}

export const PenneoDocumentStatusArray = [
    {
        key: PenneoDocumentStatus.New,
        value: 'New',
    },
    {
        key: PenneoDocumentStatus.Pending,
        value: 'Pending',
    },
    {
        key: PenneoDocumentStatus.Rejected,
        value: 'Rejected',
    },
    {
        key: PenneoDocumentStatus.Deleted,
        value: 'Deleted',
    },
    {
        key: PenneoDocumentStatus.Signed,
        value: 'Signed',
    },
    {
        key: PenneoDocumentStatus.Completed,
        value: 'Completed',
    },
]

export default function DocumentFormFields(
    document: DocumentMetaDataDto
): IFormField<Partial<DocumentMetaDataDto>>[] {
    return [
        {
            slug: 'name',
            label: 'name',
            inputProps: {
                type: 'text',
                isRequired: true,
                defaultValue: document.name,
            },
            validation: (value: string) => value?.length > 0,
            errorMessage: 'Invalid Name',
        },
        {
            slug: 'documentType',
            label: 'documentType',
            inputProps: { type: 'select', isRequired: true },
            optionsKeysValues:
                buildListOfKeyValues<typeof DocumentType>(DocumentType),
            errorMessage: 'Please select a Value',
        },
        {
            slug: 'documentStatus',
            label: 'documentStatus',
            inputProps: { type: 'select', isRequired: true },
            optionsKeysValues: buildListOfKeyValues<
                typeof UploadedDocumentStatus
            >(UploadedDocumentStatus),
            errorMessage: 'Please select a Value',
        },
    ]
}

export function UploadDocumentFormFields(): IFormField<
    Partial<UpdateDocumentCommand>
>[] {
    return [
        {
            slug: 'documentType',
            label: 'documentType',
            inputProps: {
                type: 'select',
                isRequired: true,
                default: DocumentType.UserUploaded,
            },
            optionsKeysValues:
                buildListOfKeyValues<typeof DocumentType>(DocumentType),
            errorMessage: 'Please select a Value',
        },
        {
            slug: 'documentStatus',
            label: 'documentStatus',
            inputProps: { type: 'select', isRequired: true },
            optionsKeysValues: buildListOfKeyValues<
                typeof UploadedDocumentStatus
            >(UploadedDocumentStatus),
            errorMessage: 'Please select a Value',
        },
    ]
}

export const columnsConfig = (
    translate: Function,
    onEdit: Function,
    onDelete: Function,
    onDownload: Function,
    isSignersEnabled: boolean,
    signersHandler: Function
) => [
    {
        field: 'name',
        headerName: translate('name'),
        filter: 'agTextColumnFilter',
        resizable: false,
        editable: false,
        suppressMovable: true,
    },
    {
        field: 'contractNumber',
        headerName: translate('contractNumber'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'documentType',
        filter: 'agTextColumnFilter',
        headerName: translate('documentType'),
        valueGetter: (params: any) =>
            translate(camelCase(DocumentType[params?.data?.documentType ?? 0])),
    },
    {
        field: 'signature',
        filter: 'agTextColumnFilter',
        headerName: translate('signature'),
        valueGetter: (params: any) =>
            translate(
                camelCase(
                    params?.data?.isUserUploaded
                        ? getSpecificKeyFromObject<
                              typeof UploadedDocumentStatus
                          >(
                              UploadedDocumentStatus,
                              params?.data?.documentStatus || ''
                          ) || ''
                        : findValueInArray(
                              PenneoDocumentStatusArray,
                              params?.data?.documentStatus,
                              'key',
                              'value'
                          )
                )
            ),
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <>
                    <Tooltip label={translate('edit')} placement="top" hasArrow>
                        <IconButton
                            mr={2}
                            aria-label="Edit"
                            size="sm"
                            onClick={() => onEdit(params?.data)}
                            icon={<EditIcon />}
                        />
                    </Tooltip>

                    <Tooltip
                        label={translate('delete')}
                        placement="top"
                        hasArrow
                    >
                        <IconButton
                            mr={2}
                            aria-label="delete"
                            size="sm"
                            onClick={() => onDelete(params?.data)}
                            icon={<DeleteIcon />}
                        />
                    </Tooltip>

                    <Tooltip
                        label={translate('download')}
                        placement="top"
                        hasArrow
                    >
                        <IconButton
                            aria-label="download"
                            size="sm"
                            onClick={() => onDownload(params?.data)}
                            icon={<DownloadIcon />}
                        />
                    </Tooltip>
                    {isSignersEnabled && (
                        <Tooltip
                            label={translate('signers')}
                            placement="top"
                            hasArrow
                        >
                            <IconButton
                                ml={2}
                                aria-label="sign"
                                size="sm"
                                onClick={() => signersHandler(params?.data)}
                                icon={<CheckIcon />}
                            />
                        </Tooltip>
                    )}
                </>
            ),
            200
        ),
    },
]
