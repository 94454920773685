import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    IconButton,
    Tooltip,
    useBoolean,
    useToast,
} from '@chakra-ui/react'

import TableSkeleton from '../../../../components/skeleton/table/table.skeleton.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalGetAPI,
    generalPostAPI,
} from '../../../../services/API/general.api'
import { useLoading } from '../../../../services/contexts/Loading.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../../utils/functions.utils'
import { SignerBaseDto, SignerDto } from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import {
    getCustomerSignersColumns,
    getCustomerSignersRows,
} from '../../CustomerDetails.config'
import AddSignerComponent from './AddSigner.component'

export default function CustomerDetailsSigners(): ReactElement {
    const translate = useTranslation().t
    // eslint-disable-next-line no-unused-vars
    const [signers, setSigners] = useState([])
    const [editSignerData, setEditSignerData] = useState<SignerBaseDto | null>()
    const [localLoading, setLocalLoading] = useState(false)
    const { globalLoading, stopGlobalLoading } = useLoading()
    const params = useParams<{ customerNumber?: string }>()
    const { customerNumber } = params

    const [isAddSignerOpen, setAddSigner] = useBoolean()
    const toast = useToast()

    const loadSigners = async (): Promise<void> => {
        setLocalLoading(true)

        const response = await generalGetAPI(
            `${API_ENDPOINTS.signers}/${customerNumber}/customer`
        )
        setLocalLoading(false)

        if (response.isOk) setSigners(response.data)
    }

    useEffect((): void => {
        loadSigners()
    }, [])

    const handleSaveSigner = async (signer: SignerBaseDto): Promise<void> => {
        const loadingID = globalLoading()
        const response = await generalPostAPI(
            `${API_ENDPOINTS.signers}/${customerNumber}/customer`,
            signer
        )
        if (response.isOk) {
            loadSigners()
            toast(baseSuccessToastOptions(translate('signerCreated')))
        } else {
            toast(baseErrorToastOptions(response.message))
        }
        stopGlobalLoading(loadingID)
    }

    const handleDeleteSigner = async (signer: SignerDto): Promise<void> => {
        const loadingID = globalLoading()
        const response = await generalDeleteAPI(
            `${API_ENDPOINTS.signers}/${signer.id}`,
            signer
        )
        if (response.isOk) {
            loadSigners()
            toast(baseSuccessToastOptions(translate('signerDeleted')))
        } else {
            toast(baseErrorToastOptions(response.message))
        }
        stopGlobalLoading(loadingID)
    }

    return (
        <div data-testid="asset-report-page">
            {localLoading ? (
                <TableSkeleton />
            ) : (
                <>
                    <Flex
                        alignItems={'center'}
                        justifyContent="flex-end"
                        mb={4}
                    >
                        <Button
                            onClick={() => {
                                setEditSignerData(null)
                                setAddSigner.on()
                            }}
                        >
                            {translate('addRow')}
                        </Button>
                    </Flex>
                    <DynamicGrid
                        tableId="customerDetailsSignersTable"
                        columns={getCustomerSignersColumns(
                            translate,
                            (tparams: any): any => (
                                <>
                                    <Tooltip
                                        label={translate('edit')}
                                        placement="top"
                                        hasArrow
                                    >
                                        <IconButton
                                            data-testid="delete"
                                            mr={2}
                                            aria-label="Edit"
                                            size="sm"
                                            onClick={(): any => {
                                                setEditSignerData(
                                                    tparams?.data?.item
                                                )
                                                setAddSigner.on()
                                            }}
                                            icon={<EditIcon />}
                                        />
                                    </Tooltip>

                                    <Tooltip
                                        label={translate('delete')}
                                        placement="top"
                                        hasArrow
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            size="sm"
                                            onClick={(): any => {
                                                handleDeleteSigner(
                                                    tparams?.data?.item
                                                )
                                            }}
                                            icon={<DeleteIcon />}
                                        />
                                    </Tooltip>
                                </>
                            )
                        )}
                        rowData={getCustomerSignersRows(signers)}
                        pagination={true}
                        rowMultiSelectWithClick={false}
                    />
                    <AddSignerComponent
                        editSignerData={editSignerData}
                        isCentered={true}
                        isModalOpen={isAddSignerOpen}
                        onClose={() => setAddSigner.off()}
                        modalTitle={translate('addSigner')}
                        handleSaveSigner={(data: SignerDto): void => {
                            handleSaveSigner(data)
                            setEditSignerData(null)
                            setAddSigner.off()
                        }}
                        maxWidth={'620px'}
                    />
                </>
            )}
        </div>
    )
}
