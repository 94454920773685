import camelCase from 'lodash/camelCase'
import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { EditIcon } from '@chakra-ui/icons'
import {
    IconButton,
    Input,
    Switch,
    Text,
    Tooltip,
    useToast,
} from '@chakra-ui/react'

import PageContainer from '../../../components/pageContainer/PageContainer.component'
import DynamicForm from '../../../features/dynamicForm/DynamicForm.feature'
import PopupActionButton, {
    ButtonVariant,
} from '../../../features/popupActionButton/PopupActionButton.feature'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../services/API/general.api'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    buildListOfKeyValues,
    ColumnActionConfiguration,
} from '../../../utils/functions.utils'
import {
    AssetArcheType,
    AssetTypeBO,
    AssetTypeDTO,
} from '../../../utils/types/types'
import DynamicGrid from '../../demo/DynamicAGGrid.component'

export default function AssetTypesPage(): ReactElement {
    const translate = useTranslation().t
    const [assetTypes, setAssetTypes] = useState<AssetTypeBO[]>()
    const toast = useToast()

    const [assetTypeToEdit, setAssetTypeToEdit] = useState<
        AssetTypeBO | undefined
    >(undefined)
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const buttonReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const fetchAssetTypes = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.assetTypes)
        if (response.isOk) {
            setAssetTypes(response.data)
        }
    }

    useEffect(() => {
        fetchAssetTypes()
    }, [])

    function createAssetType(formData: AssetTypeDTO) {
        generalPostAPI(API_ENDPOINTS.assetTypes, formData).then(() => {
            toast(
                baseSuccessToastOptions(
                    translate('assetTypeCreatedSuccessfully')
                )
            )
            fetchAssetTypes()
        })
    }

    function onPopupConfirm(): void {
        if (formSubmitReference) {
            formSubmitReference.current?.click()
        }
    }

    const columns = [
        {
            field: 'name',
            headerName: translate('name'),
            valueGetter: (params: any) =>
                params.data.id <= 14
                    ? translate(camelCase(params.data.name || ''))
                    : params.data?.name || '',
            filter: 'agTextColumnFilter',
        },
        {
            field: 'assetArcheType',
            headerName: translate('assetArcheType'),
            valueGetter: (params: any) =>
                assetTypes &&
                translate(
                    camelCase(
                        assetTypes
                            .slice(0, 15)
                            .find(
                                (item) =>
                                    item.assetArcheType ===
                                    params.data.assetArcheType
                            )?.name || ''
                    )
                ),

            filter: 'agTextColumnFilter',
        },
        {
            field: 'isActive',
            headerName: translate('isActive'),
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(translate, (params: any) => (
                <Tooltip label={translate('edit')} placement="top" hasArrow>
                    <IconButton
                        mr={2}
                        aria-label="Edit"
                        size="sm"
                        onClick={() => {
                            setAssetTypeToEdit(params.data)
                            buttonReference.current?.click()
                        }}
                        icon={<EditIcon />}
                    />
                </Tooltip>
            )),
        },
    ]

    return (
        <PageContainer>
            <DynamicGrid
                tableId="assetTypesTable"
                columns={columns}
                rowData={assetTypes}
                pagination={true}
                rowMultiSelectWithClick={true}
                headers={
                    <PopupActionButton
                        actionName={'createAssetType'}
                        buttonVariant={ButtonVariant.SOLID}
                        content={
                            <DynamicForm<Partial<AssetTypeDTO>>
                                data={{
                                    name: '',
                                    assetArcheType: AssetArcheType.Other,
                                    isActive: true,
                                }}
                                formFields={[
                                    {
                                        slug: 'name',
                                        label: 'name',
                                        inputProps: {
                                            type: 'text',
                                            isRequired: true,
                                        },
                                        validation: (value: string) =>
                                            value.length > 0,
                                        errorMessage: 'Name is required',
                                    },
                                    {
                                        slug: 'assetArcheType',
                                        label: 'assetArcheType',
                                        inputProps: {
                                            type: 'select',
                                            isRequired: true,
                                        },
                                        optionsKeysValues:
                                            buildListOfKeyValues<
                                                typeof AssetArcheType
                                            >(AssetArcheType),
                                    },
                                ]}
                                hideSubmit={true}
                                formSubmitReference={formSubmitReference}
                                onSubmit={createAssetType}
                            ></DynamicForm>
                        }
                        title={translate('createAssetType')}
                        onConfirm={onPopupConfirm}
                    ></PopupActionButton>
                }
            ></DynamicGrid>
            <PopupActionButton
                buttonRef={buttonReference}
                buttonProps={{
                    visibility: 'hidden',
                }}
                actionName={'edit'}
                buttonVariant={ButtonVariant.LINK}
                onConfirm={() => {
                    generalPutAPI(API_ENDPOINTS.assetTypes, {
                        id: assetTypeToEdit?.id,
                        isActive: assetTypeToEdit?.isActive,
                        name: assetTypeToEdit?.name,
                    })
                        .then(() => {
                            toast(
                                baseSuccessToastOptions(
                                    translate('assetTypeUpdatedSuccessfully')
                                )
                            )
                            fetchAssetTypes()
                        })
                        .catch((error) => {
                            toast(
                                baseErrorToastOptions(
                                    translate(`assetTypeUpdatedError:`)
                                )
                            )
                            console.error(error)
                        })
                }}
                content={
                    <>
                        <Text fontWeight={'bold'}>{translate('name')}</Text>
                        <Input
                            mb={'16px'}
                            value={assetTypeToEdit?.name}
                            onChange={(value): void => {
                                assetTypeToEdit &&
                                    setAssetTypeToEdit({
                                        ...assetTypeToEdit,
                                        name: value.currentTarget.value,
                                    })
                            }}
                        ></Input>
                        <Text fontWeight={'bold'}>{translate('isActive')}</Text>
                        <Switch
                            isChecked={!!assetTypeToEdit?.isActive}
                            onChange={(value): void =>
                                assetTypeToEdit &&
                                setAssetTypeToEdit({
                                    ...assetTypeToEdit,
                                    isActive: value.currentTarget.checked,
                                })
                            }
                        />
                    </>
                }
                title={`Edit Asset Type: ${assetTypeToEdit?.name}`}
            ></PopupActionButton>
        </PageContainer>
    )
}
