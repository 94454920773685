import camelCase from 'lodash/camelCase'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import HighlightComponent from '../../../../components/highlight/Highlight.component'
import { useContractService } from '../../../../services/contract/Contract.services'
import { ContractStatus } from '../../../../utils/types/types'

export default function KeyInformationContractFeature(): ReactElement {
    const translate = useTranslation().t
    const { contract } = useContractService()
    const isChildContract = !!contract?.parentContractNumber

    return (
        <>
            <HighlightComponent
                title={translate('contractNumber')}
                info={contract?.contractNumber}
                type="text"
            />
            {isChildContract && (
                <HighlightComponent
                    title={translate('parentContract')}
                    info={contract?.parentContractNumber}
                    type="link"
                    navigateTo={`/contracts/${contract?.parentContractNumber}`}
                />
            )}
            <HighlightComponent
                title={translate('status')}
                info={translate(
                    camelCase(ContractStatus[contract?.status ?? 0])
                )}
                type="text"
                hasBorder={true}
            />
            <HighlightComponent
                title={translate('product')}
                info={contract?.productName}
                type="text"
                hasBorder={true}
            />
            {contract.customerName && (
                <HighlightComponent
                    title={translate('customer')}
                    info={
                        contract?.customerName?.length > 20
                            ? `${contract.customerName?.substring(0, 20)}...`
                            : contract.customerName
                    }
                    type="text"
                    hasBorder={true}
                />
            )}
            {contract?.isLocked && (
                <HighlightComponent
                    title={translate('locked')}
                    info={translate('locked')}
                    type="text"
                    hasBorder={true}
                />
            )}
        </>
    )
}
