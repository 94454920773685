import camelCase from 'lodash/camelCase'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Link as ChakraLink } from '@chakra-ui/react'

import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import { useLocationsContext } from '../../../services/contexts/LocationServices.context'
import { useNumberFormatterContext } from '../../../services/contexts/NumberFormatter.context'
import { useContractService } from '../../../services/contract/Contract.services'
import {
    ColumnActionConfiguration,
    findElementFromObjectArray,
    getSpecificKeyFromObject,
} from '../../../utils/functions.utils'
import { formatDate } from '../../../utils/localization/culture.utils'
import {
    AssetStatus,
    ContractAssetStatus,
    ContractStatus,
    ModuleType,
    ProductType,
    UsageType,
} from '../../../utils/types/types'
import { useModule } from '../../../services/contexts/Module.context'

interface IContractAssetsTable {
    additionalActions?: ReactElement
    assets?: any
    hasSelection?: boolean
    onSelectionChanged?: Function
    serviceActions?: any
    insuranceActions?: any
    tableActions?: any
    cellValueChanged?: any
    hasSearch?: boolean
    variation?: 'default' | 'simple' | 'activate' | 'usage' | 'preterminate'
}
function ContractAssetsTable({
    additionalActions,
    assets,
    hasSelection = false,
    onSelectionChanged,
    serviceActions,
    insuranceActions,
    tableActions,
    hasSearch = true,
    variation = 'default',
    cellValueChanged,
}: IContractAssetsTable): ReactElement {
    const translate = useTranslation().t
    const { contract, product } = useContractService()

    const isSubscriptionAndRental =
        product?.productType === ProductType.Subscription ||
        product?.productType === ProductType.Rental

    const { locations } = useLocationsContext()
    const { formatValue } = useNumberFormatterContext()
    const { hasAccess } = useModule()

    function buildColumns(): any {
        switch (variation) {
            case 'simple':
                return [
                    {
                        headerName: translate('contract'),
                        field: 'contractNumber',
                        pinned: 'left',
                        cellRenderer: (params: any) => (
                            <ChakraLink
                                as={Link}
                                to={`/contracts/${params.data?.contractNumber}`}
                                fontSize="md"
                            >
                                {params.data.contractNumber}
                            </ChakraLink>
                        ),
                    },
                    {
                        headerName: translate('status'),
                        field: 'contractStatus',
                        valueGetter: (params: any) =>
                            translate(
                                camelCase(
                                    getSpecificKeyFromObject<
                                        typeof ContractStatus
                                    >(
                                        ContractStatus,
                                        params.data?.contractStatus
                                    )
                                ) || ''
                            ),
                    },
                    {
                        headerName: translate('endDate'),
                        valueGetter: (params: any) =>
                            formatDate(params.data.endDate),
                        field: 'endDate',
                    },
                    {
                        headerName: translate('location'),
                        field: 'location',
                        valueGetter: (params: any) =>
                            params.data?.assetStatus === AssetStatus.InUse
                                ? translate('atCustomer')
                                : params.data?.location?.locationName ||
                                  translate('unspecified'),
                    },
                ]

            case 'activate':
                return [
                    {
                        headerName: translate('itemId'),
                        headerCheckboxSelection: hasSelection,
                        checkboxSelection: hasSelection,
                        field: 'baseAssetNumber',
                        pinned: 'left',
                        cellRenderer: (params: any) => (
                            <ChakraLink
                                as={Link}
                                to={`/inventory/${params.data?.baseAssetNumber}`}
                                fontSize="md"
                            >
                                {params.data.baseAssetNumber}
                            </ChakraLink>
                        ),
                    },
                    {
                        headerName: translate('contractAssetId'),
                        field: 'assetNumber',
                    },
                    {
                        headerName: translate('valid'),
                        field: 'valid',
                        valueGetter: (params: any) =>
                            params.data.valid
                                ? translate('valid')
                                : translate('notValid'),
                    },
                    { headerName: translate('make'), field: 'make' },
                    { headerName: translate('model'), field: 'model' },
                    {
                        headerName: translate('location'),
                        valueGetter: (params: any) =>
                            findElementFromObjectArray(
                                locations,
                                'locationNumber',
                                `${params.data?.locationNumber}`,
                                'locationName'
                            ),
                        field: 'locationNumber',
                    },
                    {
                        headerName: translate('price'),
                        field: 'purchaseValue',
                        valueFormatter: (params: any) =>
                            formatValue(params, 'purchaseValue'),
                    },
                    {
                        headerName: translate('purchaseValue'),
                        field: 'originalPurchaseValue',
                        valueFormatter: (params: any) =>
                            formatValue(params, 'originalPurchaseValue'),
                    },
                    ...(!isSubscriptionAndRental
                        ? [
                              {
                                  headerName: translate('downpayment'),
                                  field: 'downpayment',
                                  valueFormatter: (params: any) =>
                                      formatValue(params, 'downpayment'),
                              },
                              {
                                  headerName: translate('residualValue'),
                                  field: 'restValue',
                                  valueFormatter: (params: any) =>
                                      formatValue(params, 'restValue'),
                              },
                          ]
                        : []),
                ]
            case 'preterminate':
                return [
                    {
                        headerName: translate('itemId'),
                        headerCheckboxSelection: hasSelection,
                        checkboxSelection: hasSelection,
                        field: 'baseAssetNumber',
                        pinned: 'left',
                        cellRenderer: (params: any) => (
                            <ChakraLink
                                as={Link}
                                to={`/inventory/${params.data?.baseAssetNumber}`}
                                fontSize="md"
                            >
                                {params.data.baseAssetNumber}
                            </ChakraLink>
                        ),
                    },
                    {
                        headerName: translate('make'),
                        field: 'make',
                    },
                    {
                        headerName: translate('model'),
                        field: 'model',
                    },
                    {
                        headerName: translate('serialNumber'),
                        field: 'serialNumber',
                    },
                    {
                        headerName: translate('currentBalance'),
                        field: 'currentBalance',
                    },
                    {
                        headerName: translate('remainingInterestEarnings'),
                        field: 'remainingInterestEarnings',
                    },
                    {
                        headerName: translate('totalBalancePlusInterest'),
                        field: 'suggestedPreterminatePrice',
                    },
                    {
                        headerName: translate('agreedRestValue'),
                        field: 'agreedRestValue',
                        editable: true,
                        cellEditor: 'agNumberCellEditor',
                        cellClass: 'cellEditable',
                        valueGetter: (params: any) =>
                            params.data?.agreedRestValue ??
                            params.data?.suggestedPreterminatePrice ??
                            0,
                    },
                    {
                        headerName: translate('expirationDate'),
                        field: 'expirationDate',
                        valueGetter: (params: any) =>
                            formatDate(params.data?.dateTimeActivation),
                    },
                ]
            case 'usage':
                return [
                    {
                        headerName: translate('itemId'),
                        field: 'baseAssetNumber',
                        pinned: 'left',
                        cellRenderer: (params: any) => (
                            <ChakraLink
                                as={Link}
                                to={`/inventory/${params.data?.baseAssetNumber}`}
                                fontSize="md"
                            >
                                {params.data.baseAssetNumber}
                            </ChakraLink>
                        ),
                    },
                    {
                        headerName: translate('contractAssetId'),
                        field: 'assetNumber',
                    },
                    {
                        headerName: translate('valid'),
                        field: 'valid',
                        valueGetter: (params: any) =>
                            params.data.valid
                                ? translate('valid')
                                : translate('notValid'),
                    },
                    {
                        headerName: translate('status'),
                        field: 'status',
                        valueGetter: (params: any) =>
                            translate(ContractAssetStatus[params.data.status]),
                    },
                    { headerName: translate('make'), field: 'make' },
                    { headerName: translate('model'), field: 'model' },
                    {
                        headerName: translate('location'),
                        field: 'locationNumber',
                        valueGetter: (params: any) =>
                            findElementFromObjectArray(
                                locations,
                                'locationNumber',
                                `${params.data?.locationNumber}`,
                                'locationName'
                            ),
                    },
                    {
                        headerName: translate('activationDate'),
                        field: 'dateTimeActivation',
                        valueGetter: (params: any) =>
                            formatDate(params.data?.dateTimeActivation),
                    },
                    {
                        headerName: translate('endDate'),
                        field: 'endDate',
                        valueGetter: (params: any) =>
                            formatDate(params.data?.endDate),
                    },
                    {
                        headerName: translate('price'),
                        field: 'purchaseValue',
                        valueFormatter: (params: any) =>
                            formatValue(params, 'purchaseValue'),
                    },
                    ...(!isSubscriptionAndRental
                        ? [
                              {
                                  headerName: translate('downpayment'),
                                  field: 'downpayment',
                                  valueFormatter: (params: any) =>
                                      formatValue(params, 'downpayment'),
                              },
                              {
                                  headerName: translate('residualValue'),
                                  field: 'restValue',
                                  valueFormatter: (params: any) =>
                                      formatValue(params, 'restValue'),
                              },
                          ]
                        : []),
                    {
                        headerName: translate('priceUnitOverLimit'),
                        field: 'pricePerUnitOverUsageLimit',
                        valueFormatter: (params: any) =>
                            formatValue(params, 'pricePerUnitOverUsageLimit'),
                    },
                    {
                        headerName: translate('priceUnitUnderLimit'),
                        field: 'pricePerUnitUnderUsageLimit',
                        valueFormatter: (params: any) =>
                            formatValue(params, 'pricePerUnitUnderUsageLimit'),
                    },
                    {
                        headerName: translate('usageType'),
                        field: 'usageType',
                        valueGetter: (params: any) =>
                            params.data.usageType !== undefined
                                ? `${UsageType[params.data.usageType] ?? ''}`
                                : '',
                    },
                    {
                        headerName: translate('usageLimit'),
                        field: 'usageLimit',
                        valueFormatter: (params: any) =>
                            formatValue(params, 'usageLimit'),
                    },
                    {
                        ...ColumnActionConfiguration(
                            translate,
                            (params: any) => serviceActions(params),
                            120,
                            'services'
                        ),
                    },
                    {
                        ...ColumnActionConfiguration(translate, (params: any) =>
                            tableActions(params)
                        ),
                    },
                ]
            default:
                return [
                    {
                        pinned: 'left',
                        headerName: translate('itemId'),
                        field: 'baseAssetNumber',
                        cellRenderer: (params: any) => (
                            <ChakraLink
                                as={Link}
                                to={`/inventory/${params.data?.baseAssetNumber}`}
                                fontSize="md"
                            >
                                {params.data.baseAssetNumber}
                            </ChakraLink>
                        ),
                    },
                    { headerName: translate('make'), field: 'make' },
                    { headerName: translate('model'), field: 'model' },

                    {
                        headerName: translate('assetType'),
                        field: 'assetType',
                        valueGetter: (params: any) =>
                            params.data.assetTypeData?.name || ' ',
                    },
                    {
                        headerName: translate('price'),
                        field: 'purchaseValue',
                        valueFormatter: (params: any) =>
                            formatValue(params, 'purchaseValue'),
                    },
                    ...(isSubscriptionAndRental
                        ? [
                              {
                                  headerName: translate('lockInPeriod'),
                                  field: 'lockInPeriod',
                                  valueFormatter: (params: any) =>
                                      formatValue(params, 'lockInPeriod'),
                              },
                              {
                                  headerName: translate('cancellationPeriod'),
                                  field: 'cancellationPeriod',
                                  valueFormatter: (params: any) =>
                                      formatValue(params, 'cancellationPeriod'),
                              },
                          ]
                        : []),
                    ...(!isSubscriptionAndRental
                        ? [
                              {
                                  headerName: translate('downpayment'),
                                  field: 'downpayment',
                                  valueFormatter: (params: any) =>
                                      formatValue(params, 'downpayment'),
                              },
                              {
                                  headerName: translate(
                                      'downpaymentPercentage'
                                  ),
                                  field: 'downpaymentPercentage',
                                  valueGetter: ({
                                      data: { purchaseValue, downpayment },
                                  }) =>
                                      purchaseValue
                                          ? `${(
                                                (downpayment / purchaseValue) *
                                                100
                                            ).toFixed(2)}%`
                                          : 'N/A',
                              },
                              {
                                  headerName: translate('residualValue'),
                                  field: 'restValue',
                                  valueFormatter: (params: any) =>
                                      formatValue(params, 'restValue'),
                              },
                              {
                                  headerName: translate('residualPercentage'),
                                  field: 'residualValuePercentage',
                                  valueGetter: ({
                                      data: { purchaseValue, restValue },
                                  }) =>
                                      purchaseValue
                                          ? `${(
                                                (restValue / purchaseValue) *
                                                100
                                            ).toFixed(2)}%`
                                          : 'N/A',
                              },
                          ]
                        : []),

                    {
                        headerName: translate('identificationNumbers'),
                        field: 'identification',
                        valueGetter: (params: any) => {
                            const { registrationNumber, vin, serialNumber } =
                                params.data
                            return [registrationNumber, vin, serialNumber]
                                .filter(Boolean)
                                .join(', ')
                        },
                    },
                    {
                        headerName: translate('activationDate'),
                        field: 'dateTimeActivation',
                        valueGetter: (params: any) =>
                            formatDate(params.data?.dateTimeActivation),
                    },
                    {
                        headerName: translate('endDate'),
                        field: 'endDate',
                        valueGetter: (params: any) =>
                            formatDate(params.data?.endDate),
                    },

                    ...(serviceActions
                        ? [
                              {
                                  ...ColumnActionConfiguration(
                                      translate,
                                      (params: any) => serviceActions(params),
                                      120,
                                      'services'
                                  ),
                              },
                          ]
                        : []),
                    ...(insuranceActions && hasAccess(ModuleType.Insurance)
                        ? [
                              {
                                  ...ColumnActionConfiguration(
                                      translate,
                                      (params: any) => insuranceActions(params),
                                      140,
                                      'insurance'
                                  ),
                              },
                          ]
                        : []),
                    ...(tableActions
                        ? [
                              {
                                  ...ColumnActionConfiguration(
                                      translate,
                                      (params: any) => tableActions(params)
                                  ),
                              },
                          ]
                        : []),
                ]
        }
    }

    return (
        <DynamicGrid
            tableId="contractAssetsTable"
            columns={buildColumns()}
            headers={additionalActions}
            onSelectionChanged={onSelectionChanged}
            pagination={true}
            enableSearch={hasSearch}
            rowData={assets || contract.contractAssets}
            rowMultiSelectWithClick={true}
            cellValueChanged={cellValueChanged}
        />
    )
}

export default React.memo(ContractAssetsTable)
