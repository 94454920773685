import orderBy from 'lodash/orderBy'
/* eslint-disable @typescript-eslint/no-shadow */
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import ContractDownloadConfig from '../../../app/config/download/contract.config.json'
import DownloadActions from '../../../components/downloadActions/DownloadActions.component'
import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { getAllPagesHandlerPrompt } from '../../../utils/pagination/pagination.util'
import { ContractDTO, ContractStatus } from '../../../utils/types/types'
import { columnsConfig } from './ContractsTable.config'

function ContractTable(): ReactElement {
    const gridApiRef = useRef(null)
    const translate = useTranslation().t
    const [contractList, setContractList] = useState<any[]>()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [contracts, setContracts] = useState<ContractDTO[]>()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const [selectedItems, setSelectedItems] = useState<ContractDTO[]>([])

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    const selectAllElements = async (): Promise<void> => {
        contractList && setSelectedItems([...contractList])
    }

    const getSelectedData = (): void => {
        const selectedNodes = (gridApiRef?.current as any)?.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        setSelectedItems(selectedData)
    }

    const onResetSelectedItems = (): void => {
        (gridApiRef?.current as any)?.deselectAll()
        setSelectedItems([])
    }

    useEffect(() => {
        const fetchContracts = async () => {
            await getAllPagesHandlerPrompt(
                API_ENDPOINTS.contract,
                (data: any) => {
                    setContractList((prevValue) => [
                        ...(prevValue || []),
                        ...data,
                    ])
                }
            )
        }

        fetchContracts()
    }, [])

    useEffect(() => {
        let contracts = contractList
        const status = searchParams.get('status')
        if (status && status !== 'All' && contractList) {
            contracts = contractList.filter(
                (c: ContractDTO) =>
                    (c.status as any) === ContractStatus[status as any]
            )
        }
        setContracts(orderBy(contracts, 'contractNumber', 'desc'))
    }, [searchParams?.get('status'), JSON.stringify(contractList)])

    return (
        <DynamicGrid
            tableId={'contractsTable'}
            onGridReady={onGridReady}
            columns={columnsConfig(translate, navigate, isDownloadEnabled)}
            rowData={contracts}
            pagination={true}
            rowSelection={'multiple'}
            rowMultiSelectWithClick={true}
            onSelectionChanged={getSelectedData}
            headers={
                <Flex
                    justifyContent={'flex-end'}
                    w={'100%'}
                    mb={4}
                    gap={2}
                    alignItems={'center'}
                >
                    <DownloadActions
                        isDownloadEnabled={isDownloadEnabled}
                        setDownloadEnabled={setDownloadEnabled}
                        selectAllElements={selectAllElements}
                        selectedItems={selectedItems}
                        downloadConfig={ContractDownloadConfig}
                        resetSelectedItems={onResetSelectedItems}
                    />
                </Flex>
            }
        />
    )
}

export default ContractTable
