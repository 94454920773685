import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import LocationsPage from '../../../setup/locations/Locations.page'

export default function CustomerLocations(): ReactElement {
    const params = useParams<{ customerNumber?: string }>()
    const { customerNumber } = params

    return <LocationsPage customerNumber={customerNumber} />
}
