import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Flex, useColorModeValue } from '@chakra-ui/react'

import { useOffer } from '../../../../services/contexts/Offer.context'

export default function NewOfferAction(): ReactElement {
    const { offer, createContract, requestPendingContract } = useOffer()
    const translate = useTranslation().t

    return (
        <>
            <Flex
                alignItems={'center'}
                bg={useColorModeValue('white', 'secondary.850')}
                justifyContent={'space-between'}
                rounded="lg"
                width="100%"
            >
                <Flex alignItems={'center'} gap={2}></Flex>
                <Box>
                    <Button
                        isDisabled={!offer.customer?.email || !offer.productId} // Offer needs to have customer & productId to create contract
                        isLoading={requestPendingContract}
                        onClick={createContract}
                    >
                        {translate('createOffer')}
                    </Button>
                </Box>
            </Flex>
        </>
    )
}
