import { ReactElement, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Flex,
    Text,
    useToast,
    Image,
    Heading,
} from '@chakra-ui/react'

import PageContainer from '../../../components/pageContainer/PageContainer.component'
import ErrorBoundaryWrapper from '../../../features/errorBoundary/ErrorBoundary.feature'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../services/API/general.api'
import { baseErrorToastOptions } from '../../../utils/functions.utils'
import PenneoSetupContainer from './PenneoSetup.page'
import { ExternalPartnerSetup } from '../../../utils/types/types'

const AllowedERPTypesToAuthorize = [
    'Economics',
    'BusinessCentral',
    'Fortnox',
    'EAccounting',
]

function findEnumValueByName(enumObj: any, searchString: string) {
    const lowerSearchString = searchString.toLowerCase()
    return Object.keys(enumObj).find(
        (key) =>
            Number.isNaN(Number(key)) &&
            key.toLowerCase().includes(lowerSearchString)
    )
        ? enumObj[
              Object.keys(enumObj).find(
                  (key) =>
                      Number.isNaN(Number(key)) &&
                      key.toLowerCase().includes(lowerSearchString)
              )!
          ]
        : null
}

interface IIntegrationRows {
    name: string
    type: string
    status: string
}

// Mapping between integration names and their respective logo paths
const integrationLogos: Record<string, string> = {
    Economics: '/integrations/e-conomic.png',
    'Credit Safe': '/integrations/creditsafe.svg',
    Scrive: '/integrations/scrive.svg',
    Fortnox: '/integrations/fortnox.svg',
    'Business Central': '/integrations/365.png',
    Netvisor: '/integrations/netvisor.svg',
    Penneo: '/integrations/penneo.svg',
    Salesforce: '/integrations/salesforce.svg',
    'Visma EAccounting': '/integrations/visma.svg',
    Zendesk: '/integrations/zendesk.svg',
    HubSpot: '/integrations/hubspot.svg',
    'Manual Csv': '/integrations/csv.svg',
}

export default function IntegrationsPage(): ReactElement {
    const [isPenneoModalOpen, setIsPenneoModalOpen] = useState(false)
    const [integrations, setIntegrations] = useState<IIntegrationRows[]>()
    const toast = useToast()

    const retrieveERPIntegration = async (): Promise<void> => {
        let apiIntegrations: any[] = []
        const request = await generalGetAPI(API_ENDPOINTS.ERPSetupActive)
        if (request.isOk) {
            apiIntegrations = request.data.map((integration: any) => ({
                name: integration.setupType,
                type: integration.externalSystemType,
                status: 'connected', // Adjust if the status is provided dynamically
            }))
            setIntegrations(apiIntegrations)
        }
    }

    const startExternalAuthorization = async (
        externalSystem: ExternalPartnerSetup
    ): Promise<void> => {
        const response = await generalPostAPI(
            API_ENDPOINTS.externalAuthorization,
            {
                externalSystem,
            }
        )
        if (response.isOk && response.data.shouldForward) {
            window.location.replace(response.data.forwardTo)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
    }

    useEffect(() => {
        retrieveERPIntegration()
    }, [])

    // Filter unused integrations
    const usedLogos = integrations?.map((integration) => integration.name) || []
    const unusedLogos = Object.keys(integrationLogos).filter(
        (key) => !usedLogos.includes(key)
    )

    return (
        <PageContainer>
            <ErrorBoundaryWrapper id="integrations-page-error">
                <Flex wrap="wrap" gap={6} justify="center">
                    {integrations?.map((integration, index) => (
                        <Box
                            key={index}
                            borderWidth="1px"
                            borderRadius="xl"
                            padding="6"
                            shadow="lg"
                            width={['100%', '300px']}
                            bg="white"
                            transition="transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out"
                            _hover={{
                                transform: 'translateY(-5px)',
                                shadow: 'xl',
                            }}
                        >
                            <Flex direction="column" align="center" mb={4}>
                                {/* Render Logo */}
                                {integrationLogos[integration.name] && (
                                    <Image
                                        src={integrationLogos[integration.name]}
                                        alt={`${integration.name} logo`}
                                        boxSize="60%"
                                        height={40}
                                        objectFit="contain"
                                    />
                                )}
                                <Text
                                    fontSize="lg"
                                    fontWeight="semibold"
                                    color="gray.700"
                                    textTransform="capitalize"
                                    mb={1}
                                >
                                    {integration.name}
                                </Text>
                                <Text
                                    fontSize="sm"
                                    fontWeight="medium"
                                    color="gray.500"
                                >
                                    {integration.type}
                                </Text>
                            </Flex>
                            <Flex justify="center">
                                <Button
                                    colorScheme="teal"
                                    variant="solid"
                                    isDisabled={
                                        !AllowedERPTypesToAuthorize.includes(
                                            integration.name
                                        )
                                    }
                                    onClick={() =>
                                        startExternalAuthorization(
                                            findEnumValueByName(
                                                ExternalPartnerSetup,
                                                integration.name
                                            )
                                        )
                                    }
                                    aria-label={`Authorize ${integration.name}`}
                                >
                                    Authorize
                                </Button>
                            </Flex>
                        </Box>
                    ))}
                </Flex>

                {/* Cards for Unused Logos */}
                <Heading size="md" mt={8} mb={4} textAlign="center">
                    Explore More Integrations
                </Heading>
                <Flex wrap="wrap" gap={6} justify="center">
                    {unusedLogos.map((logoKey, index) => (
                        <Box
                            key={index}
                            borderWidth="1px"
                            borderRadius="xl"
                            padding="6"
                            shadow="lg"
                            width={['100%', '200px']}
                            bg="white"
                            textAlign="center"
                        >
                            <Image
                                src={integrationLogos[logoKey]}
                                alt={`${logoKey} logo`}
                                boxSize="60%"
                                height={40}
                                objectFit="contain"
                                mx="auto"
                                mb={4}
                            />
                            <Text
                                fontSize="md"
                                fontWeight="medium"
                                color="gray.600"
                            >
                                {logoKey}
                            </Text>
                        </Box>
                    ))}
                </Flex>

                <PenneoSetupContainer
                    modalTitle={'PenneoSetup'}
                    isModalOpen={isPenneoModalOpen}
                    onClose={(): void => setIsPenneoModalOpen(false)}
                />
            </ErrorBoundaryWrapper>
        </PageContainer>
    )
}
