/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useToast,
} from '@chakra-ui/react'

import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalPutAPI } from '../../../../../services/API/general.api'
import { useUser } from '../../../../../services/contexts/user.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../../../utils/functions.utils'
import {
    ContractStatus,
    ProductType,
    UserGroup,
} from '../../../../../utils/types/types'
import DebtorChangeComponent from '../../debtorChange/DebtorChange.component'
import CloseContractComponent from '../closeContract/CloseContract.component'
import CopyContractComponent from '../copyContract/CopyContract.component'
import CreateBundleComponent from '../createBundle/CreateBundle.component'
import PreterminateContractComponent from '../preterminateContract/PreterminateContract.component'
import ProlongContractComponent from '../prolongContract/ProlongContract.component'

export default function GlobalContractActions(): ReactElement {
    const toast = useToast()
    const { user } = useUser()
    const {
        contract,
        product,
        originalContract,
        enableContractSave,
        saveContract,
        cancelContract,
        getContract,
        isLoading,
    } = useContractService()

    const [disableSave, setDisableSave] = useState(false)
    const translate = useTranslation().t
    const [isCloseContractModalOpen, setIsCloseContractModalOpen] =
        useState<boolean>(false)
    const [isCopyContractModalOpen, setCopyContractModalOpen] =
        useState<boolean>(false)
    const [isProlongContractModalOpen, setProlongModalOpen] =
        useState<boolean>(false)
    const [isPreterminateModalOpen, setPreterminateModalOpen] =
        useState<boolean>(false)
    const [isCreateBundleModalOpen, setCreateBundleModalOpen] =
        useState<boolean>(false)
    const [isDebtorChangeModalOpen, setDebtorChangeModalOpen] =
        useState<boolean>(false)

    const handleLockContractToggle = (): void => {
        const lockToggledContract = {
            ...contract,
            isLocked: !contract.isLocked,
        }
        saveContract(lockToggledContract)
    }

    const handleCloseContract = (): void => {
        setIsCloseContractModalOpen(true)
    }

    const handleCopyContractToggle = (): void => {
        if (isCopyContractModalOpen) return
        setCopyContractModalOpen(true)
    }

    const handleProlongContractToggle = (): void => {
        if (isProlongContractModalOpen) return
        setProlongModalOpen(true)
    }

    const handleCreateBundleToggle = (): void => {
        if (isCreateBundleModalOpen) return
        setCreateBundleModalOpen(true)
    }

    const handleDebtorChangeToggle = (): void => {
        if (isDebtorChangeModalOpen) return
        setDebtorChangeModalOpen(true)
    }

    const scheduleFutureEnd = async () => {
        if (contract?.contractNumber) {
            try {
                const response = await generalPutAPI(
                    API_ENDPOINTS.contractScheduleFutureEnd(
                        contract.contractNumber
                    ),
                    {
                        contractNumber: contract.contractNumber,
                    }
                )
                if (response.isOk) {
                    toast(baseSuccessToastOptions(response.message))
                    getContract()
                } else {
                    toast(baseErrorToastOptions(response.message))
                }
            } catch (e: any) {
                console.error(e.message)
            }
        }
    }

    useEffect(() => {
        setDisableSave(
            contract?.status === ContractStatus.Closed ||
                (contract?.status !== ContractStatus.Active &&
                    contract?.isLocked) ||
                !enableContractSave ||
                JSON.stringify(contract) === JSON.stringify(originalContract)
        )
    }, [JSON.stringify(contract), enableContractSave])

    const actionList = [
        {
            name: translate(
                contract?.isLocked ? 'unLockContract' : 'lockContract'
            ),
            callback: handleLockContractToggle,
            isVisible:
                user.userGroup > UserGroup.RestrictedUser &&
                contract?.status !== ContractStatus.Active &&
                contract?.status !== ContractStatus.Closed,
        },
        {
            name: translate('copyContract'),
            callback: handleCopyContractToggle,
            isVisible: user.userGroup > UserGroup.RestrictedUser,
            isDisabled: contract?.status === ContractStatus.Cancelled,
        },
        {
            name: translate('prolongContract'),
            callback: handleProlongContractToggle,
            isVisible:
                user.userGroup > UserGroup.RestrictedUser &&
                product?.canBeProlonged &&
                contract?.status !== ContractStatus.Closed,
        },
        {
            name: translate('closeContract'),
            callback: handleCloseContract,
            isVisible:
                user.userGroup > UserGroup.RestrictedUser &&
                contract?.status === ContractStatus.UnderClosing,
        },
        // {
        //     name: translate('scheduleFutureEnd'),
        //     callback: scheduleFutureEnd,
        //     isVisible:
        //         user.userGroup > UserGroup.RestrictedUser &&
        //         contract?.status === ContractStatus.ScheduledForClose,
        // },
        {
            name: translate('preterminateContract'),
            callback: () => setPreterminateModalOpen(true),
            isVisible:
                user.userGroup > UserGroup.RestrictedUser &&
                contract?.status === ContractStatus.Active &&
                (product?.productType === ProductType.FinancialLeasing ||
                    product?.productType === ProductType.OperationalLeasing),
        },
        {
            name: translate('cancelContract'),
            callback: cancelContract,
            isVisible: contract?.status === ContractStatus.Offer,
        },
        {
            name: translate('createBundle'),
            callback: handleCreateBundleToggle,
            isVisible:
                user.userGroup > UserGroup.RestrictedUser &&
                (contract?.status === ContractStatus.Offer ||
                    contract?.status === ContractStatus.Active),
        },
        {
            name: translate('debtorChange'),
            callback: handleDebtorChangeToggle,
            isVisible:
                user.userGroup > UserGroup.RestrictedUser &&
                contract?.status === ContractStatus.Active,
        },
    ]

    return (
        <>
            <Flex
                justifyContent={'space-between'}
                width="100%"
                alignItems={'center'}
            >
                <Flex gap={2} alignItems={'center'}>
                    <Menu>
                        <MenuButton as={Button} isDisabled={isLoading}>
                            {translate('actions')}
                        </MenuButton>
                        <MenuList>
                            {actionList.map(
                                (action, index) =>
                                    action.isVisible && (
                                        <MenuItem
                                            key={index}
                                            onClick={action.callback}
                                            isDisabled={action?.isDisabled}
                                        >
                                            {action.name}
                                        </MenuItem>
                                    )
                            )}
                        </MenuList>
                    </Menu>
                </Flex>

                <Box>
                    <Button
                        onClick={(): void => saveContract()}
                        isDisabled={disableSave}
                    >
                        {translate('save')}
                    </Button>
                </Box>
            </Flex>

            {/** Modals */}
            <CloseContractComponent
                isModalOpen={isCloseContractModalOpen}
                onClose={() => setIsCloseContractModalOpen(false)}
                modalTitle={translate('closeContract')}
            />
            <CopyContractComponent
                isModalOpen={isCopyContractModalOpen}
                onClose={() => setCopyContractModalOpen(false)}
                modalTitle={translate('copyContract')}
            />
            <PreterminateContractComponent
                isModalOpen={isPreterminateModalOpen}
                onClose={() => setPreterminateModalOpen(false)}
                modalTitle={translate('preterminateContract')}
            />
            <ProlongContractComponent
                isModalOpen={isProlongContractModalOpen}
                onClose={() => setProlongModalOpen(false)}
                modalTitle={translate('prolongContract')}
            />
            <CreateBundleComponent
                isModalOpen={isCreateBundleModalOpen}
                onClose={() => setCreateBundleModalOpen(false)}
                modalTitle={translate('createBundle')}
            />
            <DebtorChangeComponent
                isModalOpen={isDebtorChangeModalOpen}
                onClose={() => setDebtorChangeModalOpen(false)}
                modalTitle={translate('debtorChange')}
            />
        </>
    )
}
