import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    IconButton,
    Stack,
    Tooltip,
    useToast,
} from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom'
import PageContainer from '../../../components/pageContainer/PageContainer.component'
import ServiceContainer from '../../../features/service/Service.feature'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../services/API/general.api'
import useIsMounted from '../../../services/hooks/isMounted'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../utils/functions.utils'
import { BaseServiceDTO } from '../../../utils/types/types'
import DynamicGrid from '../../demo/DynamicAGGrid.component'
import {
    DEFAULT_SERVICE_ITEM,
    getServicesRows,
    servicesHeaders,
} from './ServicesSetup.config'

type OptionalBaseServiceDTO = Partial<BaseServiceDTO>

function ServicesSetupPage(): ReactElement {
    const gridApiRef = useRef(null)
    const [services, setServices] = useState<OptionalBaseServiceDTO[]>()
    const [selectedService, setSelectedService] =
        useState<OptionalBaseServiceDTO>()
    const [isServiceModalOpen, setServiceModalOpen] = useState<boolean>(false)
    const isMounted = useIsMounted()
    const navigate = useNavigate()
    const toast = useToast()
    const translate = useTranslation().t

    const getServicesHandler = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.service)
        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
            return
        }
        setServices(response.data)
    }

    const handleServiceSave = async (
        serviceItem: OptionalBaseServiceDTO
    ): Promise<void | string | number> => {
        setServiceModalOpen(false)
        let response
        const payload = {
            ...serviceItem,
        }
        if (!serviceItem?.baseServiceNumber) {
            response = await generalPostAPI(API_ENDPOINTS.service, payload)
            if (response.isOk) {
                toast(baseSuccessToastOptions(translate('serviceAdded')))
            }
        } else {
            response = await generalPutAPI(API_ENDPOINTS.service, payload)
            if (response.isOk) {
                toast(baseSuccessToastOptions(translate('serviceUpdated')))
            }
        }
        if (!response.isOk) {
            return toast(baseErrorToastOptions(response.message))
        }
        getServicesHandler()
        setSelectedService({})
    }

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    useEffect(() => {
        if (isMounted()) {
            getServicesHandler()
        }
    }, [isMounted])

    return (
        <PageContainer>
            <Stack spacing={4}>
                <Flex
                    alignItems={'center'}
                    justifyContent="flex-end"
                    gap={4}
                    flexWrap="wrap"
                >
                    <Button
                        onClick={(): void => {
                            setSelectedService({ ...DEFAULT_SERVICE_ITEM })
                            setServiceModalOpen(true)
                        }}
                    >
                        {translate('addService')}
                    </Button>
                </Flex>

                <DynamicGrid
                    tableId="servicesSetupPageTable"
                    onGridReady={onGridReady}
                    columns={servicesHeaders(
                        false,
                        translate,
                        (params: any): any => (
                            <>
                                <Tooltip
                                    label={translate('edit')}
                                    placement="top"
                                    hasArrow
                                >
                                    <IconButton
                                        aria-label="Edit"
                                        mr={2}
                                        size="sm"
                                        onClick={(): any => {
                                            if (
                                                params?.data?.baseServiceNumber
                                            ) {
                                                navigate(
                                                    params?.data
                                                        ?.baseServiceNumber
                                                )
                                            }
                                        }}
                                        icon={<EditIcon />}
                                    />
                                </Tooltip>
                            </>
                        )
                    )}
                    pagination={true}
                    rowData={getServicesRows(services)}
                    rowMultiSelectWithClick={true}
                    rowSelection={'multiple'}
                ></DynamicGrid>

                {/* Modals */}
                {isServiceModalOpen && selectedService && (
                    <ServiceContainer
                        isModalOpen={isServiceModalOpen}
                        onClose={() => {
                            setServiceModalOpen(false)
                        }}
                        modalTitle={
                            selectedService?.baseServiceNumber
                                ? translate('editService')
                                : translate('addService')
                        }
                        handleServiceSave={handleServiceSave}
                        serviceItem={selectedService}
                    />
                )}
            </Stack>
        </PageContainer>
    )
}

export default ServicesSetupPage
