import { useField } from 'formik'
import camelCase from 'lodash/camelCase'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ListItem, Select, UnorderedList } from '@chakra-ui/react'

type FormSelectProps = {
    isValueString?: boolean
    name: string
    props: any
    selectOptions: string[][]
    skipTranslation?: boolean | undefined
}
export default function FormSelectField({
    selectOptions,
    name,
    skipTranslation = false,
    isValueString = false,
    ...props
}: FormSelectProps): ReactElement {
    const [field, errors, { setValue }] = useField(name)
    const translate = useTranslation().t
    const emptyVal = isValueString ? '' : undefined

    return (
        <>
            <Select
                {...field}
                {...props.props}
                value={field.value !== null ? field.value : emptyVal}
                placeholder={translate('select')}
                name={field.name}
            >
                {selectOptions &&
                    selectOptions.map((option: string[], index: number) => (
                        <option
                            key={index}
                            value={option[0]}
                            onClick={() =>
                                setValue(
                                    isValueString
                                        ? option[0]
                                        : parseInt(option[0])
                                )
                            }
                        >
                            {skipTranslation
                                ? option[1]
                                : translate(camelCase(option[1]))}
                        </option>
                    ))}
            </Select>
            {errors.error && (
                <UnorderedList>
                    {JSON.parse(errors.error).map(
                        (errorStr: string, index: number) => {
                            if (errorStr) {
                                return (
                                    <ListItem color="red" key={index}>
                                        {errorStr}
                                    </ListItem>
                                )
                            }
                            return null
                        }
                    )}
                </UnorderedList>
            )}
        </>
    )
}
