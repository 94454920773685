import { ReactElement, ReactNode } from 'react'

import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
} from '@chakra-ui/react'

import ErrorBoundaryWrapper from '../features/errorBoundary/ErrorBoundary.feature'

export interface ModalSharedProps {
    isModalOpen: boolean
    modalTitle: string
    onClose: () => void
    maxWidth?: string
    isCentered?: boolean
    renderFooterActions?: Function
}
export interface ModalHOCInterface extends ModalSharedProps {
    children: ReactNode
}

function ModalHOC({
    children,
    isModalOpen,
    onClose = (): void => {},
    modalTitle,
    maxWidth = '80vw',
    isCentered = false,
    renderFooterActions,
}: ModalHOCInterface): ReactElement {
    const bgColor = useColorModeValue('primary.700', 'secondary.850')
    const bodybg = useColorModeValue('white', 'secondary.750')
    const borderColor = useColorModeValue('primary.500', 'secondary.500')

    return (
        <Modal
            blockScrollOnMount={false}
            isOpen={isModalOpen}
            onClose={onClose}
            isCentered={isCentered}
        >
            <ModalOverlay />
            <ModalContent
                width={{ base: '80vw', md: '70vw', lg: '60vw' }}
                maxWidth={maxWidth}
            >
                <ModalHeader
                    background={bgColor}
                    borderTopRadius={'md'}
                    color={useColorModeValue('primary.50', 'secondary.50')}
                >
                    {modalTitle}
                </ModalHeader>
                <ModalCloseButton
                    color={useColorModeValue('primary.50', 'secondary.50')}
                />
                <ModalBody py={6} bg={bodybg}>
                    <ErrorBoundaryWrapper id="modal-hoc-error">
                        {children}
                    </ErrorBoundaryWrapper>
                </ModalBody>
                {renderFooterActions && (
                    <ModalFooter borderTop={'1px'} borderColor={borderColor}>
                        {renderFooterActions()}
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    )
}
function withModalHOC<T extends ModalSharedProps>(
    Component: React.ComponentType<T>
): (props: T) => JSX.Element {
    const componentWithModal = (props: T): JSX.Element => (
        <ModalHOC
            isModalOpen={props.isModalOpen ?? false}
            modalTitle={props.modalTitle ?? ''}
            onClose={props.onClose}
            maxWidth={props.maxWidth}
            isCentered={props.isCentered ?? false}
            renderFooterActions={props.renderFooterActions}
        >
            <ErrorBoundaryWrapper id="modal-hoc-error">
                <Component {...props} />
            </ErrorBoundaryWrapper>
        </ModalHOC>
    )
    return componentWithModal
}

export default withModalHOC
