/* eslint-disable no-empty-pattern */
import camelCase from 'lodash/camelCase'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { IconButton } from '@chakra-ui/button'
import { ViewIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/tooltip'

import withModalHOC, { ModalSharedProps } from '../../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../../../services/API/general.api'
import {
    ColumnActionConfiguration,
    getContractStatusTagType,
    getSpecificKeyFromObject,
} from '../../../../../../utils/functions.utils'
import { formatDate } from '../../../../../../utils/localization/culture.utils'
import {
    ContractDTO,
    ContractStatus,
} from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import {
    StyledBadge,
    StyledLink,
} from '../../../../../demo/StyledComponents.component'

function ContractFinanceContractsTable({
    onClose,
}: ModalSharedProps): ReactElement {
    const translate = useTranslation().t
    const navigate = useNavigate()
    const [selectedItems, setSelectedItems] = useState<ContractDTO[]>([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        fetchContractFinance()
    }, [])

    const fetchContractFinance = () => {
        generalGetAPI(API_ENDPOINTS.contractFinance).then((result) => {
            if (result.isOk) {
                setData(result.data.items)
            }
        })
    }

    const contractsHeaders = [
        {
            field: 'contractNumber',
            filter: 'agTextColumnFilter',
            headerName: translate('contract'),
            headerCheckboxSelection: true,
            checkboxSelection: true,
            cellRenderer: (params: any) => (
                <StyledLink
                    onClick={(): void =>
                        navigate(`/contracts/${params.data.contractNumber}`)
                    }
                >
                    {params.data.contractNumber}
                </StyledLink>
            ),
        },
        {
            field: 'productName',
            filter: 'agTextColumnFilter',
            headerName: translate('productName'),
        },
        {
            field: 'customerNumber',
            filter: 'agTextColumnFilter',
            headerName: translate('customerNumber'),
        },
        {
            field: 'customerName',
            filter: 'agTextColumnFilter',
            headerName: translate('customerName'),
        },
        {
            field: 'status',
            filter: 'agTextColumnFilter',
            headerName: translate('status'),
            cellRenderer: (params: any) => (
                <StyledBadge
                    variant={getContractStatusTagType(params?.data?.status)}
                >
                    {translate(
                        camelCase(
                            getSpecificKeyFromObject<typeof ContractStatus>(
                                ContractStatus,
                                params.data.status
                            )
                        )
                    )}
                </StyledBadge>
            ),
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof ContractStatus>(
                            ContractStatus,
                            params.data.status
                        )
                    )
                ),
        },
        {
            field: 'endDate',
            filter: 'agDateColumnFilter',
            headerName: translate('endDate'),
            valueGetter: (params: any) => formatDate(params?.data?.endDate),
        },
        {
            field: 'createdByName',
            filter: 'agTextColumnFilter',
            headerName: translate('createdByName'),
        },
        {
            field: 'createdByBranch',
            filter: 'agTextColumnFilter',
            headerName: translate('createdByBranch'),
            flex: 1,
        },
        {
            ...ColumnActionConfiguration(
                translate,
                (params: any) => (
                    <Tooltip label={translate('view')} placement="top" hasArrow>
                        <IconButton
                            aria-label="View"
                            size="sm"
                            onClick={(): void =>
                                navigate(
                                    `/contracts/${params.data.contractNumber}`
                                )
                            }
                            icon={<ViewIcon />}
                        />
                    </Tooltip>
                ),
                100
            ),
        },
    ]

    return (
        <>
            <DynamicGrid
                tableId="contractFinanceReportTable"
                columns={contractsHeaders}
                rowData={data}
                onSelectionChanged={(values: any): void => {
                    const selectedNodes = values.api.getSelectedNodes()
                    const selectedData = selectedNodes.map(
                        (node: any) => node.data
                    )
                    setSelectedItems(selectedData)
                }}
            />
            <Button
                mt={4}
                float={'right'}
                isLoading={loading}
                onClick={() => {
                    setLoading(true)
                    const ids: string[] = selectedItems?.map(
                        (item: ContractDTO) => item.contractNumber as string
                    )
                    generalPostAPI(API_ENDPOINTS.contractFinanceReport, {
                        outstandingContracts: ids,
                    }).then(() => {
                        setLoading(false)
                        onClose()
                    })
                }}
            >
                {translate('submit')}
            </Button>
        </>
    )
}
export default withModalHOC(ContractFinanceContractsTable)
