import camelCase from 'lodash/camelCase'

import { ViewIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import { TagType } from '../../utils/constants.utils'
import { ColumnActionConfiguration } from '../../utils/functions.utils'
import { AssetStatus } from '../../utils/types/types'
import { StyledBadge } from '../demo/StyledComponents.component'

export const getTagType = (status: AssetStatus): TagType => {
    switch (status) {
        case AssetStatus.Draft:
            return 'link'
        case AssetStatus.Ready:
            return 'success'
        case AssetStatus.Closed:
            return 'warning'
        default:
            return 'default'
    }
}

export const columnsConfig = (
    translate: Function,
    navigate: Function,
    selectionEnabled: boolean
) => [
    {
        field: 'baseAssetNumber',
        headerName: translate('asset'),
        filter: 'agTextColumnFilter',
        editable: false,
        headerCheckboxSelection: selectionEnabled,
        checkboxSelection: selectionEnabled,
    },
    {
        field: 'assetType.name',
        filter: 'agTextColumnFilter',
        headerName: translate('type'),
        valueGetter: (params: any) =>
            translate(camelCase(params?.data?.assetType?.name)),
    },
    {
        field: 'make',
        headerName: translate('make'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'model',
        headerName: translate('model'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'assetStatus',
        headerName: translate('status'),
        filter: 'agTextColumnFilter',
        flex: 1,
        valueGetter: (params: any) =>
            translate(camelCase(AssetStatus[params?.data?.assetStatus])),
        cellRenderer: (params: any) => (
            <StyledBadge variant={getTagType(params?.data?.assetStatus)}>
                {translate(camelCase(AssetStatus[params?.data?.assetStatus]))}
            </StyledBadge>
        ),
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <Tooltip label={translate('view')} placement="top" hasArrow>
                    <IconButton
                        mr={2}
                        aria-label="view"
                        size="sm"
                        onClick={() =>
                            navigate(
                                `/inventory/${params?.data.baseAssetNumber}`,
                                {
                                    state: params?.data,
                                }
                            )
                        }
                        icon={<ViewIcon />}
                    />
                </Tooltip>
            ),
            100
        ),
    },
]
