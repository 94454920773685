import camelCase from 'lodash/camelCase'

import { ViewIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import {
    ColumnActionConfiguration,
    getSpecificKeyFromObject,
} from '../../utils/functions.utils'
import { AssetStatus } from '../../utils/types/types'

export const ASSET_LIST_HEADERS = (
    translate: Function,
    actions: boolean,
    selection?: boolean,
    callback?: Function
) => [
    {
        headerName: translate('make'),
        filter: true,
        field: 'make',
        headerCheckboxSelection: selection,
        checkboxSelection: selection,
    },
    { headerName: translate('model'), filter: true, field: 'model' },
    {
        headerName: translate('status'),
        filter: true,
        field: 'assetStatus',
        valueGetter: (params: any) =>
            translate(
                camelCase(
                    getSpecificKeyFromObject<typeof AssetStatus>(
                        AssetStatus,
                        params.data?.assetStatus || 0
                    )
                )
            ),
    },
    {
        headerName: translate('baseAssetNumber'),
        filter: true,
        field: 'baseAssetNumber',
    },
    { headerName: translate('location'), filter: true, field: 'locationName' },
    ...(actions
        ? [
              {
                  ...ColumnActionConfiguration(translate, (params: any) => (
                      <Tooltip
                          label={translate('select')}
                          placement="top"
                          hasArrow
                      >
                          <IconButton
                              mr={2}
                              aria-label="select"
                              size="sm"
                              onClick={(): void => callback?.(params.data)}
                              icon={<ViewIcon />}
                          />
                      </Tooltip>
                  )),
              },
          ]
        : []),
]
