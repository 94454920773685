import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DownloadIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, IconButton, Input, Tooltip } from '@chakra-ui/react'

import PageContainer from '../../../../components/pageContainer/PageContainer.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalFilePostAPI,
    generalGetAPI,
} from '../../../../services/API/general.api'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    ColumnActionConfiguration,
    downloadFile,
    downloadFileWithContent,
    removeFileNameExtension,
    standaloneToast,
} from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'

function UsagePage(): ReactElement {
    const fileUploadRef = useRef<any>()
    const translate = useTranslation().t
    const [usage, setUsage] = useState()
    const UsageSampleFileContent = 'AssetNumber,UsageAmount'

    useEffect(() => {
        generalGetAPI(API_ENDPOINTS.documentByPartnerUploadedUsage).then(
            (response): void => {
                if (response.isOk) {
                    setUsage(response.data)
                }
            }
        )
    }, [])
    const triggerUpload = async (): Promise<void> => {
        if (!fileUploadRef.current) return
        fileUploadRef.current.click()
    }

    const handleDownload = (): void => {
        const usageName = `Usage-${formatDate(new Date().toISOString())}`
        downloadFileWithContent(UsageSampleFileContent, usageName, 'csv')
    }

    const handleUpload = async (
        event: ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        const usageFile = event.target.files

        if (!usageFile || !usageFile[0]) {
            return
        }
        const file = usageFile[0]
        const formData = new FormData()
        formData.append('file', file)

        const response = await generalFilePostAPI(
            API_ENDPOINTS.usageUpload,
            formData
        )

        if (!response.isOk) {
            standaloneToast(
                baseErrorToastOptions(response.err ?? translate('apiFail'))
            )
            return
        }
        standaloneToast(baseSuccessToastOptions(translate('fileUploadSuccess')))
    }

    const USAGE_HEADER = [
        { headerName: translate('name'), field: 'name' },
        {
            headerName: translate('creationDate'),
            field: 'creationDate',
            valueFormatter: (params: any) =>
                formatDate(params.data.creationDate),
        },
        {
            ...ColumnActionConfiguration(translate, (params: any) => (
                <Tooltip label={translate('download')} placement="top" hasArrow>
                    <IconButton
                        mr={2}
                        aria-label="download"
                        size="sm"
                        onClick={(): Promise<void> =>
                            downloadFile(
                                params.data.id,
                                removeFileNameExtension(params.data.name),
                                'csv'
                            )
                        }
                        icon={<DownloadIcon />}
                    />
                </Tooltip>
            )),
        },
    ]

    return (
        <PageContainer>
            <Flex
                alignItems={['stretch', 'stretch', 'center']}
                justifyContent={'flex-end'}
                gap={2}
                flexDirection={['column', 'column', 'row']}
            >
                <Button onClick={triggerUpload}>{translate('upload')}</Button>
                <Button onClick={handleDownload}>
                    {translate('downloadSampleDocument')}
                </Button>
                <Input
                    type={'file'}
                    ref={fileUploadRef}
                    display={'none'}
                    onChange={handleUpload}
                />
            </Flex>
            <Box w="100%">
                <DynamicGrid
                    tableId="usagePageTable"
                    columns={USAGE_HEADER}
                    rowData={usage}
                />
            </Box>
        </PageContainer>
    )
}

export default UsagePage
